import api from "src/network/api";

export interface supporterRequest {
  supporterEmail?: string;
  connectToAllWallets?: boolean;
  emailId?: string;
  userId?: number | null;
  action?: string;
  applyToAll?: boolean;
}

export const deleteWalletSupporter = (
  data: supporterRequest,
  walletId: number,
  supporterId: number,
  applyToAllWallets?: boolean
) => {
  return api.delete(`/wallets/${walletId}/supporters/${supporterId}?applyToAllWallets=${applyToAllWallets}`, { data });
};

export const addWalletSupporter = (data: supporterRequest, walletId: number) => {
  return api.post(`/wallets/${walletId}/users`, data);
};

export interface budgetSupporterRequest {
  walletId: number;
  supporterId: number;
  action: string;
  applyToAllBudgets: boolean;
}
export const updateBudgetSupporter = (data: budgetSupporterRequest, budgetId: number) => {
  return api.post(`/budgets/${budgetId}/supporters`, data);
};
