import api from "src/network/api";
import { PaginationRequest } from "src/types/commonTypes";

export interface walletListRequest extends PaginationRequest {
  status?: boolean | null;
  ids?: number[];
  participantIds?: number[] | null;
  supporterIds?: number[] | null;
  startDate?: string | null;
  endDate?: string | null;
  minBalance?: number | null;
  maxBalance?: number | null;
  organisationId?: number | null;
}

export interface walletTransRequest {
  showTotalWalletBalance?: boolean;
  showTotalTransactions?: boolean;
  showTotalRepeatPayBalance?: boolean;
  showTotalTransactionsWithoutReceipts?: boolean;
  showTotalCardBalance?: boolean;
  showSupportersConnectedWallet?: boolean;
  walletIds?: number[];
  participantIds?: number[];
  supporterIds?: number[];
  organisationId?: number | null;
}

export interface addWalletRequest {
  participantId?: number;
  description?: string;
  defaultWallet?: boolean;
  id?: number;
  organisationId?: number | null;
}

export const fetchWallets = async (data: walletListRequest) => {
  return api.post("/wallets/search", data);
};

export const getWalletDetail = (walletId: number) => {
  return api.get(`/wallets/${walletId}/detail`);
};

export const fetchWalletTransaction = (data: walletTransRequest) => {
  return api.post("/analytics/dashboard", data);
};

export const addWallet = (data: addWalletRequest) => {
  return api.post("/wallets", data);
};

export const updateWallet = (walletId: number, status: boolean) => {
  return api.post(`wallets/${walletId}/status/${status}`);
};

export const getBudgetSupporter = (budgetId: number) => {
  return api.get(`/budgets/${budgetId}/supporters`);
};

export interface depositListRequest {
  page: number;
  walletId: number;
  paymentTypes: string[];
}
export const fetchDepositList = (data: depositListRequest) => {
  return api.post("/superledgers/search", data);
};

export interface unlinkWalletRequest {
  id: number;
  status: boolean;
  removeWallet: boolean;
}

export const unlinkWallet = (data: unlinkWalletRequest) => {
  return api.post("/wallets", data);
};

export interface connectWalletRequest {
  walletId: number;
  action: string;
}

export const connectWallet = (organisationId: number, data: connectWalletRequest) => {
  return api.post(`/organisations/${organisationId}/wallets`, data);
};
