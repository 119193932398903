import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { getOrgDetail, addOrg, addorgRequest } from "src/network/services/organisationService";
import { fetchPaymentRequest, TransListRequest } from "src/network/services/transactionServices";
import { connectCard, connectCardRequest } from "src/network/services/cardService";

import { immediateToast } from "izitoast-react";

type orgDetail = {
  organisation: {
    id: number;
    name: string;
    abn: string;
    address: string;
    phone: string;
    countryCode: string;
    website: string;
    facebook: string;
    linkedIn: string;
    instagram: string;
    referenceName: string;
    referenceRole: string;
    referenceCountryCode: string;
    referencePhone: string;
    referenceEmail: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    active: boolean;
  };
  users: {
    id: number;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    guardianId: number;
    accessCode: string;
    fullName: string;
  }[];
  wallets: {
    id: number;
    balance: number;
    description: string;
    participant: {
      id: number;
      active: boolean;
      email: string;
      firstName: string;
      lastName: string;
      role: string;
      guardianId: number;
      accessCode: string;
      fullName: string;
    };
  }[];
  cards: {
    id: number;
    cardNumber: string;
    cardStatus: boolean;
    accessType: string;
    accountNumber: string;
    user: {
      id: number;
      fullName: string;
      email: string;
      role: string;
      firstName: string;
      lastName: string;
    };
  }[];
};
const initialOrgDetail: orgDetail = {
  organisation: {
    id: 0,
    name: "",
    abn: "",
    address: "",
    phone: "",
    countryCode: "",
    website: "",
    facebook: "",
    linkedIn: "",
    instagram: "",
    referenceName: "",
    referenceRole: "",
    referenceCountryCode: "",
    referencePhone: "",
    referenceEmail: "",
    createdBy: "",
    createdOn: "",
    updatedBy: "",
    updatedOn: "",
    active: false
  },
  users: [],
  wallets: [],
  cards: []
};

type Transaction = {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
};

const initialTransaction: Transaction[] = [];

const orgDetailSlice = createSlice({
  name: "organisationDetails",
  initialState: {
    organisationDetailLoading: false,
    transLoading: false,
    cardConnectLoading: false,
    error: null,
    orgDetail: initialOrgDetail,
    transList: {
      trans: initialTransaction,
      total: 0
    }
  },
  reducers: {
    getOrgDetailStart: (state) => {
      state.organisationDetailLoading = true;
    },
    getOrgDetailSuccess: (state, action) => {
      state.organisationDetailLoading = false;
      state.orgDetail = action.payload ?? initialOrgDetail;
    },
    getOrgDetailFail: (state, action) => {
      state.organisationDetailLoading = false;
      state.orgDetail = initialOrgDetail;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    updateOrgStart: (state) => {
      state.organisationDetailLoading = true;
    },
    updateOrgSuccess: (state) => {
      state.organisationDetailLoading = false;
    },
    updateOrgFail: (state, action) => {
      state.organisationDetailLoading = false;
      state.error = action.payload;
    },
    addCardStart: (state) => {
      state.cardConnectLoading = true;
    },
    addCardSuccess: (state) => {
      state.cardConnectLoading = false;
      state.error = null;
    },
    addCardFail: (state, action) => {
      state.cardConnectLoading = false;
      state.error = action.payload;
    }
  }
});
const {
  getOrgDetailStart,
  getOrgDetailSuccess,
  getOrgDetailFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  updateOrgStart,
  updateOrgSuccess,
  updateOrgFail,
  addCardStart,
  addCardSuccess,
  addCardFail
} = orgDetailSlice.actions;

export const getOrgDetailAction = (id: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getOrgDetailStart());
    try {
      const response = await getOrgDetail(id);

      dispatch(getOrgDetailSuccess(response));
    } catch (error) {
      dispatch(getOrgDetailFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await fetchPaymentRequest(data);

      dispatch(fetchTransListSuccess(response));
    } catch (error) {
      dispatch(fetchTransListFail(error));
    }
  };
};

export const updateOrgAction = (data: addorgRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateOrgStart());
    try {
      await addOrg(data);

      dispatch(updateOrgSuccess());
      immediateToast("success", {
        message: "Organisation Info Updated Successfully.",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(updateOrgFail(error));
    }
  };
};

export const addCardAction = (data: connectCardRequest, walletId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addCardStart());
    try {
      const response = await connectCard(data, walletId);

      dispatch(addCardSuccess());
      return response;
    } catch (error) {
      dispatch(addCardFail(error));
      throw error;
    }
  };
};

export default orgDetailSlice.reducer;
