import { useState, useEffect } from "react";
import styles from "./userDetail.module.scss";
import UserInfocard from "src/components/userInfoCard/UserInfocard";
// import SettingCard from "src/components/SettingCard/SettingCard";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import { useParams } from "react-router-dom";
// import {
//   SupporterSettingOption,
//   ManagerSettingOption,
//   ParticipantSettingOption,
//   ParentSettingOption
// } from "src/components/SettingCard/settingOption/settingOption";
import { SUPPORTER, PARTICIPANT } from "src/components/constant/constant";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserDetailAction,
  fetchWalletConnectAction,
  fetchWalletTransactionAction,
  fetchTransListAction
} from "src/page/userDetail/userDetailSlice";
import { whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";

export default function UserPage() {
  const [searchText, setSearchText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { userId } = useParams();

  const { userDetail, WalletConnect, transList, walletTransaction, userDetailLoading } = useAppSelector(
    (state) => state.userDetail
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetailAction(Number(userId))).then(() => {
        if (userDetail?.user?.role === PARTICIPANT) {
          dispatch(fetchWalletTransactionAction({ participantIds: [Number(userId)] }));
        } else if (userDetail?.user?.role === SUPPORTER) {
          dispatch(fetchWalletTransactionAction({ supporterIds: [Number(userId)] }));
        }
      });
    }
  }, [dispatch, userId, userDetail?.user?.role]);

  useEffect(() => {
    if (userId && userDetail?.user?.role !== "") {
      const status = userDetail?.user?.active;

      if (userDetail?.user?.role === PARTICIPANT) {
        dispatch(fetchWalletConnectAction({ status, page: 0, participantIds: [Number(userId)] }));
      } else {
        dispatch(fetchWalletConnectAction({ status, page: 0, supporterIds: [Number(userId)] }));
      }
    }
  }, [dispatch, userDetail?.user?.active, userDetail?.user?.role, userId]);

  useEffect(() => {
    if (userId && userDetail?.user?.role !== "") {
      const requestData: any = {
        page: 0,
        limit: 5,
        receiptPresent,
        isReviewed,
        statuses: ["Success", "Complete", "Hold"]
      };

      if (userDetail?.user?.role === SUPPORTER) {
        requestData.supporterIds = [Number(userId)];
      } else if (userDetail?.user?.role === PARTICIPANT) {
        requestData.participantIds = [Number(userId)];
      }
      dispatch(fetchTransListAction(requestData));
    }
  }, [dispatch, userId, receiptPresent, isReviewed, userDetail?.user?.role]);

  // const settingOptions = (() => {
  //   if (userDetail) {
  //     if (userDetail.role === SUPPORTER) {
  //       return SupporterSettingOption;
  //     } else if (userDetail.role === MANAGER) {
  //       return ManagerSettingOption;
  //     } else if (userDetail.role === PARTICIPANT) {
  //       return ParticipantSettingOption;
  //     } else if (userDetail.role === GUARDIAN) {
  //       return ParentSettingOption;
  //     }
  //   }
  //   return [];
  // })();

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;

  // const userSettingTitle = "User settings";
  // const userSettingSubTitle = "Here you can set preferences.";
  const walletConnectHeading = "Wallet Connect";

  // const handleSetBudgetToggle = () => {};

  // const handleAllowRepeatToggle = () => {};

  console.log(searchText);

  return (
    <div className={styles.userPage}>
      {userDetailLoading ? <LoadingModal /> : null}
      <div className={styles.userPageWelCard}>
        <PersonWelCard
          img={userDetail?.user?.firstName?.charAt(0).toLocaleUpperCase()}
          firstName={userDetail?.user?.firstName}
          lastName={userDetail?.user?.lastName}
          email={userDetail?.user?.email}
          id={userDetail?.user?.id}
          connectedTo={"Users"}
          // welCardLOver={styles.welCardLOver}
          // status={userDetail.active}
        />
      </div>
      <div className={styles.userPageHead}>
        {/* <SettingCard
          userSettingTitle={userSettingTitle}
          userSettingSubTitle={userSettingSubTitle}
          settingOptions={settingOptions}
          onSetBudgetToggle={handleSetBudgetToggle}
          onAllowRepeatToggle={handleAllowRepeatToggle}
        /> */}
        {userDetail && <UserInfocard userId={userDetail} />}
        <ConnectCard
          walletUsers={WalletConnect.wallets}
          heading={walletConnectHeading}
          connectedTo={"User"}
          walletMemberOver={styles.walletMemberOver}
        >
          {/* <MoreVertIcon className={styles.editIcon} /> */}
        </ConnectCard>
      </div>
      <div className={styles.userPageBottom}>
        <RecentTrans
          transList={transList.trans}
          setSearchText={setSearchText}
          setReceiptPresent={setReceiptPresent}
          setIsReviewed={setIsReviewed}
          userId={Number(userId)}
          role={userDetail?.user?.role}
          recentTransOver={styles.recentTransOver}
          subTitle={"These are the details of the transactions made by this user"}
        />

        <div className={styles.transCards}>
          {Object.entries(walletTransaction).map(([key, transItem]) => {
            let title;
            let icon;

            if (key === "totalTransactions") {
              title = "Transaction (30 Days)";
              icon = whiteFourPillarLogo;
            } else if (key === "totalTransactionsWithoutReceipts") {
              title = "Transactions without receipt (30 days)";
              icon = receiptLogo;
            }
            // else if (key === "totalCardBalance") {
            //   title = "Card Balance";
            //   icon = whiteCardLogo;
            // }

            return (
              ["totalTransactionsWithoutReceipts", "totalTransactions"].includes(key) &&
              transItem && (
                <WalletTransCard
                  key={transItem.id}
                  icon={icon}
                  title={title}
                  value={
                    title && ["Wallet Balance", "Card Balance"].includes(title)
                      ? `$${Number(transItem.current).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : transItem.current
                  }
                  growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                  growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                  from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                  WalletTransCardClass={WalletTransCardOver}
                  balCardClass={balCardOver}
                />
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}
