import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { fetchOrgList, orgListRequest, addOrg, addorgRequest } from "src/network/services/organisationService";
import swal from "sweetalert2";

type orgList = {
  id: number;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
};

const initialOrgList: orgList[] = [];

const organisationSlice = createSlice({
  name: "organisation",
  initialState: {
    orgListLoading: false,
    error: null,
    orgLists: {
      list: initialOrgList,
      total: 0
    }
  },
  reducers: {
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    addOrgStart: (state) => {
      state.orgListLoading = true;
    },
    addOrgSuccess: (state) => {
      state.orgListLoading = false;
    },
    addOrgFail: (state, action) => {
      state.orgListLoading = false;
      state.error = action.payload;
    }
  }
});

const { fetchOrgListStart, fetchOrgListSuccess, fetchOrgListFail, addOrgStart, addOrgSuccess, addOrgFail } =
  organisationSlice.actions;

export const fetchOrgListAction = (data: orgListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await fetchOrgList(data);

      dispatch(fetchOrgListSuccess(response));
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

export const addOrgAction = (data: addorgRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addOrgStart());
    try {
      await addOrg(data);

      dispatch(addOrgSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Organisation added Sucessfully",
        showConfirmButton: false,
        timer: 2500
      });
    } catch (error) {
      dispatch(addOrgFail(error));
    }
  };
};

export default organisationSlice.reducer;
