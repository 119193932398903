export const colors = [
  "#1D3557",
  "#457B9D",
  "#A8DADC",
  "#808000",
  "#023E8A",
  "#DE3163",
  "#FFBF00",
  "#CD5C5C",
  "#17A589",
  "#8E44AD"
];

export const roleColors: { [key: string]: string } = {
  ADMIN: "#007bff",
  ORGANISATION_SUPER_ADMIN: "#28a745",
  ORGANISATION_OPERATIONS_MANAGER: "#ffc107",
  ORGANISATION_FINANCE_MANAGER: "#dc3545",
  GUARDIAN: "#17a589",
  PARTICIPANT: "#8e44ad",
  SUPPORTER: "#00bcd4"
};
