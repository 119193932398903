import { FormEventHandler, useState, useEffect } from "react";
import styles from "./auth.module.scss";
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { spendAbleLogo } from "src/components/nav/logo";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { resetValidationAction, resetPasswordAction } from "./loginSlice";
import { useLocation, useNavigate } from "react-router-dom";
export default function ResetPassword() {
  const [isTokenValid, setIstokenValid] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState({ password: "" });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { resetValidationLoading, resetPasswordLoading } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      dispatch(resetValidationAction(token)).then(() => {
        setIstokenValid(true);
      });
    }
  }, [dispatch, token]);

  const handleSignIn: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (!password.trim() || !confirmPassword.trim()) {
      setPasswordError({ ...passwordError, password: "Password is required" });
      return;
    }

    if (password.length < 6) {
      setPasswordError({ ...passwordError, password: "Password must be at least 6 characters long" });
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError({ ...passwordError, password: "Passwords does not match" });
      return;
    }

    const data = {
      token,
      password
    };

    dispatch(resetPasswordAction(data)).then(() => {
      navigate("/sign-in");
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value !== confirmPassword) {
      setPasswordError({ ...passwordError, password: "Passwords does not match" });
    } else {
      setPasswordError({ ...passwordError, password: "" });
    }
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (password !== e.target.value) {
      setPasswordError({ ...passwordError, password: "Passwords does not match" });
    } else {
      setPasswordError({ ...passwordError, password: "" });
    }
  };

  return (
    <div className={`${styles.container}`} id="container">
      <div className={`${styles.formContainer} ${styles.signInContainer}`}>
        <form onSubmit={handleSignIn}>
          <div className={styles.formIn}>
            {resetValidationLoading ? (
              <CircularProgress sx={{ color: "#0f172a" }} />
            ) : (
              <>
                <div className={styles.logo}>
                  <div className={styles.logoL}>{spendAbleLogo}</div>
                  <div className={styles.logoR}>
                    <h1 className={styles.title}>SpendAble</h1>
                    <div className={styles.subTitle}>Enabling Choices, Empowering Change</div>
                  </div>
                </div>
                {isTokenValid && (
                  <>
                    {""}
                    {resetPasswordLoading ? (
                      <CircularProgress sx={{ color: "#0f172a" }} />
                    ) : (
                      <>
                        <div className={styles.header}>
                          <h1>Reset Password</h1>
                        </div>
                        <div className={styles.formInput}>
                          <div className={styles.resetPass}>
                            <label htmlFor="password">New Password</label>
                            <TextField
                              type={showPassword ? "text" : "password"}
                              id="password"
                              placeholder="PASSWORD"
                              value={password}
                              onChange={handlePasswordChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                            />
                          </div>
                          <div className={styles.resetPass}>
                            <label htmlFor="passw">Confirm Password</label>
                            <TextField
                              type="password"
                              id="passw"
                              placeholder="PASSWORD"
                              value={confirmPassword}
                              onChange={handleConfirmPasswordChange}
                              onPaste={(e) => e.preventDefault()}
                              onCopy={(e) => e.preventDefault()}
                              sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                            />
                            {passwordError.password && (
                              <div className={styles.error}>
                                <ErrorOutlineIcon className={styles.errIcon} />
                                {passwordError.password}
                              </div>
                            )}
                          </div>
                          <div className={styles.checkboxContainer}>
                            <div className={styles.checkboxR}></div>
                          </div>
                        </div>
                        <div className={styles.signIn}>
                          <Button type="submit" variant="contained" className={styles.SignInBtn}>
                            Reset
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className={styles.overlayContainerR}>
                  <div className={styles.overlayR}></div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      <div className={styles.overlayContainer}>
        <div className={styles.overlay}>
          <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
            <div className={styles.rightBg}>
              <div className={styles.rightCard}>
                <div className={styles.topText}>You're part of the solution to</div>
                <div className={styles.bottomText}>financial abuse</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
