import { useState, useEffect } from "react";
import styles from "./userInfoCard.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import Modalui from "src/components/UI/modal/Modalui";
import UserInfoEdiModal from "./userInfoEditModal/UserInfoEditModal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { updateUserDetailAction } from "src/page/userDetail/userDetailSlice";
import { CAN_EDIT_USER_INFORMATION, roleMapping } from "../constant/constant";
import Swal from "sweetalert2";
import { User, kycStatusType } from "src/types/commonTypes";
import { Link } from "react-router-dom";

const IOSSwitch = styled((props: SwitchProps & { checked?: boolean }) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0f172a",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 16
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#0f172a5e" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

interface UserInfocardProps {
  userId: {
    user: {
      id: number;
      active: boolean;
      email: string;
      firstName: string;
      lastName: string;
      dob: string;
      phoneNumber: string;
      role: string;
      state: string;
      city: string;
      address: string;
      zipcode: string;
      guardianId: number;
      kycStatus: kycStatusType | null;
    };
    guardian: User | null;
    canDoActiveInactive: boolean;
    canEditRole: boolean;
    canEditGuardian: boolean;
    canEditKycStatus: boolean;
  };
}

export default function UserInfocard({ userId }: UserInfocardProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [userStatus, setUserStatus] = useState(userId?.user?.active);
  const { config } = useAppSelector((state) => state.auth);

  useEffect(() => {
    setUserStatus(userId?.user?.active);
  }, [userId?.user?.active]);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (userId.canDoActiveInactive) {
      setUserStatus(event.target.checked);

      const userData = {
        active: !userStatus
      };

      dispatch(updateUserDetailAction(userId?.user?.id, userData));
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: `You are not permitted to ${userStatus ? "InActive" : "Active"} this user`,
        showConfirmButton: false,
        timer: 2500
      });
    }
  };

  return (
    <>
      <Modalui open={open} handleClose={handleModal}>
        <UserInfoEdiModal handleClose={handleModal} userDetail={userId} />
      </Modalui>
      <div className={styles.userHead}>
        <div className={styles.head}>
          <div className={styles.headL}>
            <h3>User information</h3>
            <p>Edit the information about you.</p>
          </div>
          {config?.allowedPermissions.includes(CAN_EDIT_USER_INFORMATION) && (
            <div className={styles.headR} onClick={handleModal}>
              <EditIcon className={styles.editIcon} />
            </div>
          )}
        </div>
        <div className={styles.userInfo}>
          <div className={styles.label}>
            First name:
            <span className={styles.value}>{userId?.user?.firstName}</span>
          </div>
          <div className={styles.label}>
            Last name:
            <span className={styles.value}>{userId?.user?.lastName}</span>
          </div>
          <div className={styles.label}>
            Mobile:
            <span className={styles.value}>{userId?.user?.phoneNumber}</span>
          </div>
          <div className={styles.label}>
            Role: <span className={styles.value}>{roleMapping[userId?.user?.role]}</span>
          </div>
          {/* <div className={styles.label}>
          Role Description:
          <span className={styles.value}>{"///still need description////"}</span>
        </div> */}
          <div className={styles.label}>
            Address:
            <span className={styles.value}>{userId?.user?.address}</span>
          </div>
          <div className={styles.bottomInfo}>
            <div className={styles.label}>
              City: <span className={styles.value}>{userId?.user?.city}</span>
            </div>
            <div className={styles.label}>
              State: <span className={styles.value}>{userId?.user?.state}</span>
            </div>
            <div className={styles.label}>
              Postcode:
              <span className={styles.value}>{userId?.user?.zipcode}</span>
            </div>
          </div>
          <div className={styles.label}>
            Active:
            <FormGroup className={styles.formGroup} style={{ marginTop: "-5px", marginLeft: "4px" }}>
              <FormControlLabel
                className={styles.formControlLabel}
                control={<IOSSwitch sx={{ m: 1 }} checked={userStatus} onChange={handleToggle} />}
                label=""
              />
            </FormGroup>
          </div>
          <div className={styles.label}>
            KYC Status:
            <span className={styles.value}>{userId.user.kycStatus}</span>
          </div>
          {userId.guardian != null && (
            <div className={styles.label}>
              Guardian:
              <Link
                style={{ textDecoration: "underline" }}
                to={`/users/${userId.guardian.id}`}
                className={styles.value}
              >
                {userId.guardian.fullName}
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
