import { CardAccessType, PaginationRequest } from "src/types/commonTypes";
import api from "../api";

export interface GetAllCardsRequest extends PaginationRequest {
  accessTypes?: CardAccessType[];
  active?: boolean | null;
  organisationId?: number;
  walletIds?: number[];
}
export interface UpdateCardRequest {
  accessType?: string;
  organisationId?: number;
  cardStatus?: boolean;
}

export const getAllCards = (data: GetAllCardsRequest) => {
  return api.post(`/cards/search`, data);
};

export interface connectCardRequest {
  cardId?: number;
  applyToAll?: boolean;
  action: string;
}

export const connectCard = (data: connectCardRequest, walletId: number) => {
  return api.post(`/wallets/${walletId}/cards`, data);
};

export const updateCard = (id: number, data: UpdateCardRequest) => {
  return api.put(`/cards/${id}`, data);
};

export interface orderCardRequest {
  userId: number;
  organisationId: number | null;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  postcode: string;
  accessType: string;
}

export const orderCard = (data: orderCardRequest) => {
  return api.post(`/cards/order`, data);
};

export interface cardPinRequest {
  pin: string;
}

export const changeCardPin = (data: cardPinRequest, id: number) => {
  return api.post(`/cards/${id}/changePin`, data);
};
