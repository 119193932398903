import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  fetchWallets,
  walletListRequest,
  fetchWalletTransaction,
  addWallet,
  addWalletRequest,
  walletTransRequest,
  unlinkWalletRequest,
  unlinkWallet,
  connectWalletRequest,
  connectWallet
} from "src/network/services/walletService";
import { fetchUserList, UserListRequest } from "src/network/services/UsersServices";
import { immediateToast } from "izitoast-react";
import { fetchOrgList, orgListRequest } from "src/network/services/organisationService";

type Wallet = {
  id: number;
  participant: { fullName: string; role: string };
  description: string;
  balance: number;
  status: boolean | null;
  cardBalance: number;
  organisationId: number;
};
const initialWallets: Wallet[] = [];

type ConnectWallet = {
  id: number;
  participant: { fullName: string; role: string };
  description: string;
};
const initialConnectWallets: ConnectWallet[] = [];

type orgList = {
  id: number;
  name: string;
  abn: string;
};

const initialOrgList: orgList[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];

type User = {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
};

const initialUsers: User[] = [];

const walletSlice = createSlice({
  name: "wallet",
  initialState: {
    walletLoading: false,
    walletConnectLoading: false,
    walletTransLoading: false,
    userLoading: false,
    unlinkWalletLoading: false,
    orgListLoading: false,
    connectWalletLoading: false,
    error: null,
    walletList: {
      list: initialWallets,
      total: 0
    },
    walletConnectList: {
      list: initialConnectWallets,
      total: 0
    },
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    orgLists: {
      list: initialOrgList,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    fetchWalletConnectStart: (state) => {
      state.walletConnectLoading = true;
    },
    fetchWalletConnectSuccess: (state, action) => {
      state.walletConnectLoading = false;
      state.walletConnectList.list = action.payload?.list ?? [];
      state.walletConnectList.total = action.payload?.total ?? 0;
    },
    fetchWalletConnectFail: (state, action) => {
      state.walletConnectLoading = false;
      state.walletConnectList.list = [];
      state.walletConnectList.total = 0;
      state.error = action.payload;
    },
    setWalletConnectAction: (state, action) => {
      state.walletConnectList.list = action?.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    addWalletStart: (state) => {
      state.walletLoading = true;
    },
    addWalletSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addWalletFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    unlinkWalletStart: (state) => {
      state.unlinkWalletLoading = true;
    },
    unlinkWalletSuccess: (state) => {
      state.unlinkWalletLoading = false;
      state.error = null;
    },
    unlinkWalletFail: (state, action) => {
      state.unlinkWalletLoading = false;
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    setOrgListAction: (state, action) => {
      state.orgLists.list = action?.payload;
    },
    connectWalletStart: (state) => {
      state.connectWalletLoading = true;
    },
    connectWalletSuccess: (state) => {
      state.connectWalletLoading = false;
      state.error = null;
    },
    connectWalletFail: (state, action) => {
      state.connectWalletLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchWalletTransactionsStart,
  fetchWalletTransactionsSuccess,
  fetchWalletTransactionsFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  addWalletStart,
  addWalletSuccess,
  addWalletFail,
  unlinkWalletStart,
  unlinkWalletSuccess,
  unlinkWalletFail,
  fetchOrgListStart,
  fetchOrgListSuccess,
  fetchOrgListFail,
  fetchWalletConnectStart,
  fetchWalletConnectSuccess,
  fetchWalletConnectFail,
  connectWalletStart,
  connectWalletSuccess,
  connectWalletFail
} = walletSlice.actions;

export const { setOrgListAction, setWalletConnectAction } = walletSlice.actions;

export const fetchWalletListAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchWalletConnectListAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletConnectStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletConnectSuccess(response));
    } catch (error) {
      dispatch(fetchWalletConnectFail(error));
    }
  };
};

export const fetchWalletTransactionAction = (data: walletTransRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletTransactionsStart());
    try {
      const response = await fetchWalletTransaction({
        ...data,
        showTotalWalletBalance: true,
        showTotalTransactions: true,
        showTotalRepeatPayBalance: true,
        showTotalCardBalance: true,
        showSupportersConnectedWallet: true
      });

      dispatch(fetchWalletTransactionsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletTransactionsFail(error));
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const addWalletAction = (data: addWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addWalletStart());
    try {
      const response = await addWallet(data);

      dispatch(addWalletSuccess());
      immediateToast("success", {
        message: "Wallet Successfully Added.",
        timeout: 3000,
        position: "topCenter"
      });
      return response;
    } catch (error) {
      dispatch(addWalletFail(error));
      throw error;
    }
  };
};

export const unlinkWalletAction = (data: unlinkWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(unlinkWalletStart());
    try {
      const response = await unlinkWallet(data);

      dispatch(unlinkWalletSuccess());
      immediateToast("success", {
        message: "Wallet Successfully unlinked.",
        timeout: 3000,
        position: "topCenter"
      });
      return response;
    } catch (error) {
      dispatch(unlinkWalletFail(error));
      throw error;
    }
  };
};

export const fetchOrgListAction = (data: orgListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await fetchOrgList(data);

      dispatch(fetchOrgListSuccess(response));
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

export const connectWalletAction = (organisationId: number, data: connectWalletRequest, sucessfullMessage: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(connectWalletStart());
    try {
      await connectWallet(organisationId, data);

      dispatch(connectWalletSuccess());
      immediateToast("success", {
        message: `Wallet Successfully ${sucessfullMessage}.`,
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(connectWalletFail(error));
    }
  };
};

export default walletSlice.reducer;
