import api from "src/network/api";

export interface updateBudgetRequest {
  budgets: {
    budgetId: number | null;
    amount: number;
    itemCategory: {
      id: number;
      categoryName: string;
    };
  }[];
  walletId: number;
}

export const getMasterList = () => {
  return api.get("/items?isMaster=true");
};

export const updateBudget = (data: updateBudgetRequest) => {
  return api.put("/budgets", data);
};

export const deleteBudget = (budgetId: number) => {
  return api.delete(`/budgets/${budgetId}`);
};
