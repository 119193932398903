import { useState } from "react";
import Button from "@mui/material/Button";
import styles from "./deleteSupporterModal.module.scss";
import { Avatar } from "@mui/material";
import { colors } from "src/variable/randomColor";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface Iprops {
  img?: string;
  name?: string;
  email: string;
  supporterId: number;
  handleClose: () => void;
  onDeleteSupporter: (_email: string, _supporterId: number, _removeItFromAll?: boolean) => void;
  deleteInput?: {
    buttonInfo: string;
    whomToRemove: string;
    headInfo: string;
  };
  modalOf?: "UNLINK" | "LINK";
}

export default function DeleteSettingModal({
  img,
  name,
  email,
  supporterId,
  handleClose,
  onDeleteSupporter,
  deleteInput,
  modalOf
}: Iprops) {
  const [connectToParticipant, setConnectToParticipant] = useState<boolean | null>(null);
  const [radioError, setRadioError] = useState<string>("");

  const handleDeleteSupporter = () => {
    if (modalOf !== "UNLINK") {
      if (connectToParticipant === null) {
        setRadioError("Please select an option");
        return;
      }
      onDeleteSupporter(email, supporterId, connectToParticipant);
    } else {
      onDeleteSupporter(email, supporterId);
    }
    handleClose();
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnectToParticipant(e.target.value === "Yes");
    setRadioError("");
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.setting}>
            <div className={styles.settingL}>
              <h4>{deleteInput?.headInfo}</h4>
            </div>
            <div className={styles.settingR}>
              <div className={styles.image}>
                {/* <img src={img} alt="image" /> */}
                <Avatar
                  id="av"
                  className={styles.av}
                  aria-label="Recipe"
                  style={{ backgroundColor: colors[supporterId % 10] }}
                >
                  {img}
                </Avatar>
              </div>
              <div className={styles.supportInfo}>
                <h6>{name}</h6>
                <p>{email}</p>
              </div>
            </div>
            {modalOf !== "UNLINK" ? (
              <div className={styles.RadioInput}>
                <label>Remove it from all {deleteInput?.whomToRemove}?</label>
                <div className={styles.inputs}>
                  <div className={styles.inputYes}>
                    <input type="radio" name="connectOption" value="Yes" id="yes" onChange={handleRadioChange} />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className={styles.inputNo}>
                    <input type="radio" name="connectOption" value="No" id="No" onChange={handleRadioChange} required />
                    <label htmlFor="No">No</label>
                  </div>
                </div>
                {radioError && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {radioError}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <div className={styles.btnL}>
            <Button className={styles.reportBtn} variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className={styles.btnR}>
            <Button className={styles.sendBtn} variant="contained" onClick={handleDeleteSupporter}>
              {deleteInput?.buttonInfo}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
