import React, { useState, useEffect, useCallback } from "react";
import styles from "./walletListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./walletListTable/WalletListTable";
import Modalui from "src/components/UI/modal/Modalui";
import AddWalletModal from "src/components/walletList/addWalletModal/AddWalletModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchWalletListAction, addWalletAction, connectWalletAction } from "src/page/wallets/walletSlice";
import UserListFilter from "./userListFilter/UserListFilter";
import { useSearchParams, useParams, useLocation } from "react-router-dom";
import {
  WAL_END_DATE,
  WAL_LIMIT,
  WAL_MAX_AMT,
  WAL_MIN_AMT,
  WAL_PAGE,
  WAL_PARTICIPANTS,
  WAL_START_DATE,
  WAL_SUPPORTERS
} from "../constant/queryConstants";
import { utc } from "moment";
import DeleteSettingModal from "../connectCard/connectMember/deleteSupporterModal/DeleteSupporterModal";
import WalletIcon from "@mui/icons-material/Wallet";
import ConnectWalletModal from "./connectWalletModal/ConnectWalletModal";
import { CAN_CONNECT_WALLET_TO_ORGANISATION } from "../constant/constant";

type AddWalletIn = {
  description: string;
  participantId: number;
  defaultWallet: boolean;
};

type WalletIn = {
  walletId: number;
  organisationId?: number;
};

interface TableData {
  id: number;
  participant: {
    firstName: string;
    lastname: string;
    email: string;
    fullName: string;
  };
  description: string;
}

function WalletListCard() {
  const [open, setOpen] = useState(false);
  const [openConnectWallet, setOpenConnectWallet] = useState(false);
  const [unlinkModal, setUnlinkModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [unlinkData, setUnlinkData] = useState({ id: 0, name: "", img: "", email: "" });
  const { walletList } = useAppSelector((state) => state.wallet);
  const { config } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { organisationId } = useParams();
  const location = useLocation();

  const fetchWalletsData = useCallback(() => {
    const minBalanceParam = searchParams.get(WAL_MIN_AMT);
    const maxBalanceParams = searchParams.get(WAL_MAX_AMT);
    const walLimit = searchParams.get(WAL_LIMIT);
    const walPage = searchParams.get(WAL_PAGE);
    const data = {
      page: walPage ? Number(walPage) : 0,
      limit: walLimit ? Number(walLimit) : 10,
      searchText,
      startDate: utc(searchParams.get(WAL_START_DATE)).toISOString(),
      endDate: utc(searchParams.get(WAL_END_DATE)).endOf("day").toISOString(),
      minBalance: minBalanceParam ? Number(minBalanceParam) : null,
      maxBalance: maxBalanceParams ? Number(maxBalanceParams) : null,
      participantIds:
        searchParams
          .get(WAL_PARTICIPANTS)
          ?.split("&")
          .map((i) => Number(i)) || [],
      supporterIds:
        searchParams
          .get(WAL_SUPPORTERS)
          ?.split("&")
          .map((i) => Number(i)) || [],
      organisationId: Number(organisationId) || null
    };

    dispatch(fetchWalletListAction(data));
  }, [dispatch, organisationId, searchParams, searchText]);

  const isWalletListEmpty = !walletList || !walletList.list || walletList.list.length === 0;

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenConnectWallet = () => {
    setOpenConnectWallet(!openConnectWallet);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(WAL_PAGE, newPage + "");
    setSearchParams(searchParams);
    fetchWalletsData();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(WAL_LIMIT, event.target.value);
    searchParams.delete(WAL_PAGE);
    setSearchParams(searchParams);
    fetchWalletsData();
  };

  const handleFilter = () => {
    fetchWalletsData();
  };

  const handleAddWallet = (walletData: AddWalletIn, close: boolean) => {
    const WalletData = {
      ...walletData,
      organisationId: Number(organisationId) || null
    };

    dispatch(addWalletAction(WalletData)).then(() => {
      fetchWalletsData();
      if (close) {
        handleClose();
      }
    });
  };

  const handleConnectWallet = (walletData: WalletIn, close: boolean) => {
    const WalletData = {
      ...walletData,
      action: "LINK"
    };

    delete WalletData.organisationId;

    console.log(WalletData);

    dispatch(connectWalletAction(Number(walletData.organisationId), WalletData, "Connected")).then(() => {
      fetchWalletsData();
      if (close) {
        handleOpenConnectWallet();
      }
    });
  };

  const handleUnlinkWallet = (data: TableData) => {
    console.log("dataaa", data);
    setUnlinkData({
      id: data.id,
      name: `${data.participant.fullName}`,
      img: data.participant.firstName?.charAt(0).toLocaleUpperCase(),
      email: data.description
    });
    setUnlinkModal(true);
  };

  let unlinkOperation;

  if (
    config?.allowedPermissions.includes(CAN_CONNECT_WALLET_TO_ORGANISATION) &&
    (organisationId || config?.defaultOrganisation?.id)
  ) {
    unlinkOperation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: TableData) => (
        <div onClick={() => handleUnlinkWallet(record)} className={styles.unlinkOperation}>
          <Tooltip title="Unlink" arrow>
            <LinkOffIcon />
          </Tooltip>
        </div>
      ),
      width: 100
    };
  } else {
    unlinkOperation = {};
  }

  const updatedColumns = [...columns, unlinkOperation];

  useEffect(() => {
    fetchWalletsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, location.pathname]);

  const handleUnlinkClose = () => {
    setUnlinkModal(false);
  };

  const deleteInput = {
    buttonInfo: "Unlink",
    whomToRemove: "wallet's",
    headInfo: "Are you sure, you want to Unlink this wallet?"
  };

  const onUnlinkWallet = (email: string, supporterId: number) => {
    const data = {
      walletId: supporterId,
      action: "UNLINK"
    };

    dispatch(connectWalletAction(Number(organisationId || config?.defaultOrganisation?.id), data, "Uninked")).then(
      () => {
        fetchWalletsData();
      }
    );
  };

  return (
    <>
      <Modalui open={openFilter} handleClose={handleCloseFilter} modaluiOver={styles.filterModalOver}>
        <UserListFilter handleClose={handleCloseFilter} handleFilter={handleFilter} />
      </Modalui>
      <Modalui open={unlinkModal} handleClose={handleUnlinkClose}>
        <DeleteSettingModal
          img={unlinkData.img}
          name={unlinkData.name}
          email={unlinkData.email}
          supporterId={unlinkData.id}
          handleClose={handleUnlinkClose}
          onDeleteSupporter={onUnlinkWallet}
          deleteInput={deleteInput}
          modalOf={"UNLINK"}
        />
      </Modalui>
      <div className={styles.walletList}>
        <div className={styles.walletListHead}>
          <div className={styles.walletListHeadIn}>
            <div className={styles.walletListHeadD}>
              <h3>Wallets List</h3>
              <p>Pick a wallet to view the details, edit budgets and connect users</p>
            </div>
            <div className={styles.walletListHeadBtn}>
              <Button variant="outlined" className={styles.filterBtn} onClick={handleOpenFilter}>
                Filter Wallet
              </Button>
              {config?.allowedPermissions.includes(CAN_CONNECT_WALLET_TO_ORGANISATION) &&
                (organisationId || config?.defaultOrganisation?.id) && (
                  <div onClick={handleOpenConnectWallet}>
                    <Button variant="contained" className={styles.addWalletBtn}>
                      <div>
                        <WalletIcon />
                      </div>
                      <div>Connect Wallet</div>
                    </Button>
                  </div>
                )}
              <div onClick={handleOpen}>
                <Button variant="contained" className={styles.addWalletBtn}>
                  <div>
                    <PersonAddAlt1Icon />
                  </div>
                  <div>Add wallet</div>
                </Button>
              </div>
              <Modalui open={open} handleClose={handleClose} modaluiOver={styles.modaluiOver}>
                <AddWalletModal
                  handleClose={handleClose}
                  isWalletListEmpty={isWalletListEmpty}
                  onAddWallet={handleAddWallet}
                />
              </Modalui>
              <Modalui open={openConnectWallet} handleClose={handleOpenConnectWallet} modaluiOver={styles.modaluiOver}>
                <ConnectWalletModal handleClose={handleOpenConnectWallet} onConnectWallet={handleConnectWallet} />
              </Modalui>
            </div>
          </div>
          <div className={styles.walletListFilter}>
            {/* <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button>All</Button>
            <Button>Participants</Button>
            <Button>Supporter</Button>
          </ButtonGroup> */}
            <div className={styles.filterSearch}></div>
            <div className={styles.walletListSearch}>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
        </div>
        <div className={styles.walletListTable}>
          <Table columns={updatedColumns} data={walletList.list} className={styles.tableIn} />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={walletList.total}
            page={searchParams.get(WAL_PAGE) ? Number(searchParams.get(WAL_PAGE)) : 0}
            rowsPerPage={searchParams.get(WAL_LIMIT) ? Number(searchParams.get(WAL_LIMIT)) : 10}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default WalletListCard;
