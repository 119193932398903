import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./attachUser.module.scss";
import CloseIcon from "@mui/icons-material/Close";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface AddUserModalProps {
  handleClose: () => void;
  onConnectUser: (_emailId: string, _role: string | null, _close: boolean) => void;
}
export default function AttachUser({ handleClose, onConnectUser }: AddUserModalProps) {
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState({ email: "", role: "" });

  const handleCancelClose = () => {
    handleClose();
  };

  const handleCancel = () => {
    if (!emailId.trim()) {
      setError({
        email: emailId ? "" : "Email is required",
        role: ""
      });
      return;
    }

    onConnectUser(emailId, null, true);
  };

  const handleAddUser = () => {
    if (!emailId.trim()) {
      setError({
        email: emailId ? "" : "Email is required",
        role: ""
      });
      return;
    }

    onConnectUser(emailId, null, false);
    setEmailId("");

    setError({
      email: "",
      role: ""
    });
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect User</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon onClick={handleCancelClose} />
            </button>
          </div>

          <div className={styles.email}>
            <label>User Email</label>
            <input
              type="email"
              placeholder="example@gmail.com"
              value={emailId}
              onChange={(e) => setEmailId(e.target.value)}
            />
            {error.email && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.email}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleCancel}>
              Add & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddUser}>
              Add & another User
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
