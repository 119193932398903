import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addUserModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";

import { roleMapping, reverseRoleMapping } from "src/components/constant/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppSelector } from "src/page/store";
import { Autocomplete, TextField } from "@mui/material";

type UserIn = {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
};
interface AddUserModalProps {
  handleClose: () => void;
  onAddUser: (_addUser: UserIn, _close: boolean) => void;
}
export default function AddUserModal({ handleClose, onAddUser }: AddUserModalProps) {
  const { config } = useAppSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState({ email: "", firstName: "", lastName: "", role: "" });
  const [role, setRole] = useState("");

  const handleCancelClose = () => {
    handleClose();
  };

  const handleCancel = () => {
    if (!email.trim() || !firstName.trim() || !lastName.trim() || !role.trim()) {
      setError({
        email: email ? "" : "Email is required",
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        role: role ? "" : "Role is required"
      });
      return;
    }

    const userData = {
      email,
      firstName,
      lastName,
      role: reverseRoleMapping[role]
    };

    onAddUser(userData, true);
  };

  const handleAddUser = () => {
    if (!email.trim() || !firstName.trim() || !lastName.trim() || !role.trim()) {
      setError({
        email: email ? "" : "Email is required",
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        role: role ? "" : "Role is required"
      });
      return;
    }

    const userData = {
      email,
      firstName,
      lastName,
      role: reverseRoleMapping[role]
    };

    onAddUser(userData, false);
    setEmail("");
    setFirstName("");
    setLastName("");
    setError({
      email: "",
      firstName: "",
      lastName: "",
      role: ""
    });
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setRole(value || "Participant");
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Add User</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon onClick={handleCancelClose} />
            </button>
          </div>

          <div className={styles.email}>
            <label>User Email</label>
            <input
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error.email && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.email}
              </div>
            )}
          </div>
          <div className={styles.name}>
            <div className={styles.Fname}>
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {error.firstName && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.firstName}
                </div>
              )}
            </div>
            <div className={styles.Lname}>
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {error.lastName && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.lastName}
                </div>
              )}
            </div>
          </div>
          <div className={styles.Role}>
            <label>Role</label>
            <Autocomplete
              className={styles.autoComplete}
              options={config?.rolesCanBeAdded.map((roles) => roleMapping[roles]) || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
              value={role}
              onChange={handleParticipantChange}
            />
            {error.role && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.role}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleCancel}>
              Add & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddUser}>
              Add & another User
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
