import { PaginationRequest } from "src/types/commonTypes";
import api from "../api";

export interface superledgerRequest extends PaginationRequest {
  walletIds: number | null;
  userIds: number | null;
  filterContext?: string;
}

export const fetchSuperledgers = (data: superledgerRequest) => {
  return api.post(`/superledgers/search`, data);
};
