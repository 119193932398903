import TablePagination from "@mui/material/TablePagination";
import styles from "./superLedgers.module.scss";
// import Button from "@mui/material/Button";
import Table from "rc-table";
import { columns } from "./superLedgerConfig";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchSuperledgerAction } from "./superLedgerSlice";
// import { CardAccessType } from "src/types/commonTypes";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import {
  // SUPERLEDGER_END_DATE,
  SUPERLEDGER_LIMIT,
  SUPERLEDGER_PAGE,
  SUPERLEDGER_USERID,
  SUPERLEDGER_WALLETID
  // SUPERLEDGER_START_DATE
} from "src/components/constant/queryConstants";
import { useLocation, useSearchParams } from "react-router-dom";
import SuperledgerFilter from "src/components/superledgerFilter/SuperledgerFilter";
// import OrderCardModal from "src/components/orderCardModal/OrderCardModal";
import Modalui from "src/components/UI/modal/Modalui";
// import { utc } from "moment";

function SuperLedgers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { superledger } = useAppSelector((state) => state.superledger);
  const dispatch = useAppDispatch();
  const [openFilter, setOpenFilter] = useState(false);

  const page = searchParams.get(SUPERLEDGER_PAGE) ?? "0";
  const rowsPerPage = searchParams.get(SUPERLEDGER_LIMIT) ?? "10";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(SUPERLEDGER_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(SUPERLEDGER_LIMIT, event.target.value as string);
    searchParams.set(SUPERLEDGER_PAGE, "0");
    setSearchParams(searchParams);
  };

  const fetchSuperledgerData = useCallback(() => {
    dispatch(
      fetchSuperledgerAction({
        page: parseInt(page),
        limit: parseInt(rowsPerPage),
        // startDateInZonedDateTime: utc(searchParams.get(SUPERLEDGER_START_DATE)).toISOString(),
        // endDateInZonedDateTime: utc(searchParams.get(SUPERLEDGER_END_DATE)).endOf("day").toISOString(),
        walletIds: Number(searchParams.get(SUPERLEDGER_WALLETID)) || null,
        userIds: Number(searchParams.get(SUPERLEDGER_USERID)) || null
      })
    );
  }, [dispatch, page, rowsPerPage, searchParams]);

  useEffect(() => {
    fetchSuperledgerData();
  }, [fetchSuperledgerData, location.pathname]);

  function handleFilterModal() {
    setOpenFilter(!openFilter);
  }

  const handleFilter = () => {
    fetchSuperledgerData();
  };

  return (
    <div className={styles.superledgersWrapper}>
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"View Your Super Ledger"} subHeading={"View details of all the Super Ledgers"} />
      </div>
      <div className={styles.superledgerList}>
        <div className={styles.superledgerListHead}>
          <div className={styles.superledgerListHeadIn}>
            <div className={styles.superledgerListHeadD}>
              <h3>Super Ledgers list</h3>
              <p>Manage all the Super Ledgers</p>
            </div>
            <div className={styles.superledgerListHeadBtn}>
              {/* <Button variant="outlined" className={styles.filterBtn} onClick={handleFilterModal}>
                <div>Filter Super Ledger</div>
              </Button> */}
              {/* <Button variant="contained" className={styles.addTransactionBtn} onClick={handleDownload}>
                Download Transactions
              </Button> */}
              <Modalui open={openFilter} handleClose={handleFilterModal} modaluiOver={styles.modaluiOver}>
                <SuperledgerFilter handleClose={handleFilterModal} handleFilter={handleFilter} />
              </Modalui>
            </div>
          </div>
        </div>
        <div className={styles.superledgerListTable}>
          <Table columns={columns} data={superledger.list} rowKey="id" />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={superledger.total ?? 0}
            page={parseInt(page)}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(rowsPerPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default SuperLedgers;
