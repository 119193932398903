import { Avatar } from "@mui/material";
import styles from "../orgListCard.module.scss";
import { Link } from "react-router-dom";
import { colors } from "src/variable/randomColor";
import { unix } from "moment";

interface Org {
  id: number;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
}

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    Key: "id",
    render: (id: number) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Company Name",
    dataIndex: "name",
    key: "name",
    // width: 150,
    render: (name: string, data: Org) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          {/* <img src={} alt="Member" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: colors[data.id % 10] }}>
            {/* {this.props.userName.charAt(0).toLocaleUpperCase()} */}
            {name?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>{name}</div>
          <div className={styles.tableMemberEmail}>{data?.abn}</div>
        </div>
      </div>
    )
  },
  {
    title: "Date / Time",
    dataIndex: "createdBy",
    key: "createdBy",
    // width: 150,
    render: (createdBy: number) => {
      if (createdBy === undefined) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }

      const formattedDate = unix(createdBy).format("L");
      const formattedTime = unix(createdBy).format("LT");

      return (
        <>
          <div>{formattedDate}</div>
          <div>{formattedTime}</div>
        </>
      );
    }
  },

  {
    title: "Name",
    dataIndex: "referenceName",
    key: "referenceName",
    // width: 100,
    render: (referenceName: string) => (
      <div className={styles.tableRole}>
        <div className={styles.tableRoleName}>{referenceName}</div>
      </div>
    )
  },
  {
    title: "Organisation Contact",
    dataIndex: "phone",
    key: "phone",
    // width: 100,
    render: (phone: string) => (
      <div className={styles.tableRole}>
        <div className={styles.tableRoleName}>{phone}</div>
      </div>
    )
  },
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (active: boolean) => (
      <div className={styles.tableStatus}>
        <div className={`${active ? styles.green : styles.grey}`}>{active ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: Org) => {
      return (
        <Link to={`/organisation/${data.id}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      );
    }
    // width: 100,
  }
];

export default columns;
