import type { CognitoConfig, HostPrefix } from "./Config";

const cognitoConfig: {
  [key in HostPrefix]: CognitoConfig;
} = {
  possibility: {
    Auth: {
      clientId: "7vvhcl9o0p63ha7d6hvbcl2mkq",
      clientSecret: "hto9v19em67rot46upthb01c8ciuj10mlh06g5mt9bts0dc1dfv",
      basic:
        "Basic N3Z2aGNsOW8wcDYzaGE3ZDZodmJjbDJta3E6aHRvOXYxOWVtNjdyb3Q0NnVwdGhiMDFjOGNpdWoxMG1saDA2ZzVtdDlidHMwZGMxZGZ2"
    },
    Pool: {
      poolId: "ap-southeast-2_r94GM8hmq"
    },
    baseUrl: "https://cognito.gimmeit.net.au",
    redirectUrl: "http://localhost:3000",
    oauthProvider:
      "https://cognito.gimmeit.net.au/oauth2/authorize?client_id=7vvhcl9o0p63ha7d6hvbcl2mkq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
  },
  hub: {
    Auth: {
      clientId: "7vvhcl9o0p63ha7d6hvbcl2mkq",
      clientSecret: "hto9v19em67rot46upthb01c8ciuj10mlh06g5mt9bts0dc1dfv",
      basic:
        "Basic N3Z2aGNsOW8wcDYzaGE3ZDZodmJjbDJta3E6aHRvOXYxOWVtNjdyb3Q0NnVwdGhiMDFjOGNpdWoxMG1saDA2ZzVtdDlidHMwZGMxZGZ2"
    },
    Pool: {
      poolId: "ap-southeast-2_r94GM8hmq"
    },
    baseUrl: "https://cognito.gimmeit.net.au",
    redirectUrl: "http://localhost:3000",
    oauthProvider:
      "https://cognito.gimmeit.net.au/oauth2/authorize?client_id=7vvhcl9o0p63ha7d6hvbcl2mkq&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A3000"
  }
};

export default cognitoConfig;
