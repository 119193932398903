import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./userInfoModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
// import { useAppDispatch } from "src/page/store";
// import { addUserAction } from "src/page/users/usersSlice";
// import { PARTICIPANT } from "src/components/constant/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserDetailAction,
  fetchUserListAction,
  updateUserDetailAction
} from "src/page/userDetail/userDetailSlice";
import { GUARDIAN, reverseRoleMapping, roleMapping } from "src/components/constant/constant";
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { debounce } from "lodash";
import { User, kycStatusType } from "src/types/commonTypes";

type UserIn = {
  user: {
    id: number;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    phoneNumber: string;
    role: string;
    state: string;
    city: string;
    address: string;
    zipcode: string;
    guardianId: number;
    kycStatus: kycStatusType | null;
  };
  guardian: User | null;
  canDoActiveInactive: boolean;
  canEditRole: boolean;
  canEditGuardian: boolean;
  canEditKycStatus: boolean;
};
interface AddUserModalProps {
  handleClose: () => void;
  userDetail: UserIn;
  onAddUser?: (_addUser: UserIn, _close: boolean) => void;
}
export default function UserInfoEdiModal({ handleClose, userDetail }: AddUserModalProps) {
  const { config } = useAppSelector((Rstate) => Rstate.auth);
  const { users } = useAppSelector((Rstate) => Rstate.userDetail);
  const dispatch = useAppDispatch();
  const [firstName, setFirstName] = useState(userDetail?.user?.firstName);
  const [lastName, setLastName] = useState(userDetail?.user?.lastName);
  const [mobile, setMobile] = useState(userDetail?.user?.phoneNumber);
  const [kyc, setKyc] = useState<kycStatusType>(
    userDetail?.user?.kycStatus !== null ? userDetail?.user?.kycStatus : "fail"
  );
  const [address, setAddress] = useState(userDetail?.user?.address);
  const [city, setCity] = useState(userDetail?.user?.city);
  const [state, setState] = useState(userDetail?.user?.state);
  const [postCode, setPostCode] = useState(userDetail?.user?.zipcode);
  const [role, setRole] = useState(userDetail?.user?.role);
  const [user, setUser] = useState<User | undefined>(userDetail.guardian as User);

  const [error, setError] = useState({ firstName: "", lastName: "", role: "" });

  const handleCancelClose = () => {
    handleClose();
  };

  const handleUpdateUser = () => {
    if (!firstName.trim() || !lastName.trim() || !role.trim()) {
      setError({
        firstName: firstName.trim() ? "" : "First name is required",
        lastName: lastName.trim() ? "" : "Last name is required",
        role: role.trim() ? "" : "Role is Required"
      });
      return;
    }

    const userData = {
      firstName,
      lastName,
      phoneNumber: mobile,
      state,
      city,
      address,
      zipcode: postCode,
      role: reverseRoleMapping[role],
      guardianId: user?.id,
      kycStatus: kyc
    };

    dispatch(updateUserDetailAction(userDetail.user.id, userData)).then(() => {
      dispatch(fetchUserDetailAction(Number(userDetail.user.id)));
      handleClose();
    });
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: string | null) => {
    setRole(value || "Participant");
  };

  const handleKycChange = (e: SelectChangeEvent<kycStatusType>) => {
    const value = e.target.value;

    setKyc(value as kycStatusType);
  };

  const fetchOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];

    const data = {
      page: 0,
      limit: 30,
      searchText: inputValue,
      filterContext: GUARDIAN
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchOptions(newInputValue);
  };

  const handleSelectionChange = (event: React.ChangeEvent<object>, newValue: any) => {
    const id = newValue?.id || 0;
    const selectedUser = users.list.find((item) => item.id === id);

    setUser(selectedUser);
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>User Information</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon onClick={handleCancelClose} />
            </button>
          </div>

          <div className={styles.name}>
            <div className={styles.Fname}>
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {error.firstName && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.firstName}
                </div>
              )}
            </div>
            <div className={styles.Lname}>
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {error.lastName && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.lastName}
                </div>
              )}
            </div>
          </div>

          <div className={styles.lineII}>
            <div className={styles.mobile}>
              <label>Mobile</label>
              <input
                type="type"
                placeholder="Enter Text Here"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className={styles.role}>
              <label>Role</label>
              {userDetail?.canEditRole ? (
                <div className={styles.roleInput}>
                  <Autocomplete
                    className={styles.autoComplete}
                    options={config?.rolesCanBeAdded.map((roles) => roleMapping[roles]) || []}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <TextField {...params} placeholder="Select your Role.." />}
                    value={roleMapping[role] || role}
                    onChange={handleParticipantChange}
                    // sx={{ width: 220 }}
                  />
                </div>
              ) : (
                <div className={styles.disableRoleInput}>
                  <input type="text" value={roleMapping[userDetail?.user?.role]} disabled />
                </div>
              )}
              {error.role && (
                <div className={styles.error}>
                  <ErrorOutlineIcon className={styles.errIcon} />
                  {error.role}
                </div>
              )}
            </div>
          </div>
          <div className={styles.kycrow}>
            <div className={styles.guardian}>
              <label>Guardian</label>
              {userDetail?.canEditGuardian ? (
                <div className={styles.guardianInput}>
                  <Autocomplete
                    options={users.list}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onInputChange={handleInputChange}
                    onChange={handleSelectionChange}
                    value={user}
                    // renderInput={(parms) => <TextField {...parms} variant="outlined" />}
                    renderInput={(parms) => (
                      <TextField
                        {...parms}
                        variant="outlined"
                        sx={{
                          height: 50,
                          // width: "120%",
                          "& .MuiOutlinedInput-root": {
                            height: "100%",
                            alignItems: "center"
                          }
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start"
                        }}
                      >
                        <span>
                          {option.firstName} {option.lastName}
                        </span>
                        <span>{option.email}</span>
                      </li>
                    )}
                    ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                  />
                </div>
              ) : (
                <div className={styles.disableRoleInput}>
                  <input type="text" value={user?.fullName} disabled />
                </div>
              )}
            </div>
            <div className={styles.kyc}>
              <label>KYC</label>
              {userDetail?.canEditKycStatus ? (
                <FormControl>
                  <Select
                    value={kyc}
                    onChange={handleKycChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 50
                      // width: "120%"
                    }}
                  >
                    <MenuItem value="pass">Pass</MenuItem>
                    <MenuItem value="fail">Fail</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <div className={styles.disableRoleInput}>
                  <input type="text" value={kyc ? "Yes" : "No"} disabled />
                </div>
              )}
            </div>
          </div>
          <div className={styles.lineIII}>
            <div className={styles.address}>
              <label>Address</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.lineIV}>
            <div className={styles.city}>
              <label>City</label>
              <input type="text" placeholder="Enter Text Here" value={city} onChange={(e) => setCity(e.target.value)} />
            </div>
            <div className={styles.state}>
              <label>State</label>
              <input
                type="text"
                placeholder="Enter Text Here"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className={styles.postCode}>
              <label>PostCode</label>
              <input
                type="number"
                placeholder="Enter Text Here"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.sendBtn} variant="contained" onClick={handleUpdateUser}>
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
