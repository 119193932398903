import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  UserListRequest,
  fetchUserList,
  getUserDetail,
  updateUserDetail,
  userDetailRequest
} from "src/network/services/UsersServices";
import {
  fetchWallets,
  walletListRequest,
  fetchWalletTransaction,
  walletTransRequest
} from "src/network/services/walletService";
import { fetchPaymentRequest, TransListRequest } from "src/network/services/transactionServices";
import swal from "sweetalert2";
import { User, kycStatusType } from "src/types/commonTypes";

type UserDetail = {
  user: {
    id: number;
    active: boolean;
    email: string;
    firstName: string;
    lastName: string;
    dob: string;
    phoneNumber: string;
    role: string;
    state: string;
    city: string;
    address: string;
    zipcode: string;
    country: string;
    accessCode: string;
    fullName: string;
    guardianId: number;
    kycStatus: kycStatusType | null;
  };
  guardian: User | null;
  canDoActiveInactive: boolean;
  canEditRole: boolean;
  canEditGuardian: boolean;
  canEditKycStatus: boolean;
};

const initialUserDetail: UserDetail = {
  user: {
    id: 0,
    active: false,
    email: "",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    role: "",
    state: "",
    city: "",
    address: "",
    zipcode: "",
    country: "",
    accessCode: "",
    fullName: "",
    guardianId: 0,
    kycStatus: "fail"
  },
  guardian: null,
  canDoActiveInactive: false,
  canEditRole: false,
  canEditGuardian: false,
  canEditKycStatus: false
};

type GuardianDetail = {
  user: {
    id: number;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
  };
};

const initialGuardianDetail: GuardianDetail = {
  user: {
    id: 0,
    fullName: "",
    firstName: "",
    lastName: "",
    email: "",
    role: ""
  }
};

type Wallet = {
  id: number;
  participant: { id: number; firstName: string; lastName: string; fullName: string; email: string };
  description: string;
  balance: number;
};
const initialWallets: Wallet[] = [];

type Transaction = {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
};

const initialTransaction: Transaction[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];

type UserList = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const initialUsers: UserList[] = [];

const userDetailSlice = createSlice({
  name: "userDetail",
  initialState: {
    userDetailLoading: false,
    walletLoading: false,
    transLoading: false,
    walletTransLoading: false,
    userLoading: false,
    guardianDetailloading: false,
    error: null,
    userDetail: initialUserDetail,
    WalletConnect: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    guardianDetail: initialGuardianDetail
  },
  reducers: {
    fetchUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    fetchUserDetailSuccess: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = action.payload ?? initialUserDetail;
    },
    fetchUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = initialUserDetail;
      state.error = action.payload;
    },
    fetchGuardianDetailStart: (state) => {
      state.guardianDetailloading = true;
    },
    fetchGuardianDetailSuccess: (state, action) => {
      state.guardianDetailloading = false;
      state.guardianDetail = action.payload ?? initialGuardianDetail;
    },
    fetchGuardianDetailFail: (state, action) => {
      state.guardianDetailloading = false;
      state.guardianDetail = initialGuardianDetail;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = action.payload?.list ?? [];
      state.WalletConnect.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = [];
      state.WalletConnect.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    updateUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    updateUserDetailSuccess: (state) => {
      state.userDetailLoading = false;
    },
    updateUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    }
  }
});

const {
  fetchUserDetailStart,
  fetchUserDetailSuccess,
  fetchUserDetailFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  fetchWalletTransactionsStart,
  fetchWalletTransactionsSuccess,
  fetchWalletTransactionsFail,
  updateUserDetailStart,
  updateUserDetailSuccess,
  updateUserDetailFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchGuardianDetailStart,
  fetchGuardianDetailSuccess,
  fetchGuardianDetailFail
} = userDetailSlice.actions;

export const fetchUserDetailAction = (data: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDetailStart());
    try {
      const response = await getUserDetail(data);

      dispatch(fetchUserDetailSuccess(response));
    } catch (error) {
      dispatch(fetchUserDetailFail(error));
    }
  };
};
export const fetchGuardianDetailAction = (data: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchGuardianDetailStart());
    try {
      const response = await getUserDetail(data);

      dispatch(fetchGuardianDetailSuccess(response));
    } catch (error) {
      dispatch(fetchGuardianDetailFail(error));
    }
  };
};

export const fetchWalletConnectAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await fetchPaymentRequest(data);

      dispatch(fetchTransListSuccess(response));
    } catch (error) {
      dispatch(fetchTransListFail(error));
    }
  };
};

export const fetchWalletTransactionAction = (data: walletTransRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletTransactionsStart());
    try {
      const response = await fetchWalletTransaction({
        ...data,
        showTotalWalletBalance: true,
        showTotalTransactions: true,
        showTotalCardBalance: true,
        showTotalTransactionsWithoutReceipts: true
      });

      dispatch(fetchWalletTransactionsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletTransactionsFail(error));
    }
  };
};

export const updateUserDetailAction = (id: number, data: userDetailRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateUserDetailStart());
    try {
      await updateUserDetail(id, data);

      dispatch(updateUserDetailSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "User info updated Sucessfully",
        showConfirmButton: false,
        timer: 2500
      });
    } catch (error) {
      dispatch(updateUserDetailFail(error));
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export default userDetailSlice.reducer;
