import { useState, useEffect } from "react";
import styles from "./depositCard.module.scss";
import Table from "rc-table";
import columns from "./column";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchDepositListAction } from "src/page/walletDetail/walletDetailSlice";
import { useParams } from "react-router-dom";

interface depositCardProp {
  userId: number;
}

export default function DepositCard({ userId }: depositCardProp) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useAppDispatch();
  const { despositList } = useAppSelector((state) => state.walletDetail);
  const { walletId } = useParams();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setPage(0);
  };

  useEffect(() => {
    if (userId) {
      const data = {
        page,
        limit: rowsPerPage,
        walletId: Number(walletId),
        paymentTypes: ["DEPOSIT"]
      };

      dispatch(fetchDepositListAction(data));
    }
  }, [dispatch, page, rowsPerPage, userId, walletId]);

  return (
    <>
      {/* {selectedTranId && (
        <Modalui open={open} handleClose={handleClose}>
          <TransactionEditModal transaction={selectedTranId} handleClose={handleClose} />
        </Modalui>
      )} */}
      <div className={styles.recentTrans}>
        <div className={styles.recentTransIn}>
          <div className={styles.recentTransHead}>
            <div className={styles.recentTransHeadIn}>
              <div className={styles.recentTransHeadD}>
                <h3>Deposits</h3>
                <p>Check your Deposits here</p>
              </div>
            </div>
          </div>
          <div className={styles.recentTranTable}>
            <Table columns={columns} data={despositList.list} />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={despositList.total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
