export const SUPPORTER = "SUPPORTER";
export const MANAGER = "MANAGER";
export const PARTICIPANT = "PARTICIPANT";
export const GUARDIAN = "GUARDIAN";
export const ADMIN = "ADMIN";
export const SUPER_ADMIN = "SUPER ADMIN";
export const OPERATIONS_MANAGER = "OPERATIONS MANAGER";
export const FINANCE_MANAGER = "FINANCE MANAGER";
export const SHARED = "SHARED";
export const PRIVATE = "PRIVATE";
export const CAN_ADD_USER = "CAN_ADD_USER";
export const CAN_EDIT_USER_INFORMATION = "CAN_EDIT_USER_INFORMATION";
export const CAN_EDIT_TRANSACTION = "CAN_EDIT_TRANSACTION";
export const CAN_UPDATE_WALLET_SETTINGS = "CAN_UPDATE_WALLET_SETTINGS";
export const CAN_ADD_ORGANSIATION = "CAN_ADD_ORGANSIATION";
export const CAN_UPDATE_BUDGET = "CAN_UPDATE_BUDGET";
export const CAN_UPDATE_REPEAT_PAY = "CAN_UPDATE_REPEAT_PAY";
export const CAN_CONNECT_USER_TO_WALLET = "CAN_CONNECT_USER_TO_WALLET";
export const CAN_CONNECT_CARD_TO_WALLET = "CAN_CONNECT_CARD_TO_WALLET";
export const CAN_CONNECT_CARD = "CAN_CONNECT_CARD";
export const CAN_CONNECT_CARD_TO_ORGANISATION = "CAN_CONNECT_CARD_TO_ORGANISATION";
export const CAN_CONNECT_WALLET_TO_ORGANISATION = "CAN_CONNECT_WALLET_TO_ORGANISATION";
export const CAN_CONNECT_USER_TO_ORGANISATION = "CAN_CONNECT_USER_TO_ORGANISATION";
export const CAN_UPDATE_CARD = "CAN_UPDATE_CARD";
export const CAN_CHANGE_PIN = "CAN_CHANGE_PIN";

export const roleMapping: Record<string, string> = {
  ADMIN: "Admin",
  ORGANISATION_SUPER_ADMIN: "Super Admin",
  ORGANISATION_OPERATIONS_MANAGER: "Operation Manager",
  ORGANISATION_FINANCE_MANAGER: "Finance Manager",
  GUARDIAN: "Guardian",
  PARTICIPANT: "Participant",
  SUPPORTER: "Supporter"
};

export const reverseRoleMapping: Record<string, string> = {
  ["Admin"]: "ADMIN",
  ["Super Admin"]: "ORGANISATION_SUPER_ADMIN",
  ["Operation Manager"]: "ORGANISATION_OPERATIONS_MANAGER",
  ["Finance Manager"]: "ORGANISATION_FINANCE_MANAGER",
  ["Guardian"]: "GUARDIAN",
  ["Participant"]: "PARTICIPANT",
  ["Supporter"]: "SUPPORTER"
};
