import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  fetchWallets,
  getWalletDetail,
  fetchWalletTransaction,
  walletTransRequest,
  addWallet,
  addWalletRequest,
  updateWallet,
  getBudgetSupporter,
  fetchDepositList,
  depositListRequest
} from "src/network/services/walletService";
import {
  fetchPaymentRequest,
  TransListRequest,
  fetchRepeatPayRequest,
  repeatPayRequest
} from "src/network/services/transactionServices";
import {
  deleteWalletSupporter,
  addWalletSupporter,
  supporterRequest,
  updateBudgetSupporter,
  budgetSupporterRequest
} from "src/network/services/supporterServices";
import { getMasterList, deleteBudget, updateBudget, updateBudgetRequest } from "src/network/services/budgetServices";
import { UserListRequest, fetchUserList } from "src/network/services/UsersServices";
import { GetAllCardsRequest, getAllCards, connectCard, connectCardRequest } from "src/network/services/cardService";
import { CardAccessType, User } from "src/types/commonTypes";
import { immediateToast } from "izitoast-react";

type Wallet = {
  id: number;
  participant: { fullName: string };
  description: string;
  balance: number;
};
const initialWallets: Wallet[] = [];

type Transaction = {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
};

const initialTransaction: Transaction[] = [];

type walletDetail = {
  wallet: {
    id: number;
    participant: {
      id: number;
      fullName: string;
      firstName: string;
      lastname: string;
      phoneNumber: string;
      role: string;
      address: string;
      city: string;
      state: string;
      zipcode: string;
    };
    status: boolean;
    description: string;
    defaultWallet: boolean;
    organisationId: number;
    repeatPay: boolean;
    repeatPayBalance: number;
    useWholeBalance: boolean;
  };
  supporter: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
  }[];
  budgets: {
    id: number;
    itemCategory: { id: number; categoryName: string; isMasterItem: boolean };
    budgetAvailable: number;
  }[];
  pendingTransactions: {
    id: number;
    requestedAmount: number;
    isRepeatPay: boolean;
    useWholeBalance: boolean;
  }[];
  cards: {
    id: number;
    cardNumber: string;
    cardStatus: boolean;
    accessType: string;
    accountNumber: string;
    user: {
      id: number;
      fullName: string;
      email: string;
      role: string;
      firstName: string;
      lastName: string;
    };
  }[];
};

const initialWalletDetail: walletDetail = {
  wallet: {
    id: 0,
    participant: {
      id: 0,
      fullName: "",
      firstName: "",
      lastname: "",
      phoneNumber: "",
      role: "",
      address: "",
      city: "",
      state: "",
      zipcode: ""
    },
    status: false,
    description: "",
    defaultWallet: false,
    organisationId: 0,
    repeatPay: false,
    repeatPayBalance: 0,
    useWholeBalance: false
  },
  supporter: [],
  budgets: [],
  pendingTransactions: [],
  cards: []
};

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];

type Users = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const initialUsers: Users[] = [];

type budgetSupporter = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
};

const initialBudgetSupporter: budgetSupporter[] = [];

type masterList = {
  id: number;
  categoryName: string;
};

const initialMasterList: masterList[] = [];

type depositList = {
  amounAdded: number;
  createdAt: number;
};

const initialDepositList: depositList[] = [];

export type Card = {
  id: number;
  cardNumber: string;
  cardStatus: boolean;
  accessType: CardAccessType;
  user: User;
  bbs: string;
};

const initialCards: Card[] = [];

const walletDetailSlice = createSlice({
  name: "walletDetail",
  initialState: {
    walletLoading: false,
    transLoading: false,
    walletDetailLoading: false,
    walletTransLoading: false,
    userLoading: false,
    budgetSupporterLoading: false,
    masterListLoading: false,
    deletebudgetLoading: false,
    updateBudgetLoading: false,
    repeatPayLoading: false,
    depositListLoading: false,
    cardListLoading: false,
    cardConnectLoading: false,
    error: null,
    WalletConnect: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletDetail: initialWalletDetail,
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    budgetSupporters: initialBudgetSupporter,
    mastersList: {
      total: 0,
      list: initialMasterList
    },
    despositList: {
      total: 0,
      list: initialDepositList
    },
    cards: {
      list: initialCards,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = action.payload?.list ?? [];
      state.WalletConnect.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.WalletConnect.wallets = [];
      state.WalletConnect.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletDetailStart: (state) => {
      state.walletDetailLoading = true;
    },
    fetchWalletDetailSuccess: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail.wallet = action.payload.wallet ?? initialWalletDetail.wallet;
      state.walletDetail.supporter = action.payload.supporters ?? initialWalletDetail.supporter;
      state.walletDetail.budgets = action.payload.budgets ?? initialWalletDetail.budgets;
      state.walletDetail.pendingTransactions =
        action.payload.pendingTransactions ?? initialWalletDetail.pendingTransactions;
      state.walletDetail.cards = action.payload.cards ?? initialWalletDetail.cards;
    },
    fetchWalletDetailFail: (state, action) => {
      state.walletDetailLoading = false;
      state.walletDetail = initialWalletDetail;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    deleteSupporterStart: (state) => {
      state.walletLoading = true;
    },
    deleteSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    deleteSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    addSupporterStart: (state) => {
      state.walletLoading = true;
    },
    addSupporterSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addSupporterFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    addWalletStart: (state) => {
      state.walletDetailLoading = true;
    },
    addWalletSuccess: (state) => {
      state.walletDetailLoading = false;
      state.error = null;
    },
    addWalletFail: (state, action) => {
      state.walletDetailLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    updateWalletStatusStart: (state) => {
      state.walletDetailLoading = true;
    },
    updateWalletStatusSuccess: (state) => {
      state.walletDetailLoading = false;
    },
    updateWalletStatusFail: (state, action) => {
      state.walletDetailLoading = false;
      state.error = action.payload;
    },
    getBudgetSupporterStart: (state) => {
      state.budgetSupporterLoading = true;
    },
    getBudgetSupporterSuccess: (state, action) => {
      state.budgetSupporterLoading = false;
      state.budgetSupporters = action?.payload ?? [];
    },
    getBudgetSupporterFail: (state, action) => {
      state.budgetSupporterLoading = false;
      state.budgetSupporters = [];
      state.error = action?.payload;
    },
    updateBudgetSupporterStart: (state) => {
      state.budgetSupporterLoading = true;
    },
    updateBudgetSupporterSuccess: (state) => {
      state.budgetSupporterLoading = false;
    },
    updateBudgetSupporterFail: (state, action) => {
      state.budgetSupporterLoading = false;
      state.error = action?.payload;
    },
    getMasterListStart: (state) => {
      state.masterListLoading = true;
    },
    getMasterListSuccess: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = action?.payload.total ?? 0;
      state.mastersList.list = action?.payload.list ?? [];
    },
    getMasterListFail: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = 0;
      state.mastersList.list = [];
      state.error = action?.payload;
    },
    deleteBudgetStart: (state) => {
      state.deletebudgetLoading = true;
    },
    deleteBudgetSuccess: (state) => {
      state.deletebudgetLoading = false;
      state.error = null;
    },
    deleteBudgetFail: (state, action) => {
      state.deletebudgetLoading = false;
      state.error = action?.payload;
    },
    updateBudgetStart: (state) => {
      state.updateBudgetLoading = true;
    },
    updateBudgetSuccess: (state) => {
      state.updateBudgetLoading = false;
      state.error = null;
    },
    updateBudgetFail: (state, action) => {
      state.updateBudgetLoading = false;
      state.error = action?.payload;
    },
    fetchRepeatPayStart: (state) => {
      state.repeatPayLoading = true;
    },
    fetchRepeatPaySuccess: (state) => {
      state.repeatPayLoading = false;
    },
    fetchRepeatPayFail: (state, action) => {
      state.repeatPayLoading = false;
      state.error = action?.payload;
    },
    fetchDepositListStart: (state) => {
      state.depositListLoading = true;
    },
    fetchDepositListSuccess: (state, action) => {
      state.depositListLoading = false;
      state.despositList.list = action.payload?.list ?? [];
      state.despositList.total = action.payload?.total ?? 0;
    },
    fetchDepositListFail: (state, action) => {
      state.depositListLoading = false;
      state.despositList.list = [];
      state.despositList.total = 0;
      state.error = action.payload;
    },
    getAllCardsStart: (state) => {
      state.cardListLoading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.cardListLoading = false;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.cardListLoading = false;
      state.error = null;
      state.cards = action.payload;
    },
    addCardStart: (state) => {
      state.cardConnectLoading = true;
    },
    addCardSuccess: (state) => {
      state.cardConnectLoading = false;
      state.error = null;
    },
    addCardFail: (state, action) => {
      state.cardConnectLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  fetchWalletDetailStart,
  fetchWalletDetailSuccess,
  fetchWalletDetailFail,
  fetchWalletTransactionsStart,
  fetchWalletTransactionsSuccess,
  fetchWalletTransactionsFail,
  deleteSupporterStart,
  deleteSupporterSuccess,
  deleteSupporterFail,
  addSupporterStart,
  addSupporterSuccess,
  addSupporterFail,
  addWalletStart,
  addWalletSuccess,
  addWalletFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  updateWalletStatusStart,
  updateWalletStatusSuccess,
  updateWalletStatusFail,
  getBudgetSupporterStart,
  getBudgetSupporterSuccess,
  getBudgetSupporterFail,
  updateBudgetSupporterStart,
  updateBudgetSupporterSuccess,
  updateBudgetSupporterFail,
  getMasterListStart,
  getMasterListSuccess,
  getMasterListFail,
  deleteBudgetStart,
  deleteBudgetSuccess,
  deleteBudgetFail,
  updateBudgetStart,
  updateBudgetSuccess,
  updateBudgetFail,
  fetchRepeatPayStart,
  fetchRepeatPaySuccess,
  fetchRepeatPayFail,
  fetchDepositListStart,
  fetchDepositListSuccess,
  fetchDepositListFail,
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  addCardStart,
  addCardSuccess,
  addCardFail
} = walletDetailSlice.actions;

export const fetchWalletConnectAction = (status: boolean, walletId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets({
        status: status,
        ids: [walletId],
        page: 0
        // limit: 0
      });

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await fetchPaymentRequest(data);

      dispatch(fetchTransListSuccess(response));
    } catch (error) {
      dispatch(fetchTransListFail(error));
    }
  };
};

export const fetchWalletDetailAction = (data: number, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletDetailStart());
    try {
      const response = await getWalletDetail(data);

      dispatch(fetchWalletDetailSuccess(response));
      if (cb) cb();
    } catch (error) {
      dispatch(fetchWalletDetailFail(error));
    }
  };
};

export const fetchWalletTransactionAction = (data: walletTransRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletTransactionsStart());
    try {
      const response = await fetchWalletTransaction({
        showTotalWalletBalance: true,
        showTotalTransactions: true,
        showTotalCardBalance: true,
        showTotalTransactionsWithoutReceipts: true,
        ...data
      });

      dispatch(fetchWalletTransactionsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletTransactionsFail(error));
    }
  };
};

export const deleteWalletSupporterAction = (
  data: supporterRequest,
  walletId: number,
  supporterId: number,
  applyToAllWallets: boolean
) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteSupporterStart());
    try {
      await deleteWalletSupporter(data, walletId, supporterId, applyToAllWallets);

      dispatch(deleteSupporterSuccess());
      immediateToast("success", {
        message: "Supporter Deleted Successfully",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(deleteSupporterFail(error));
    }
  };
};

export const addWalletSupporterAction = (data: supporterRequest, walletId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addSupporterStart());
    try {
      await addWalletSupporter(data, walletId);

      dispatch(addSupporterSuccess());
      immediateToast("success", {
        message: "Supporter Added Successfully.",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(addSupporterFail(error));
    }
  };
};

export const addWalletAction = (data: addWalletRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addWalletStart());
    try {
      const response = await addWallet(data);

      dispatch(addWalletSuccess());
      if (cb) cb();
      return response;
    } catch (error) {
      dispatch(addWalletFail(error));
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const updateWalletStatusAction = (walletId: number, status: boolean) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateWalletStatusStart());
    try {
      await updateWallet(walletId, status);

      dispatch(updateWalletStatusSuccess());
    } catch (error) {
      dispatch(updateWalletStatusFail(error));
    }
  };
};

export const getBudgetSupporterAction = (budgetId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getBudgetSupporterStart());

    try {
      const response = await getBudgetSupporter(budgetId);

      dispatch(getBudgetSupporterSuccess(response));
    } catch (error) {
      dispatch(getBudgetSupporterFail(error));
    }
  };
};

export const updateBudgetSupporterAction = (data: budgetSupporterRequest, budgetId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateBudgetSupporterStart());

    try {
      await updateBudgetSupporter(data, budgetId);

      dispatch(updateBudgetSupporterSuccess());
    } catch (error) {
      dispatch(updateBudgetSupporterFail(error));
    }
  };
};

export const getMasterListAction = () => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getMasterListStart());

    try {
      const response = await getMasterList();

      dispatch(getMasterListSuccess(response));
    } catch (error) {
      dispatch(getMasterListFail(error));
    }
  };
};

export const deleteBudgetAction = (budgetId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(deleteBudgetStart());

    try {
      await deleteBudget(budgetId);

      dispatch(deleteBudgetSuccess());
    } catch (error) {
      dispatch(deleteBudgetFail(error));
    }
  };
};
export const updateBudgetAction = (data: updateBudgetRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateBudgetStart());

    try {
      await updateBudget(data);

      dispatch(updateBudgetSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateBudgetFail(error));
    }
  };
};

export const fetchRepeatPayAction = (data: repeatPayRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchRepeatPayStart());

    try {
      await fetchRepeatPayRequest(data);

      dispatch(fetchWalletTransactionAction({ walletIds: [Number(data.walletId)] }));
      dispatch(fetchRepeatPaySuccess());
    } catch (error) {
      dispatch(fetchRepeatPayFail(error));
    }
  };
};

export const fetchDepositListAction = (data: depositListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchDepositListStart());

    try {
      const response = await fetchDepositList(data);

      dispatch(fetchDepositListSuccess(response));
    } catch (error) {
      dispatch(fetchDepositListFail(error));
    }
  };
};

export const getAllCardsAction = (data: GetAllCardsRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await getAllCards(data);

      dispatch(getAllCardsSuccess(response));
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};

export const addCardAction = (data: connectCardRequest, walletId: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addCardStart());
    try {
      const response = await connectCard(data, walletId);

      dispatch(addCardSuccess());
      return response;
    } catch (error) {
      dispatch(addCardFail(error));
      throw error;
    }
  };
};

export default walletDetailSlice.reducer;
