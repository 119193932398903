import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllCardsRequest,
  UpdateCardRequest,
  getAllCards,
  orderCard,
  orderCardRequest,
  updateCard,
  cardPinRequest,
  changeCardPin
} from "src/network/services/cardService";
import { CardAccessType, User } from "src/types/commonTypes";
import { StoreDispatch } from "../store";
import { UserListRequest, fetchUserList, getUserData } from "src/network/services/UsersServices";
import { fetchOrgList, orgListRequest } from "src/network/services/organisationService";
import { fetchWallets } from "src/network/services/walletService";
import { immediateToast } from "izitoast-react";

export type Card = {
  id: number;
  cardNumber: string;
  cardStatus: boolean;
  cardLimit: number;
  accessType: CardAccessType;
  user: User;
  bsb: string;
  organisationId?: number;
};

const initialCards: Card[] = [];

type UserList = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const initialUsers: UserList[] = [];

type UserData = {
  id: number;
  active: boolean;
  email: string;
  firstName: string;
  lastName: string;
  dob: string;
  phoneNumber: string;
  role: string;
  state: string;
  city: string;
  address: string;
  zipcode: string;
  country: string;
  accessCode: string;
  fullName: string;
};

const initialUserData: UserData = {
  id: 0,
  active: false,
  email: "",
  firstName: "",
  lastName: "",
  dob: "",
  phoneNumber: "",
  role: "",
  state: "",
  city: "",
  address: "",
  zipcode: "",
  country: "",
  accessCode: "",
  fullName: ""
};

type orgList = {
  id: number;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
};

type Wallet = {
  id: number;
  description: string;
  participant: { fullName: string };
};

const initialOrgList: orgList[] = [];
const initialWalletOptions: Wallet[] = [];

const cardSlice = createSlice({
  initialState: {
    loading: false,
    userLoading: false,
    userDataLoading: false,
    orgListLoading: false,
    orderCardLoading: false,
    cardPinLoading: false,
    error: null,
    cards: {
      list: initialCards,
      total: 0
    },
    users: {
      list: initialUsers,
      total: 0
    },
    userData: initialUserData,
    orgLists: {
      list: initialOrgList,
      total: 0
    },
    walletOptions: {
      list: initialWalletOptions
    }
  },
  name: "cards",
  reducers: {
    getAllCardsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cards = action.payload;
    },
    updateCardStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    updateCardSuccess: (state) => {
      state.loading = false;
    },
    updateCardFail: (state) => {
      state.loading = false;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    setUsersAction: (state, action) => {
      state.users.list = action.payload;
    },
    fetchUserDataStart: (state) => {
      state.userDataLoading = true;
    },
    fetchUserDataSuccess: (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload ?? initialUserData;
    },
    fetchUserDataFail: (state, action) => {
      state.userDataLoading = false;
      state.userData = initialUserData;
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    setOrganisationAction: (state, action) => {
      state.orgLists.list = action.payload;
    },
    setWalletOptionsAction: (state, action) => {
      state.walletOptions.list = action.payload;
    },
    orderCardStart: (state) => {
      state.orderCardLoading = true;
    },
    orderCardSuccess: (state) => {
      state.orderCardLoading = false;
    },
    orderCardFail: (state, action) => {
      state.orderCardLoading = false;
      state.error = action.payload;
    },
    changeCardPinStart: (state) => {
      state.cardPinLoading = true;
    },
    changeCardPinSuccess: (state) => {
      state.cardPinLoading = false;
    },
    changeCardPinFail: (state, action) => {
      state.cardPinLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  updateCardStart,
  updateCardSuccess,
  updateCardFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchUserDataStart,
  fetchUserDataSuccess,
  fetchUserDataFail,
  fetchOrgListStart,
  fetchOrgListSuccess,
  fetchOrgListFail,
  orderCardStart,
  orderCardSuccess,
  orderCardFail,
  changeCardPinStart,
  changeCardPinSuccess,
  changeCardPinFail
} = cardSlice.actions;

export const { setUsersAction, setOrganisationAction, setWalletOptionsAction } = cardSlice.actions;

export const getAllCardsAction = (data: GetAllCardsRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await getAllCards(data);

      dispatch(getAllCardsSuccess(response));
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};
export const updateCardAction = (id: number, data: UpdateCardRequest, cb: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateCardStart());
    try {
      await updateCard(id, data);

      dispatch(updateCardSuccess());
      if (cb) cb();
    } catch (error) {
      updateCardFail();
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchUserDataAction = (data: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDataStart());
    try {
      const response = await getUserData(data);

      dispatch(fetchUserDataSuccess(response));
    } catch (error) {
      dispatch(fetchUserDataFail(error));
    }
  };
};

export const fetchOrgListAction = (data: orgListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchOrgListStart());
    try {
      const response = await fetchOrgList(data);

      dispatch(fetchOrgListSuccess(response));
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};
export const fetchWalletListAction = (data: orgListRequest) => {
  return async (dispatch: StoreDispatch) => {
    try {
      const response = (await fetchWallets(data)) as any;

      dispatch(setWalletOptionsAction(response?.list));
    } catch (error) {
      dispatch(fetchOrgListFail(error));
    }
  };
};

export const orderCardAction = (data: orderCardRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(orderCardStart());
    try {
      await orderCard(data);

      dispatch(orderCardSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(orderCardFail(error));
    }
  };
};

export const changeCardPinAction = (data: cardPinRequest, id: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changeCardPinStart());
    try {
      await changeCardPin(data, id);
      dispatch(changeCardPinSuccess());
      immediateToast("success", {
        message: "Card Pin Changed Successfully.",
        timeout: 3000,
        position: "topCenter"
      });
    } catch (error) {
      dispatch(changeCardPinFail(error));
    }
  };
};

export default cardSlice.reducer;
