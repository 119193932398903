import axios from "axios";
import config from "src/config/Config";
import { logout } from "src/page/auth/loginSlice";
import store from "src/page/store";
import { immediateToast } from "izitoast-react";

const api = axios.create({
  baseURL: config.SIMS_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use((configuration) => {
  try {
    const {
      auth: { token, config: authConfig }
    } = store.getState();

    let defaultOrg = null;

    if (authConfig != null && authConfig.organisations !== undefined && authConfig.organisations !== null)
      defaultOrg = authConfig?.organisations[0];

    if (authConfig?.defaultOrganisation) defaultOrg = authConfig?.defaultOrganisation;

    configuration.headers["Authorization"] = `Bearer ${token}`;
    configuration.headers["organisation_id"] = defaultOrg?.id;
  } catch (error) {
    console.error(error);
  }
  return configuration;
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 403) {
      store.dispatch(logout());
      immediateToast("error", {
        message: "Opps, Your session is expired! Please login again",
        timeout: 2000,
        position: "topCenter"
      });
    } else if (error.response?.status === 400 && !error.response.data?.description) {
      immediateToast("error", {
        message: "Opps, Your request is invalid! Please try again",
        timeout: 2000,
        position: "topCenter"
      });
    }
    // const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (error.response?.data?.description) {
      immediateToast("error", { message: error.response?.data?.description, timeout: 2000, position: "topCenter" });
    } else if (error.response?.data?.message) {
      immediateToast("error", { message: error.response?.data?.message, timeout: 2000, position: "topCenter" });
    }
    return Promise.reject(error?.response?.data);
  }
);

export default api;
