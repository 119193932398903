export const DashLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="22" viewBox="0 0 14 15" fill="none">
    <path
      d="M2.09961 3.60015C2.09961 3.21355 2.41301 2.90015 2.79961 2.90015H11.1996C11.5862 2.90015 11.8996 3.21355 11.8996 3.60015V5.00015C11.8996 5.38674 11.5862 5.70015 11.1996 5.70015H2.79961C2.41301 5.70015 2.09961 5.38674 2.09961 5.00015V3.60015Z"
      fill="white"
    />
    <path
      d="M2.09961 7.8001C2.09961 7.41349 2.41301 7.1001 2.79961 7.1001H6.99961C7.38622 7.1001 7.69961 7.41349 7.69961 7.8001V12.0001C7.69961 12.3867 7.38622 12.7001 6.99961 12.7001H2.79961C2.41301 12.7001 2.09961 12.3867 2.09961 12.0001V7.8001Z"
      fill="#6C757D"
    />
    <path
      d="M9.79961 7.1001C9.413 7.1001 9.09961 7.41349 9.09961 7.8001V12.0001C9.09961 12.3867 9.413 12.7001 9.79961 12.7001H11.1996C11.5862 12.7001 11.8996 12.3867 11.8996 12.0001V7.8001C11.8996 7.41349 11.5862 7.1001 11.1996 7.1001H9.79961Z"
      fill="#6C757D"
    />
  </svg>
);
export const UserLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="22" viewBox="0 0 14 15" fill="none">
    <path
      d="M3.49961 2.50024C2.72641 2.50024 2.09961 3.12705 2.09961 3.90024V5.30024C2.09961 6.07344 2.72641 6.70024 3.49961 6.70024H4.89961C5.67281 6.70024 6.29961 6.07344 6.29961 5.30024V3.90024C6.29961 3.12705 5.67281 2.50024 4.89961 2.50024H3.49961Z"
      fill="white"
    />
    <path
      d="M3.49961 8.1001C2.72641 8.1001 2.09961 8.72688 2.09961 9.5001V10.9001C2.09961 11.6733 2.72641 12.3001 3.49961 12.3001H4.89961C5.67281 12.3001 6.29961 11.6733 6.29961 10.9001V9.5001C6.29961 8.72688 5.67281 8.1001 4.89961 8.1001H3.49961Z"
      fill="white"
    />
    <path
      d="M7.7002 3.90024C7.7002 3.12705 8.32698 2.50024 9.1002 2.50024H10.5002C11.2734 2.50024 11.9002 3.12705 11.9002 3.90024V5.30024C11.9002 6.07344 11.2734 6.70024 10.5002 6.70024H9.1002C8.32698 6.70024 7.7002 6.07344 7.7002 5.30024V3.90024Z"
      fill="white"
    />
    <path
      d="M7.7002 9.5001C7.7002 8.72688 8.32698 8.1001 9.1002 8.1001H10.5002C11.2734 8.1001 11.9002 8.72688 11.9002 9.5001V10.9001C11.9002 11.6733 11.2734 12.3001 10.5002 12.3001H9.1002C8.32698 12.3001 7.7002 11.6733 7.7002 10.9001V9.5001Z"
      fill="white"
    />
  </svg>
);
export const WalletLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="22" viewBox="0 0 14 14" fill="none">
    <path
      d="M2.80039 2.80005C2.02719 2.80005 1.40039 3.42685 1.40039 4.20005V4.90005H12.6004V4.20005C12.6004 3.42685 11.9736 2.80005 11.2004 2.80005H2.80039Z"
      fill="#64748B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6004 6.30005H1.40039V9.80005C1.40039 10.5733 2.02719 11.2 2.80039 11.2H11.2004C11.9736 11.2 12.6004 10.5733 12.6004 9.80005V6.30005ZM2.80039 9.10005C2.80039 8.71344 3.11379 8.40005 3.50039 8.40005H4.20039C4.58699 8.40005 4.90039 8.71344 4.90039 9.10005C4.90039 9.48666 4.58699 9.80005 4.20039 9.80005H3.50039C3.11379 9.80005 2.80039 9.48666 2.80039 9.10005ZM6.30039 8.40005C5.91379 8.40005 5.60039 8.71344 5.60039 9.10005C5.60039 9.48666 5.91379 9.80005 6.30039 9.80005H7.00039C7.387 9.80005 7.70039 9.48666 7.70039 9.10005C7.70039 8.71344 7.387 8.40005 7.00039 8.40005H6.30039Z"
      fill="white"
    />
  </svg>
);
export const TransLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="22" viewBox="0 0 14 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09961 4.10015C2.09961 3.71355 2.41301 3.40015 2.79961 3.40015H11.1996C11.5862 3.40015 11.8996 3.71355 11.8996 4.10015C11.8996 4.48674 11.5862 4.80015 11.1996 4.80015H2.79961C2.41301 4.80015 2.09961 4.48674 2.09961 4.10015Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.09961 7.60015C2.09961 7.21355 2.41301 6.90015 2.79961 6.90015H11.1996C11.5862 6.90015 11.8996 7.21355 11.8996 7.60015C11.8996 7.98676 11.5862 8.30015 11.1996 8.30015H2.79961C2.41301 8.30015 2.09961 7.98676 2.09961 7.60015Z"
      fill="#64748B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.2998 11.1001C6.2998 10.7135 6.61321 10.4001 6.9998 10.4001H11.1998C11.5864 10.4001 11.8998 10.7135 11.8998 11.1001C11.8998 11.4868 11.5864 11.8001 11.1998 11.8001H6.9998C6.61321 11.8001 6.2998 11.4868 6.2998 11.1001Z"
      fill="#64748B"
    />
  </svg>
);
export const AccountLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="22" viewBox="0 0 14 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6004 7.19985C12.6004 10.2927 10.0932 12.7999 7.00039 12.7999C3.90759 12.7999 1.40039 10.2927 1.40039 7.19985C1.40039 4.10706 3.90759 1.59985 7.00039 1.59985C10.0932 1.59985 12.6004 4.10706 12.6004 7.19985ZM8.40039 5.09985C8.40039 5.87305 7.77361 6.49985 7.00039 6.49985C6.22719 6.49985 5.60039 5.87305 5.60039 5.09985C5.60039 4.32665 6.22719 3.69985 7.00039 3.69985C7.77361 3.69985 8.40039 4.32665 8.40039 5.09985ZM7.00034 7.89985C5.58806 7.89985 4.37115 8.73635 3.81801 9.94084C4.58821 10.8343 5.72825 11.3999 7.00038 11.3999C8.2725 11.3999 9.41252 10.8343 10.1827 9.94091C9.62959 8.73635 8.41264 7.89985 7.00034 7.89985Z"
      fill="white"
    />
  </svg>
);
export const ReferLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="28" viewBox="0 0 22 20" fill="none">
    <g filter="url(#filter0_i_286_1682)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09467 2.69477C3.42914 1.3603 5.42603 0.850098 8 0.850098H14C16.574 0.850098 18.5709 1.3603 19.9053 2.69477C21.2398 4.02924 21.75 6.02613 21.75 8.6001V14.6001C21.75 17.1741 21.2398 19.171 19.9053 20.5054C18.5709 21.8399 16.574 22.3501 14 22.3501H8C5.42603 22.3501 3.42914 21.8399 2.09467 20.5054C0.760201 19.171 0.25 17.1741 0.25 14.6001V8.6001C0.25 6.02613 0.760201 4.02924 2.09467 2.69477ZM3.15533 3.75543C2.2398 4.67096 1.75 6.17407 1.75 8.6001V14.6001C1.75 17.0261 2.2398 18.5292 3.15533 19.4448C4.07086 20.3603 5.57397 20.8501 8 20.8501H14C16.426 20.8501 17.9291 20.3603 18.8447 19.4448C19.7602 18.5292 20.25 17.0261 20.25 14.6001V8.6001C20.25 6.17407 19.7602 4.67096 18.8447 3.75543C17.9291 2.8399 16.426 2.3501 14 2.3501H8C5.57397 2.3501 4.07086 2.8399 3.15533 3.75543Z"
        fill="#78FBD7"
      />
    </g>
    <g opacity="0.4" filter="url(#filter1_d_286_1682)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 7.1001C14.0858 7.1001 13.75 7.43588 13.75 7.8501C13.75 8.26431 14.0858 8.6001 14.5 8.6001C14.9142 8.6001 15.25 8.26431 15.25 7.8501C15.25 7.43588 14.9142 7.1001 14.5 7.1001ZM12.25 7.8501C12.25 6.60746 13.2574 5.6001 14.5 5.6001C15.7426 5.6001 16.75 6.60746 16.75 7.8501C16.75 9.09274 15.7426 10.1001 14.5 10.1001C13.2574 10.1001 12.25 9.09274 12.25 7.8501Z"
        fill="#78FBD7"
      />
    </g>
    <g opacity="0.4" filter="url(#filter2_d_286_1682)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.1001C7.08579 7.1001 6.75 7.43588 6.75 7.8501C6.75 8.26431 7.08579 8.6001 7.5 8.6001C7.91421 8.6001 8.25 8.26431 8.25 7.8501C8.25 7.43588 7.91421 7.1001 7.5 7.1001ZM5.25 7.8501C5.25 6.60746 6.25736 5.6001 7.5 5.6001C8.74264 5.6001 9.75 6.60746 9.75 7.8501C9.75 9.09274 8.74264 10.1001 7.5 10.1001C6.25736 10.1001 5.25 9.09274 5.25 7.8501Z"
        fill="#78FBD7"
      />
    </g>
    <g opacity="0.4" filter="url(#filter3_d_286_1682)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4 13.6501C7.31421 13.6501 7.25 13.7144 7.25 13.8001C7.25 15.8759 8.92421 17.5501 11 17.5501C13.0758 17.5501 14.75 15.8759 14.75 13.8001C14.75 13.7144 14.6858 13.6501 14.6 13.6501H7.4ZM5.75 13.8001C5.75 12.8859 6.48579 12.1501 7.4 12.1501H14.6C15.5142 12.1501 16.25 12.8859 16.25 13.8001C16.25 16.7044 13.9042 19.0501 11 19.0501C8.09579 19.0501 5.75 16.7044 5.75 13.8001Z"
        fill="#78FBD7"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_286_1682"
        x="0.25"
        y="0.850098"
        width="21.5"
        height="25.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_286_1682" />
      </filter>
      <filter
        id="filter1_d_286_1682"
        x="8.25"
        y="5.6001"
        width="12.5"
        height="12.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_1682" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_1682" result="shape" />
      </filter>
      <filter
        id="filter2_d_286_1682"
        x="1.25"
        y="5.6001"
        width="12.5"
        height="12.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_1682" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_1682" result="shape" />
      </filter>
      <filter
        id="filter3_d_286_1682"
        x="1.75"
        y="12.1501"
        width="18.5"
        height="14.8999"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_1682" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_1682" result="shape" />
      </filter>
    </defs>
  </svg>
);
export const TiltedBellLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
    <g clipPath="url(#clip0_286_1673)">
      <g filter="url(#filter0_d_286_1673)">
        <path
          d="M9.90861 6.94939C6.90539 8.34981 5.60606 11.9197 7.0065 14.9229L8.52192 18.1728L8.1799 19.1125C8.04156 19.4925 8.14579 19.9185 8.44401 20.1918C8.74222 20.465 9.17564 20.5317 9.54221 20.3608L20.4179 15.2894C20.7845 15.1184 21.012 14.7436 20.9943 14.3394C20.9767 13.9353 20.7173 13.5817 20.3373 13.4434L19.3976 13.1014L17.8822 9.85152C16.4817 6.84828 12.9118 5.54896 9.90861 6.94939Z"
          fill="#EE370F"
        />
        <path
          d="M9.0626 18.1492L8.99177 18.3438L8.64974 19.2835M9.0626 18.1492L8.64974 19.2835M9.0626 18.1492L8.97508 17.9615L7.45965 14.7116C6.17592 11.9586 7.36697 8.68626 10.1199 7.40254C12.8729 6.11881 16.1453 7.30986 17.429 10.0628L18.9444 13.3127L19.032 13.5004L19.2266 13.5712L20.1663 13.9132C20.3563 13.9824 20.486 14.1592 20.4948 14.3612C20.5036 14.5633 20.3899 14.7507 20.2066 14.8362L9.3309 19.9076C9.14759 19.9931 8.93089 19.9597 8.78181 19.8231C8.63268 19.6865 8.58058 19.4735 8.64974 19.2835M9.0626 18.1492L8.1799 19.1125L8.64974 19.2835"
          stroke="#979797"
        />
      </g>
      <g filter="url(#filter1_d_286_1673)">
        <path
          d="M16.6704 21.4503C15.1688 22.1506 13.3838 21.5009 12.6836 19.9993L18.1214 17.4636C18.8217 18.9652 18.172 20.7501 16.6704 21.4503Z"
          fill="#EE370F"
        />
        <path
          d="M16.4591 20.9972C15.3629 21.5083 14.0856 21.1563 13.3934 20.22L17.8343 18.1492C18.1066 19.2813 17.5553 20.486 16.4591 20.9972Z"
          stroke="#979797"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_286_1673"
        x="2.44336"
        y="6.38574"
        width="22.5518"
        height="22.0688"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_1673" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_1673" result="shape" />
      </filter>
      <filter
        id="filter1_d_286_1673"
        x="8.68359"
        y="17.4636"
        width="13.7197"
        height="12.2686"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_286_1673" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_286_1673" result="shape" />
      </filter>
      <clipPath id="clip0_286_1673">
        <rect width="20" height="20" fill="white" transform="translate(0 9.36304) rotate(-25)" />
      </clipPath>
    </defs>
  </svg>
);

export const walletTransCardLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="6" fill="#1E293B" />
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 -2 13.8 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3001 4.8001V4.0001C5.3001 2.67462 6.37462 1.6001 7.7001 1.6001H9.3001C10.6256 1.6001 11.7001 2.67462 11.7001 4.0001V4.8001H13.3001C14.1838 4.8001 14.9001 5.51644 14.9001 6.4001V9.25674C12.9078 9.99586 10.752 10.4 8.5001 10.4C6.24821 10.4 4.09239 9.99586 2.1001 9.25666V6.4001C2.1001 5.51644 2.81644 4.8001 3.7001 4.8001H5.3001ZM6.9001 4.0001C6.9001 3.55827 7.25827 3.2001 7.7001 3.2001H9.3001C9.74194 3.2001 10.1001 3.55827 10.1001 4.0001V4.8001H6.9001V4.0001ZM7.7001 8.0001C7.7001 7.55827 8.05827 7.2001 8.5001 7.2001H8.5081C8.94994 7.2001 9.3081 7.55827 9.3081 8.0001C9.3081 8.44194 8.94994 8.8001 8.5081 8.8001H8.5001C8.05827 8.8001 7.7001 8.44194 7.7001 8.0001Z"
        fill="white"
      />
      <path
        d="M2.1001 10.9536V12.7998C2.1001 13.6835 2.81644 14.3998 3.7001 14.3998H13.3001C14.1838 14.3998 14.9001 13.6835 14.9001 12.7998V10.9536C12.8899 11.6321 10.7372 11.9997 8.5001 11.9997C6.263 11.9997 4.11033 11.6321 2.1001 10.9536Z"
        fill="white"
      />
    </svg>
  </svg>
);

export const fourPillarLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 48 48" fill="none">
    <rect width="48" height="48" rx="6" fill="white" />
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2 -1 24 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.596 2.55801C12.2271 2.3472 11.7741 2.3472 11.4052 2.55801L3.00523 7.35801C2.4298 7.68683 2.22989 8.41985 2.55871 8.99527C2.77996 9.38247 3.18425 9.59965 3.60039 9.60011V17.9999C2.93765 17.9999 2.40039 18.5371 2.40039 19.1999C2.40039 19.8627 2.93765 20.3999 3.60039 20.3999H20.4004C21.0632 20.3999 21.6004 19.8627 21.6004 19.1999C21.6004 18.5371 21.0632 17.9999 20.4004 17.9999V9.60011C20.8167 9.59979 21.2212 9.38261 21.4425 8.99527C21.7713 8.41985 21.5714 7.68683 20.9959 7.35801L12.596 2.55801ZM7.20039 10.7999C6.53765 10.7999 6.00039 11.3372 6.00039 11.9999V15.5999C6.00039 16.2627 6.53765 16.7999 7.20039 16.7999C7.86313 16.7999 8.40039 16.2627 8.40039 15.5999V11.9999C8.40039 11.3372 7.86313 10.7999 7.20039 10.7999ZM10.8004 11.9999C10.8004 11.3372 11.3377 10.7999 12.0004 10.7999C12.6631 10.7999 13.2004 11.3372 13.2004 11.9999V15.5999C13.2004 16.2627 12.6631 16.7999 12.0004 16.7999C11.3377 16.7999 10.8004 16.2627 10.8004 15.5999V11.9999ZM16.8004 10.7999C16.1376 10.7999 15.6004 11.3372 15.6004 11.9999V15.5999C15.6004 16.2627 16.1376 16.7999 16.8004 16.7999C17.4632 16.7999 18.0004 16.2627 18.0004 15.5999V11.9999C18.0004 11.3372 17.4632 10.7999 16.8004 10.7999Z"
        fill="#1E293B"
      />
    </svg>
  </svg>
);
export const whiteFourPillarLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="6" fill="#1E293B" />
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="-2.7 -1 24 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.596 2.55801C12.2271 2.3472 11.7741 2.3472 11.4052 2.55801L3.00523 7.35801C2.4298 7.68683 2.22989 8.41985 2.55871 8.99527C2.77996 9.38247 3.18425 9.59965 3.60039 9.60011V17.9999C2.93765 17.9999 2.40039 18.5371 2.40039 19.1999C2.40039 19.8627 2.93765 20.3999 3.60039 20.3999H20.4004C21.0632 20.3999 21.6004 19.8627 21.6004 19.1999C21.6004 18.5371 21.0632 17.9999 20.4004 17.9999V9.60011C20.8167 9.59979 21.2212 9.38261 21.4425 8.99527C21.7713 8.41985 21.5714 7.68683 20.9959 7.35801L12.596 2.55801ZM7.20039 10.7999C6.53765 10.7999 6.00039 11.3372 6.00039 11.9999V15.5999C6.00039 16.2627 6.53765 16.7999 7.20039 16.7999C7.86313 16.7999 8.40039 16.2627 8.40039 15.5999V11.9999C8.40039 11.3372 7.86313 10.7999 7.20039 10.7999ZM10.8004 11.9999C10.8004 11.3372 11.3377 10.7999 12.0004 10.7999C12.6631 10.7999 13.2004 11.3372 13.2004 11.9999V15.5999C13.2004 16.2627 12.6631 16.7999 12.0004 16.7999C11.3377 16.7999 10.8004 16.2627 10.8004 15.5999V11.9999ZM16.8004 10.7999C16.1376 10.7999 15.6004 11.3372 15.6004 11.9999V15.5999C15.6004 16.2627 16.1376 16.7999 16.8004 16.7999C17.4632 16.7999 18.0004 16.2627 18.0004 15.5999V11.9999C18.0004 11.3372 17.4632 10.7999 16.8004 10.7999Z"
        fill="white"
      />
    </svg>
  </svg>
);

export const envelopLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
    <rect x="0.25" width="48" height="48" rx="6" fill="white" />
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2 1 24 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77839 7.69488C3.07668 8.13346 2.65039 8.90259 2.65039 9.73008V19.1999C2.65039 20.5254 3.72491 21.5999 5.05039 21.5999H19.4504C20.7759 21.5999 21.8504 20.5254 21.8504 19.1999V9.73008C21.8504 8.90259 21.4242 8.13346 20.7224 7.69488L13.5224 3.19488C12.7442 2.70848 11.7566 2.70848 10.9784 3.19488L3.77839 7.69488ZM6.91603 10.6015C6.36459 10.2339 5.61955 10.3829 5.25193 10.9344C4.88431 11.4858 5.03331 12.2309 5.58475 12.5984L11.5848 16.5985C11.9878 16.8672 12.513 16.8672 12.916 16.5985L18.916 12.5984C19.4674 12.2309 19.6165 11.4858 19.2489 10.9344C18.8812 10.3829 18.1362 10.2339 17.5848 10.6015L12.2504 14.1577L6.91603 10.6015Z"
        fill="#1E293B"
      />
    </svg>
  </svg>
);

export const supporterLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
    <rect x="0.5" width="48" height="48" rx="6" fill="white" />
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2 -1 24 20" fill="none">
      <path
        d="M11.2996 7.2001C11.2996 9.18832 9.68783 10.8001 7.69961 10.8001C5.71139 10.8001 4.09961 9.18832 4.09961 7.2001C4.09961 5.21188 5.71139 3.6001 7.69961 3.6001C9.68783 3.6001 11.2996 5.21188 11.2996 7.2001Z"
        fill="#1E293B"
      />
      <path
        d="M20.9002 7.2001C20.9002 9.18832 19.2885 10.8001 17.3002 10.8001C15.3119 10.8001 13.7002 9.18832 13.7002 7.2001C13.7002 5.21188 15.3119 3.6001 17.3002 3.6001C19.2885 3.6001 20.9002 5.21188 20.9002 7.2001Z"
        fill="#1E293B"
      />
      <path
        d="M16.015 20.4002C16.071 20.0083 16.1 19.6076 16.1 19.2002C16.1 17.238 15.4272 15.4329 14.2998 14.0031C15.1823 13.4924 16.2071 13.2002 17.3 13.2002C20.6137 13.2002 23.3 15.8865 23.3 19.2002V20.4002H16.015Z"
        fill="#111827"
      />
      <path
        d="M7.7002 13.2002C11.0139 13.2002 13.7002 15.8865 13.7002 19.2002V20.4002H1.7002V19.2002C1.7002 15.8865 4.38649 13.2002 7.7002 13.2002Z"
        fill="#111827"
      />
    </svg>
  </svg>
);

export const cardLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
    <rect x="0.75" width="48" height="48" rx="6" fill="white" />
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-2 0 24 20" fill="none">
      <path
        d="M5.55039 4.7998C4.22491 4.7998 3.15039 5.87432 3.15039 7.1998V8.3998H22.3504V7.1998C22.3504 5.87432 21.2759 4.7998 19.9504 4.7998H5.55039Z"
        fill="#212529"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3504 10.7998H3.15039V16.7998C3.15039 18.1253 4.22491 19.1998 5.55039 19.1998H19.9504C21.2759 19.1998 22.3504 18.1253 22.3504 16.7998V10.7998ZM5.55039 15.5998C5.55039 14.937 6.08765 14.3998 6.75039 14.3998H7.95039C8.61313 14.3998 9.15039 14.937 9.15039 15.5998C9.15039 16.2626 8.61313 16.7998 7.95039 16.7998H6.75039C6.08765 16.7998 5.55039 16.2626 5.55039 15.5998ZM11.5504 14.3998C10.8877 14.3998 10.3504 14.937 10.3504 15.5998C10.3504 16.2626 10.8877 16.7998 11.5504 16.7998H12.7504C13.4132 16.7998 13.9504 16.2626 13.9504 15.5998C13.9504 14.937 13.4132 14.3998 12.7504 14.3998H11.5504Z"
        fill="#212529"
      />
    </svg>
  </svg>
);

export const whiteCardLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="6" fill="#1E293B" />
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="-2 -2 16 16" fill="none">
      <path
        d="M3.2001 3.2002C2.31644 3.2002 1.6001 3.91654 1.6001 4.8002V5.6002H14.4001V4.8002C14.4001 3.91654 13.6838 3.2002 12.8001 3.2002H3.2001Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4001 7.2002H1.6001V11.2002C1.6001 12.0839 2.31644 12.8002 3.2001 12.8002H12.8001C13.6838 12.8002 14.4001 12.0839 14.4001 11.2002V7.2002ZM3.2001 10.4002C3.2001 9.95836 3.55827 9.6002 4.0001 9.6002H4.8001C5.24192 9.6002 5.6001 9.95836 5.6001 10.4002C5.6001 10.842 5.24192 11.2002 4.8001 11.2002H4.0001C3.55827 11.2002 3.2001 10.842 3.2001 10.4002ZM7.2001 9.6002C6.75827 9.6002 6.4001 9.95836 6.4001 10.4002C6.4001 10.842 6.75827 11.2002 7.2001 11.2002H8.0001C8.44194 11.2002 8.8001 10.842 8.8001 10.4002C8.8001 9.95836 8.44194 9.6002 8.0001 9.6002H7.2001Z"
        fill="white"
      />
    </svg>
  </svg>
);

export const spendAbleLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="300" height="60" viewBox="0 0 227.464 48">
    <g id="logo-budget-hub" transform="translate(-19.998 -8)">
      <g id="Group_1" data-name="Group 1" transform="translate(20 8)">
        <path
          id="Path_1317"
          data-name="Path 1317"
          d="M493.069-577.992a3.509,3.509,0,0,1-1.349.055c-.67-.2-1.176-.954-1.59-1.484-.855-1.142-1.621-2.333-2.462-3.49a46.348,46.348,0,0,1-3.876-7.308c-.342-.705-1.246-2.057-.848-2.837.783-1.529,2.857-1.37,4.323-1.529a56.053,56.053,0,0,1,9.65,0c1.836.076,3.771.156,4.454,2.121h.106c1.836-2.969,1.514-7.067.8-10.286a11.008,11.008,0,0,0-1.393-3.51,10.46,10.46,0,0,0-2.123-2.776c-2.015-2.028-4.3-3.5-7.255-3.47-1.95.023-3.9,1.439-5.514,2.4-2.829,1.677-5.647,3.436-8.5,5.033-2.348,1.324-5.469,2.414-6.626,5.083-.8,1.836-.46,4.2-.46,6.15v11.452c0,2.56-.177,4.934,1.621,7a9.116,9.116,0,0,0,3.973,2.4,15.4,15.4,0,0,0,13.178-1.445,14.881,14.881,0,0,0,3.891-3.563Z"
          transform="translate(-470.336 612.506)"
          fill="#0053a3"
        />
        <path
          id="Path_1333"
          data-name="Path 1333"
          d="M729.437-588.363c.443,1.06,1.712,1.524,2.316,2.461,1.985,3.093,2.306,4.05,2.671,9.508.32,3.266-1.428,5.331-2.384,7.634-.377.9-1.185,2.058-.868,3.075.339,1.113,1.824,1.738,2.778,2.251,2.473,1.354,4.878,2.793,7.316,4.218,1.1.646,2.386,1.209,3.481.183a3.4,3.4,0,0,0,.654-2.623v-12.725a9.693,9.693,0,0,0-.431-4.029c-.872-1.988-2.669-2.9-4.447-3.91-2.72-1.552-5.375-3.269-8.165-4.679A17.227,17.227,0,0,0,729.437-588.363Z"
          transform="translate(-701.962 590.923)"
          fill="#fb8278"
        />
        <path
          id="Path_1414"
          data-name="Path 1414"
          d="M529.3-320.69a4.947,4.947,0,0,0,1.771,1.481L535-316.938c3.486,1.988,7.465,5.474,11.77,4.281,2.651-.742,5.109-2.88,7.529-4.165,2.07-1.1,4.163-2.274,6.15-3.509,1.015-.636,1.8-1.147,1.8-2.439a2.3,2.3,0,0,0-1.379-1.9c-1.568-.962-3.226-1.916-4.878-2.764-1.3-.663-2.545-1.52-3.817-2.227a4.906,4.906,0,0,0-1.8-.842c-1.316-.1-1.844.736-2.559,1.69-.69.921-1.317,1.9-2,2.877a15.661,15.661,0,0,1-7.423,5.217C534.022-319.327,529.852-320.686,529.3-320.69Z"
          transform="translate(-523.052 360.414)"
          fill="#fb8278"
        />
      </g>
    </g>
  </svg>
);

export const receiptLogo = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="6" fill="#1E293B" />
    <svg width="25" height="30" viewBox="-4.2 -2 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_285_1527)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 13.3571V19.25C3.26742 19.1526 5.25781 19.184 7.31322 19.2165C8.36569 19.2331 9.4352 19.25 10.5006 19.25C11.566 19.25 12.6355 19.2331 13.688 19.2165C15.7433 19.184 19.2333 19.1526 21 19.25V12.375C21 8.25 21 8.25 17.25 8.25H3.75C1.5 8.25 1.5 8.25 1.5 13.3571Z"
          fill="white"
        />
        <path
          d="M3 14.15V17.475C3 17.6959 3.17909 17.875 3.4 17.875H19.1C19.3209 17.875 19.5 17.6959 19.5 17.475V14.15C19.5 13.9291 19.3209 13.75 19.1 13.75H3.4C3.17909 13.75 3 13.9291 3 14.15Z"
          fill="#1E293B"
        />
        <path
          d="M7.8 15H5.2C5.08954 15 5 15.0895 5 15.2V16.8C5 16.9105 5.08954 17 5.2 17H7.8C7.91046 17 8 16.9105 8 16.8V15.2C8 15.0895 7.91046 15 7.8 15Z"
          fill="white"
        />
        <path
          d="M12.8 15H10.2C10.0895 15 10 15.0895 10 15.2V16.8C10 16.9105 10.0895 17 10.2 17H12.8C12.9105 17 13 16.9105 13 16.8V15.2C13 15.0895 12.9105 15 12.8 15Z"
          fill="white"
        />
        <path
          d="M17.8 15H15.2C15.0895 15 15 15.0895 15 15.2V16.8C15 16.9105 15.0895 17 15.2 17H17.8C17.9105 17 18 16.9105 18 16.8V15.2C18 15.0895 17.9105 15 17.8 15Z"
          fill="white"
        />
        <path
          d="M3.75 9.62497V11V12.8625C3.75 12.9729 3.83954 13.0625 3.95 13.0625H4.5H5.25H7.5H11.25H15H17.25H18.55C18.6605 13.0625 18.75 12.9729 18.75 12.8625L18.75 11.6875V11V10.3125V9.62497V8.93747V7.56247V6.18747V4.81247V3.43747V2.06247V0.35791L16.1234 1.67546C16.0491 1.71273 15.9595 1.70017 15.8984 1.64391L14.6175 0.465982C14.5488 0.402769 14.4454 0.395537 14.3686 0.448557L12.6442 1.63794C12.5616 1.69486 12.4497 1.68189 12.3824 1.6076L11.3586 0.477728C11.2952 0.407841 11.1917 0.391692 11.1101 0.438975L8.99547 1.66427C8.92029 1.70783 8.82558 1.69787 8.76111 1.63961L7.60567 0.595463C7.54242 0.538307 7.4499 0.527524 7.3752 0.568604L5.35908 1.67739C5.2923 1.71411 5.21045 1.70969 5.14801 1.66599L3.75 0.687469V1.37497V2.74997V4.12497V5.49997V6.87497V8.24997V9.62497Z"
          stroke="#FFFCFC"
          stroke-width="0.8"
        />
        <path d="M4.5 12.375V8.25H18V12.375H4.5Z" fill="#1E293B" stroke="#1E293B" stroke-width="0.8" />
        <path d="M5.33301 4L17.333 4" stroke="#F9FBFF" />
        <path d="M5.33301 7L17.333 7" stroke="#F9FBFF" />
        <path d="M5.33301 10L17.333 10" stroke="#F9FBFF" />
      </g>
      <defs>
        <clipPath id="clip0_285_1527">
          <rect width="24" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
);
