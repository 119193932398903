import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./budgetSupporterModal.module.scss";
import AddIcon from "@mui/icons-material/Add";
import ConnectMember from "src/components/connectCard/connectMember/ConnectMember";
import { colors } from "src/variable/randomColor";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getBudgetSupporterAction, updateBudgetSupporterAction } from "src/page/walletDetail/walletDetailSlice";

interface Iprops {
  rowId: number;
  budgetSupporter: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
  }[];
}

export default function BudgetSupporterModal({ rowId, budgetSupporter }: Iprops) {
  const [supporteropen, setSupporterOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { walletId } = useParams();
  const [budgetSupporterId, setBudgetSupporterId] = useState<number>();
  const { budgetSupporters, budgetSupporterLoading } = useAppSelector((state) => state.walletDetail);
  const [connectToParticipant, setConnectToParticipant] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getBudgetSupporterAction(rowId));
  }, [dispatch, rowId]);

  const handleSupporterOpen = () => {
    setSupporterOpen(true);
  };
  const handleSupporterClose = () => {
    setSupporterOpen(false);
  };

  const handleDeleteSupporter = (email?: string, supporterId?: number, removeItFromAll?: boolean) => {
    const deletedata = {
      walletId: Number(walletId),
      supporterId: Number(supporterId),
      action: "UNLINK",
      applyToAllBudgets: removeItFromAll || false
    };
    const budgetId = rowId;

    dispatch(updateBudgetSupporterAction(deletedata, budgetId)).then(() => {
      dispatch(getBudgetSupporterAction(rowId));
    });
  };

  const handleAddSupporter = () => {
    const adddata = {
      walletId: Number(walletId),
      supporterId: Number(budgetSupporterId),
      action: "LINK",
      applyToAllBudgets: connectToParticipant
    };
    const budgetId = rowId;

    dispatch(updateBudgetSupporterAction(adddata, budgetId)).then(() => {
      dispatch(getBudgetSupporterAction(rowId));
      setSupporterOpen(false);
    });
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnectToParticipant(e.target.value === "Yes");
  };

  const deleteInput = {
    buttonInfo: "budget",
    whomToRemove: "budget's",
    headInfo: "Supporter"
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Supporter </h5>
            {supporteropen ? "" : <AddIcon className={styles.editIcon} onClick={handleSupporterOpen} />}
          </div>
          {supporteropen ? (
            <div className={styles.addSupporter}>
              <Autocomplete
                options={budgetSupporter}
                getOptionLabel={(option) => option.firstName + " - " + option.email}
                style={{ width: 300 }}
                onChange={(event, newValue) => {
                  setBudgetSupporterId(newValue?.id);
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add Supporter" />}
              />
              <div className={styles.RadioInput}>
                <label>Connect to all of this wallet budgets?</label>
                <div className={styles.inputs}>
                  <div className={styles.inputYes}>
                    <input type="radio" name="connectOption" value="Yes" id="yes" onChange={handleRadioChange} />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className={styles.inputNo}>
                    <input type="radio" name="connectOption" value="No" id="No" onChange={handleRadioChange} />
                    <label htmlFor="No">No</label>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {""}
              {budgetSupporterLoading ? (
                <div className={styles.progress}>
                  <CircularProgress sx={{ color: "#0f172a" }} />
                </div>
              ) : (
                <>
                  {""}
                  {budgetSupporters.length === 0 ? (
                    <div className={styles.noSupporter}>No Budget supporter found!</div>
                  ) : (
                    budgetSupporters.map((user) => (
                      <ConnectMember
                        key={user.id}
                        img={user.firstName?.charAt(0).toLocaleUpperCase() || ""}
                        name={user.firstName + " " + user.lastName}
                        walletName={user.email || ""}
                        email={""}
                        supporterId={user.id}
                        bg={colors[user.id % 10]}
                        onDeleteSupporter={handleDeleteSupporter}
                        connectTo={"Wallet"}
                        deletedby={"budgetCard"}
                        deleteInput={deleteInput}
                      />
                    ))
                  )}
                </>
              )}
            </>
          )}
        </form>
      </div>
      {supporteropen ? (
        <div className={styles.referModalBottom}>
          <div className={styles.referModalBtn}>
            <Stack spacing={2} direction="row">
              <Button className={styles.cancelBtn} variant="text" onClick={handleSupporterClose}>
                Cancel
              </Button>
              <Button className={styles.sendBtn} variant="contained" onClick={handleAddSupporter}>
                Add
              </Button>
            </Stack>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
