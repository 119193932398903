import React, { useCallback, useEffect, useState, FormEventHandler } from "react";
import Modalui from "../UI/modal/Modalui";
import { Button, CircularProgress, IconButton, Menu, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Card, getAllCardsAction, updateCardAction, changeCardPinAction } from "src/page/cards/cardSlice";
import styles from "./cardEdit.module.scss";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { useSearchParams } from "react-router-dom";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import {
  CARD_ACCESS_TYPE,
  CARD_LIMIT,
  CARD_PAGE,
  CARD_SEARCH_TEXT,
  CARD_STATUS
} from "src/components/constant/queryConstants";
import { CardAccessType } from "src/types/commonTypes";
import { fetchOrgList } from "src/network/services/organisationService";
import { CAN_CONNECT_CARD_TO_ORGANISATION, CAN_UPDATE_CARD, CAN_CHANGE_PIN } from "../constant/constant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MuiOtpInput } from "mui-one-time-password-input";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { VisibilityOff, Visibility } from "@mui/icons-material";

interface Iprops {
  card: Card;
}

type Organisation = {
  id: number;
  name: string;
};

function CardEdit({ card }: Iprops) {
  const [open, setOpen] = useState<boolean>(false);
  const [orgOptions, setOrgOptions] = useState<Organisation[]>([]);
  const [org, setOrg] = useState<Organisation | null>();
  const [cardActive, setCardActive] = useState<boolean>(card.cardStatus);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pinModalOpen, setpinModalOpen] = useState<boolean>(false);
  const [pin, setPin] = useState<string>("");
  const [confirmPin, setConfirmPin] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");
  const [pinInputType, setPinInputType] = useState<"text" | "password">("password");
  const [confirmPinInputType, setConfirmPinInputType] = useState<"text" | "password">("password");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { cardPinLoading } = useAppSelector((state) => state.cards);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();
  const {
    cards: { loading },
    auth: { config }
  } = useAppSelector((state) => state);
  const [searchParams] = useSearchParams();

  const handleClose = () => setOpen(false);
  const handlePinClose = () => setpinModalOpen(false);

  const page = searchParams.get(CARD_PAGE) ?? "0";
  const rowsPerPage = searchParams.get(CARD_LIMIT) ?? "10";
  const cardStatus = searchParams.get(CARD_STATUS);
  const cardAccessType = searchParams.get(CARD_ACCESS_TYPE);
  const searchText = searchParams.get(CARD_SEARCH_TEXT) ?? "";
  const handleOrgSelect = (event: SelectChangeEvent) => {
    const selectedOrganisation = orgOptions.find((item) => item.id === parseInt(event.target.value));

    setOrg(selectedOrganisation);
  };

  const handleActiveSelect = (event: SelectChangeEvent) => {
    if (event.target.value === "active") {
      setCardActive(true);
    } else {
      setCardActive(false);
    }
  };
  const fetchOrganisations = async () => {
    const response = await fetchOrgList({
      page: 0,
      limit: 10,
      userIds: [card.user.id]
    });

    setOrgOptions(response.list);
    const defaultOrganisation = response.list.find((item: { id: number }) => item.id === card.organisationId);

    setOrg(defaultOrganisation);
  };

  useEffect(() => {
    if (open) {
      setCardActive(true);
      fetchOrganisations();
    }
  }, [card.organisationId, open]);

  const fetchCardsData = useCallback(() => {
    let cardStatusValue = null;

    if (cardStatus) {
      cardStatusValue = cardStatus === "active";
    }
    dispatch(
      getAllCardsAction({
        page: parseInt(page),
        limit: parseInt(rowsPerPage),
        active: cardStatusValue,
        accessTypes: cardAccessType != null ? [cardAccessType as CardAccessType] : undefined,
        searchText
      })
    );
  }, [dispatch, searchParams]);

  const handleSave = () => {
    dispatch(
      updateCardAction(
        card.id,
        {
          organisationId: org?.id,
          cardStatus: cardActive
        },
        () => {
          handleClose();
          fetchCardsData();
        }
      )
    );
  };

  const handlePinSave: FormEventHandler<HTMLFormElement> = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!pin.trim() || !confirmPin.trim()) {
      setPinError("PIN is required");
      return;
    }

    if (pin.length !== 4) {
      setPinError("PIN must be at least 4 characters long");
      return;
    }

    if (pin !== confirmPin) {
      setPinError("PINs do not match");
      return;
    }

    const data = {
      pin: pin.toString()
    };

    dispatch(changeCardPinAction(data, card.id)).finally(() => {
      setPin("");
      setConfirmPin("");
      setpinModalOpen(false);
    });
  };

  const handlePinChange = (newValue: string) => {
    setPin(newValue);
    if (newValue !== confirmPin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  const handleConfirmPinChange = (newValue: string) => {
    setConfirmPin(newValue);
    if (newValue !== pin) {
      setPinError("PINs do not match");
    } else {
      setPinError("");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    {
      showPassword ? setPinInputType("password") : setPinInputType("text");
    }
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
    {
      showConfirmPassword ? setConfirmPinInputType("password") : setConfirmPinInputType("text");
    }
  };

  return (
    <>
      {config?.allowedPermissions.includes(CAN_UPDATE_CARD || CAN_CHANGE_PIN) && (
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon></MoreVertIcon>
        </IconButton>
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {config?.allowedPermissions.includes(CAN_UPDATE_CARD) && (
          <MenuItem onClick={() => setOpen(true)}>Edit Card Details</MenuItem>
        )}
        {!config?.allowedPermissions.includes(CAN_CHANGE_PIN) && (
          <MenuItem onClick={() => setpinModalOpen(true)}>Change PIN</MenuItem>
        )}
      </Menu>

      <Modalui open={open} handleClose={handleClose} modaluiOver={styles.editModalOver}>
        <div className={styles.cardEditWrapper}>
          <div className={styles.header}>
            <h5>Edit Card</h5>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.body}>
            {loading ? (
              <div className={styles.progress}>
                <CircularProgress sx={{ color: "#0f172a" }} />
              </div>
            ) : (
              <>
                <div>
                  <span className={styles.label}>Card id: </span>
                  <span className={styles.value}>{card.id}</span>
                </div>
                <div>
                  <span className={styles.label}>Card Number: </span>
                  <span className={styles.value}>{card.cardNumber}</span>
                </div>
                <div>
                  <span className={styles.label}>Card BSB: </span>
                  <span className={styles.value}>{card.bsb}</span>
                </div>
                <div>
                  <span className={styles.label}>User: </span>
                  <span className={styles.value}>{card.user?.fullName}</span>
                </div>
                <div>
                  <span className={styles.label}>Access Type: </span>
                  <span className={styles.value}>{card.accessType}</span>
                </div>
                <div>
                  <span className={styles.label}>Card Status: </span>
                  <Select
                    value={cardActive ? "active" : "inactive"}
                    onChange={handleActiveSelect}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 30,
                      minWidth: 150
                    }}
                  >
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"inactive"}>Inactive</MenuItem>
                  </Select>
                </div>
                {config?.allowedPermissions.includes(CAN_CONNECT_CARD_TO_ORGANISATION) && (
                  <div className={styles.mt10}>
                    <span className={styles.label}>Organisation: </span>
                    <Select
                      value={org?.id + ""}
                      onChange={handleOrgSelect}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        height: 30,
                        minWidth: 150
                      }}
                      disabled={orgOptions.length <= 0}
                    >
                      {orgOptions.map((option) => (
                        <MenuItem value={option.id + ""}>
                          {option.id}) {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomContainer}>
            <Stack spacing={2} direction="row">
              <Button className={styles.cancelBtn} variant="text" onClick={handleClose}>
                Cancel
              </Button>
              <Button className={styles.saveBtn} variant="contained" onClick={handleSave} disabled={loading}>
                Apply Changes
              </Button>
            </Stack>
          </div>
        </div>
      </Modalui>
      <Modalui open={pinModalOpen} handleClose={handlePinClose} modaluiOver={styles.pinModalOver}>
        <div className={styles.pinEditWrapper}>
          <div className={styles.pinheader}>
            <h3>Change Pin</h3>
            <IconButton className="customIconButton" onClick={handlePinClose}>
              <CloseIcon />
            </IconButton>
          </div>
          {cardPinLoading ? (
            <LoadingModal />
          ) : (
            <form onSubmit={handlePinSave}>
              <div className={styles.pinbody}>
                {loading ? (
                  <div className={styles.pinprogress}>
                    <CircularProgress sx={{ color: "#0f172a" }} />
                  </div>
                ) : (
                  <>
                    <div className={styles.pinTitleContainer}>
                      <h4 className={styles.pinTitle}>Enter new PIN:</h4>
                      <span className={styles.visibilityIcon} onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <Visibility></Visibility> : <VisibilityOff></VisibilityOff>}
                      </span>
                    </div>
                    <div>
                      <div className={styles.otpInputContainer}>
                        <MuiOtpInput
                          value={pin}
                          onChange={handlePinChange}
                          length={4}
                          autoFocus
                          validateChar={(character: string) => /^[0-9]$/.test(character)}
                          TextFieldsProps={{ type: pinInputType }}
                        />
                      </div>
                    </div>

                    <div className={styles.confirmpinTitleContainer}>
                      <h4 className={styles.confirmpinTitle}>Re-enter new PIN:</h4>
                      <span className={styles.confirmvisibilityIcon} onClick={handleToggleConfirmPasswordVisibility}>
                        {showConfirmPassword ? <Visibility></Visibility> : <VisibilityOff></VisibilityOff>}
                      </span>
                    </div>

                    <div>
                      <div className={styles.otpInputContainer}>
                        <MuiOtpInput
                          value={confirmPin}
                          onChange={handleConfirmPinChange}
                          length={4}
                          autoFocus
                          validateChar={(character: string) => /^[0-9]$/.test(character)}
                          TextFieldsProps={{ type: confirmPinInputType }}
                        />
                      </div>
                    </div>

                    <br></br>
                    {pinError && (
                      <div className={styles.error}>
                        <ErrorOutlineIcon className={styles.errIcon} />
                        {pinError}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.pinbottom}>
                <div className={styles.pinbottomContainer}>
                  <Stack spacing={2} direction="row">
                    <Button className={styles.pincancelBtn} variant="text" onClick={handlePinClose}>
                      Cancel
                    </Button>
                    <Button className={styles.pinsaveBtn} variant="contained" type="submit">
                      Apply Changes
                    </Button>
                  </Stack>
                </div>
              </div>
            </form>
          )}
          ;
        </div>
      </Modalui>
    </>
  );
}

export default CardEdit;
