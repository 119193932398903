import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./addWalletModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { fetchUserListAction } from "src/page/wallets/walletSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { PARTICIPANT } from "src/components/constant/constant";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useParams } from "react-router-dom";

type WalletIn = {
  description: string;
  participantId: number;
  defaultWallet: boolean;
};
interface AddUserModalProps {
  handleClose: () => void;
  isWalletListEmpty: boolean;
  onAddWallet: (_addWallet: WalletIn, _close: boolean) => void;
}
interface User {
  id: number;
  firstName: string;
  lastName: string;
}

export default function AddUserModal({ handleClose, isWalletListEmpty, onAddWallet }: AddUserModalProps) {
  const { users } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const [description, setDescription] = useState<string>("");
  const [participant, setParticipant] = useState<User | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [defaultWallet, setDefaultWallet] = useState<boolean>(false);
  const [error, setError] = useState({ description: "", participant: "" });

  const handleCancel = () => {
    handleClose();
  };

  useEffect(() => {
    const data = {
      page: 0,
      filterContext: PARTICIPANT,
      isActive: true,
      organisationId: Number(organisationId) || null
    };

    dispatch(fetchUserListAction(data));
  }, [dispatch, organisationId]);

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
    setIsSaveDisabled(!event.target.value || !participant);
  };

  const handleParticipantChange = (event: React.ChangeEvent<object>, value: User | null) => {
    setParticipant(value || null);
    setIsSaveDisabled(!description || !value);
  };

  const handleDefaultWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultWallet(event.target.checked);
  };

  const handleSave = () => {
    if (!description.trim() || !participant) {
      setError({
        description: description ? "" : "Description is required",
        participant: participant ? "" : "Participant is required"
      });
      return;
    }

    if (participant) {
      let walletData;

      if (isWalletListEmpty) {
        walletData = {
          participantId: participant.id,
          description: description,
          defaultWallet: isWalletListEmpty
        };
      } else {
        walletData = {
          participantId: participant.id,
          description: description,
          defaultWallet: defaultWallet
        };
      }

      onAddWallet(walletData, true);
    }
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Create Wallet</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.text}>
            <label>Description</label>
            <input
              type="text"
              id="description"
              placeholder="Enter description here"
              value={description}
              onChange={handleDescriptionChange}
              required
            />
            {error.description && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.description}
              </div>
            )}
          </div>
          <div className={styles.participant}>
            <label>Participant</label>
            <Autocomplete
              className={styles.autoComplete}
              options={users.list}
              getOptionLabel={(option) => option.firstName + " " + option.lastName}
              renderInput={(params) => <TextField {...params} placeholder="Andy Baker" />}
              value={participant}
              onChange={handleParticipantChange}
            />
            {error.participant && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.participant}
              </div>
            )}
          </div>

          {isWalletListEmpty ? (
            <div className={styles.checkbox}>
              <input type="checkbox" checked disabled />
              <label>Default Wallet</label>
            </div>
          ) : (
            <div className={styles.checkbox}>
              <input type="checkbox" checked={defaultWallet} onChange={handleDefaultWalletChange} />{" "}
              <label>Default Wallet</label>
            </div>
          )}
          <div className={styles.switch}></div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              className={styles.sendBtn}
              variant="contained"
              disabled={isSaveDisabled}
              onClick={handleSave}
              style={{
                backgroundColor: isSaveDisabled ? "#9f9f9f" : undefined,
                color: isSaveDisabled ? "white" : undefined
              }}
            >
              Save
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
