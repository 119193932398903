import { Dispatch, createSlice } from "@reduxjs/toolkit";
import {
  loginService,
  forgetPassword,
  resetValidate,
  resetPassword,
  resetPasswordRequest,
  changePassword,
  fetchConfig,
  fetchMenu
} from "src/network/services/authService";
import { LoginData, User } from "src/types/commonTypes";
import swal from "sweetalert2";

export type Organisation = {
  id: string;
  name: string;
};

export type Config = null | {
  rolesCanBeAdded: string[];
  organisations: Organisation[];
  defaultOrganisation?: Organisation;
  allowedPermissions: string[];
};

export type MenuItem = {
  id: string;
  name: string;
};

const initialState: {
  loading: boolean;
  userInfo: User | null;
  token: string | null;
  error: object | null;
  forgetPasswordLoading: boolean;
  resetValidationLoading: boolean;
  resetPasswordLoading: boolean;
  changePasswordLoading: boolean;
  config: Config;
  menuList: MenuItem[];
} = {
  loading: false,
  userInfo: null,
  token: null,
  error: null,
  forgetPasswordLoading: false,
  resetValidationLoading: false,
  resetPasswordLoading: false,
  changePasswordLoading: false,
  config: null,
  menuList: []
};

const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload?.userInfo;
      state.token = action.payload?.token;
      state.error = null;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.userInfo = null;
    },
    logout: (state) => {
      state.userInfo = null;
      state.token = null;
      state.menuList = [];
      state.config = null;
    },
    forgetPasswordStart: (state) => {
      state.forgetPasswordLoading = true;
    },
    forgetPasswordSuccess: (state) => {
      state.forgetPasswordLoading = false;
    },
    forgetPasswordFail: (state, action) => {
      state.forgetPasswordLoading = false;
      state.error = action?.payload;
    },
    resetValidationStart: (state) => {
      state.resetValidationLoading = true;
    },
    resetValidationSuccess: (state) => {
      state.resetValidationLoading = false;
    },
    resetValidationFail: (state, action) => {
      state.resetValidationLoading = false;
      state.error = action?.payload;
    },
    resetPasswordStart: (state) => {
      state.resetPasswordLoading = true;
    },
    resetPasswordSuccess: (state) => {
      state.resetPasswordLoading = false;
    },
    resetPasswordFail: (state, action) => {
      state.resetPasswordLoading = false;
      state.error = action?.payload;
    },
    changePasswordStart: (state) => {
      state.changePasswordLoading = true;
    },
    changePasswordSuccess: (state) => {
      state.changePasswordLoading = false;
    },
    changePasswordFail: (state, action) => {
      state.changePasswordLoading = false;
      state.error = action?.payload;
    },
    getConfigSuccess: (state, action) => {
      state.config = action.payload;
      if (
        !action.payload.defaultOrganisation &&
        state.config?.organisations &&
        state.config?.organisations?.length > 0
      ) {
        state.config.defaultOrganisation = state.config?.organisations[0];
      }
    },
    setDefaultOrganisation: (state, action) => {
      if (state.config) {
        state.config.defaultOrganisation = action.payload;
      }
    },
    setMenuItems: (state, action) => {
      state.menuList = action.payload?.menuList;
    },
    setConfig: (state, action) => {
      state.config = {
        ...state.config,
        ...action.payload
      };
    }
  }
});

const {
  loginStart,
  loginFail,
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordFail,
  resetValidationStart,
  resetValidationSuccess,
  resetValidationFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  getConfigSuccess,
  setMenuItems,
  setConfig
} = loginSlice.actions;

export const { logout, setDefaultOrganisation, loginSuccess } = loginSlice.actions;

export const loginAction = (data: LoginData, cb?: () => void) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginStart());
    try {
      const loginResponse = await loginService(data);

      dispatch(loginSuccess(loginResponse));
      const configResponse = await fetchConfig();
      const menuItemResponse = await fetchMenu();

      dispatch(getConfigSuccess(configResponse));
      dispatch(setMenuItems(menuItemResponse));
      if (cb) cb();
    } catch (error) {
      dispatch(loginFail(error));
    }
  };
};

export const getConfigAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const configResponse = await fetchConfig();
      const menuItemResponse = await fetchMenu();

      dispatch(getConfigSuccess(configResponse));
      dispatch(setMenuItems(menuItemResponse));
    } catch (error) {
      dispatch(logout());
    }
  };
};

export const forgetPasswordAction = (data: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(forgetPasswordStart());
    try {
      await forgetPassword(data);
      dispatch(forgetPasswordSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "We have Send you the Reset Link to Your Registered Email"
      });
    } catch (error) {
      dispatch(forgetPasswordFail(error));
    }
  };
};

export const resetValidationAction = (data: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(resetValidationStart());
    try {
      await resetValidate(data);
      dispatch(resetValidationSuccess());
    } catch (error) {
      dispatch(resetValidationFail(error));
      swal.fire("Opps!", "The Link is expired", "warning");
    }
  };
};

export const resetPasswordAction = (data: resetPasswordRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(resetPasswordStart());
    try {
      await resetPassword(data);
      dispatch(resetPasswordSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Password Reset Successfully"
      });
    } catch (error) {
      dispatch(resetPasswordFail(error));
      swal.fire("Opps!", "Reset Password is Falied", "warning");
    }
  };
};

export const changePasswordAction = (data: resetPasswordRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(changePasswordStart());
    try {
      await changePassword(data);
      dispatch(changePasswordSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Password Change Successfully",
        showConfirmButton: false,
        timer: 990
      });
    } catch (error) {
      dispatch(changePasswordFail(error));
      swal.fire("Opps!", " Password Change is Falied", "warning");
    }
  };
};

export const fetchConfigAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await fetchConfig();

      dispatch(setConfig(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMenuAction = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await fetchMenu();

      dispatch(setMenuItems(response));
    } catch (error) {
      console.log(error);
    }
  };
};

export default loginSlice.reducer;
