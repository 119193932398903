import api from "src/network/api";
import { PaginationRequest, kycStatusType } from "src/types/commonTypes";

export interface UserListRequest extends PaginationRequest {
  filterContext?: string | null;
  // guardianId?: number;
  ids?: number[];
  isActive?: boolean | null;
  organisationId?: number | null;
}
export const fetchUserList = (data: UserListRequest) => {
  return api.post("/users/search", data);
};

export interface addUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  organisationId?: number | null;
}
export const addUser = (data: addUserRequest) => {
  return api.post("/users", data);
};

export const getUserData = (userId: number) => {
  return api.get(`/users/${userId}`);
};
export const getUserDetail = (userId: number) => {
  return api.get(`/users/${userId}/detail`);
};

export interface userDetailRequest {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  state?: string;
  city?: string;
  address?: string;
  zipcode?: string;
  active?: boolean;
  role?: string;
  guardianId?: number;
  kycStatus?: kycStatusType | null;
}

export const updateUserDetail = (userId: number, data: userDetailRequest) => {
  return api.put(`/users/${userId}`, data);
};

export interface connectUserRequest {
  emailId: string;
  role: string | null;
  action: string;
}

export const connectUser = (organisationId: number, data: connectUserRequest) => {
  return api.post(`/organisations/${organisationId}/users`, data);
};
