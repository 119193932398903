import cognitoProdConfig from "./cognitoConfig.prod";
import cognitoStageConfig from "./cognitoConfig.stage";

export type CognitoConfig = {
  Auth: {
    clientId: string;
    clientSecret: string;
    basic: string;
  };
  Pool: {
    poolId: string;
  };
  baseUrl: string;
  redirectUrl: string;
  oauthProvider: string;
};

export type HostPrefix = "hub" | "possibility";
const prodHosts = ["hub.gimmeit.net.au", "possibility.gimmeit.net.au"];

const prodConfig = {
  API_URL: "https://gimmeit.net.au:8443",
  SIMS_URL: "https://sims.gimmeit.net.au"
};

const stageConfig = {
  API_URL: "https://gimmeit.net.au:9443",
  SIMS_URL: "https://sims-stage.gimmeit.net.au"
};

const hostname = window.location.hostname;

let config: {
  API_URL: string;
  SIMS_URL: string;
  cognitoConfig: CognitoConfig;
} = {
  ...stageConfig,
  cognitoConfig: cognitoStageConfig["hub"]
};

if (prodHosts.includes(hostname)) {
  const hostPrefix: HostPrefix = hostname.split("gimmeit.net.au")[0] as HostPrefix;

  config = {
    ...prodConfig,
    cognitoConfig: cognitoProdConfig[hostPrefix] as CognitoConfig
  };
}
export default config;
