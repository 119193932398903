import { ChangeEvent } from "react";
import { CAN_UPDATE_BUDGET, CAN_UPDATE_REPEAT_PAY, CAN_UPDATE_WALLET_SETTINGS } from "src/components/constant/constant";

export const SupporterSettingOption = [
  {
    id: 1,
    head: "Account",

    option: [
      {
        id: "activeUser",
        // checked: false,
        label: "Active User"
      },
      {
        id: "receiptTransaction",
        // checked: false,

        label: "Requires a receipt for every transaction"
      },
      {
        id: "withdrawCash",
        // checked: false,

        label: "Can withdraw Cash"
      }
    ]
  },
  {
    id: 2,
    head: "Suspend User",
    option: [
      {
        id: "suspendAccount",
        // checked: false,

        label: "Suspend this account"
      }
    ]
  }
];
export const ManagerSettingOption = [
  {
    id: 1,
    head: "Account",

    option: [
      {
        id: "activeUser",
        // checked: false,

        label: "Active User"
      }
    ]
  },
  {
    id: 2,
    head: "Suspend User",
    option: [
      {
        id: "suspendAccount",
        // checked: false,

        label: "Suspend this account"
      }
    ]
  }
];
export const ParticipantSettingOption = [
  {
    id: 1,
    head: "Account",

    option: [
      {
        id: "activeUser",
        // checked: false,

        label: "Active User"
      },
      {
        id: "receiptTransaction",
        // checked: false,

        label: "Requires a receipt for every transaction"
      },
      {
        id: "withdrawCash",
        // checked: false,

        label: "Can withdraw Cash"
      }
    ]
  },
  {
    id: 2,
    head: "Suspend User",
    option: [
      {
        id: "suspendAccount",
        // checked: false,

        label: "Suspend this account"
      }
    ]
  }
];
export const ParentSettingOption = [
  {
    id: 1,
    head: "Account",

    option: [
      {
        id: "activeUser",
        // checked: false,

        label: "Active User"
      },
      {
        id: "receiptTransaction",
        // checked: false,

        label: "Requires a receipt for every transaction"
      },
      {
        id: "withdrawCash",
        // checked: false,

        label: "Can withdraw Cash"
      }
    ]
  },
  {
    id: 2,
    head: "Suspend User",
    option: [
      {
        id: "suspendAccount",
        // checked: false,

        label: "Suspend this account"
      }
    ]
  }
];

type WalletDetail = {
  wallet: {
    id: number;
    participant: {
      id: number;
      fullName: string;
      firstName: string;
      lastname: string;
      phoneNumber: string;
      role: string;
      address: string;
      city: string;
      state: string;
      zipcode: string;
    };
    status: boolean;
    description: string;
    defaultWallet: boolean;
  };
  supporter: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
  }[];
  pendingTransactions: {
    id: number;
    requestedAmount: number;
    isRepeatPay: boolean;
    useWholeBalance: boolean;
  }[];
};

export const getWalletSettingOptions = (
  walletDetail: WalletDetail,
  isDefaultWallet: boolean,
  isActiveChecked: boolean,
  isSetBudgetChecked: boolean,
  isAllowRepeatChecked: boolean,
  handleDefaultWalletToggle: (_event: ChangeEvent<HTMLInputElement>) => void,
  handleActiveToggle: (_event: ChangeEvent<HTMLInputElement>) => void,
  // handleSetBudgetToggle: (_event: ChangeEvent<HTMLInputElement>, _optionId: string, _useWholeBalance: boolean) => void,
  handleAllowRepeatToggle: (_event: ChangeEvent<HTMLInputElement>, _optionId?: string) => void,
  allowedPermissions: string[] | undefined
) => {
  const settingOptions = [];
  const accountOption = [];

  if (walletDetail.wallet.status === true) {
    if (walletDetail.wallet.defaultWallet === true) {
      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_WALLET_SETTINGS)) {
        accountOption.push({
          id: "defaultWallet",
          checked: isDefaultWallet,
          label: "Default Wallet",
          onChange: handleDefaultWalletToggle
        });
      }

      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_BUDGET)) {
        accountOption.push({
          id: "setBudgetForThisWallet",
          label: "Set budgets for this wallet",
          checked: isSetBudgetChecked
        });
      }

      settingOptions.push({
        id: 1,
        head: "Account",
        option: accountOption
      });

      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_REPEAT_PAY)) {
        settingOptions.push({
          id: 2,
          head: "Repeat Pay",
          option: [
            {
              id: "allowRepeat",
              label: "Allow Repeat Pay For this Wallet?",
              checked: isAllowRepeatChecked,
              onChange: handleAllowRepeatToggle
            }
          ]
        });
      }
    } else {
      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_WALLET_SETTINGS)) {
        accountOption.push(
          {
            id: "Active",
            checked: isActiveChecked,
            label: "Active",
            onChange: handleActiveToggle
          },
          {
            id: "defaultWallet",
            checked: isDefaultWallet,
            label: "Default Wallet",
            onChange: handleDefaultWalletToggle
          }
        );
      }

      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_BUDGET)) {
        accountOption.push({
          id: "setBudgetForThisWallet",
          label: "Set budgets for this wallet",
          checked: isSetBudgetChecked
        });
      }

      settingOptions.push({
        id: 1,
        head: "Account",
        option: accountOption
      });

      if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_REPEAT_PAY)) {
        settingOptions.push({
          id: 2,
          head: "Repeat Pay",
          option: [
            {
              id: "allowRepeat",
              label: "Allow Repeat Pay For this Wallet?",
              checked: isAllowRepeatChecked
            }
          ]
        });
      }
    }
  } else if (walletDetail.wallet.status === false) {
    if (allowedPermissions && allowedPermissions.includes(CAN_UPDATE_WALLET_SETTINGS)) {
      accountOption.push({
        id: "Active",
        checked: isActiveChecked,
        label: "Active",
        onChange: handleActiveToggle
      });
    }
    settingOptions.push({
      id: 1,
      head: "Account",
      option: accountOption
    });
  }

  return settingOptions || [];
};
