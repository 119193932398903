import api from "src/network/api";
import { PaginationRequest } from "src/types/commonTypes";

export interface orgListRequest extends PaginationRequest {
  active?: boolean | null;
  startDate?: string | null;
  endDate?: string | null;
  userIds?: number[];
}

export const fetchOrgList = async (data: orgListRequest) => {
  return api.post("/organisations/search", data) as any;
};

export const getOrgDetail = async (orgId: number | string) => {
  return api.get(`/organisations/${orgId}/detail`);
};

export interface addorgRequest {
  id: number | null;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode?: string;
  website: string;
  facebook?: string;
  linkedIn?: string;
  instagram?: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode?: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy?: string;
  createdOn?: string;
  updatedBy?: string;
  updatedOn?: string;
  active: boolean;
}

export const addOrg = async (data: addorgRequest) => {
  return api.post(`/organisations`, data);
};
