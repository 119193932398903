import { FormEventHandler, useState } from "react";
import styles from "./auth.module.scss";
import { Button, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import api from "src/network/api";
import { loginAction, forgetPasswordAction } from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { useNavigate } from "react-router-dom";
import { spendAbleLogo } from "src/components/nav/logo";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// import CognitoAuth from "src/components/cognitoAuth/CognitoAuth";

function Login() {
  const [rightPanelActive, setRightPanelActive] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [contact, setContact] = useState<number>();
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [registeredEmail, setRegisteredEmail] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { loading, forgetPasswordLoading } = useAppSelector((state) => state.auth);
  const [emailError, setEmailError] = useState({ email: "", loginemail: "", loginPassword: "" });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSignUpClick = () => {
    window.open("https://spendable.com.au/freetrial", "_blank");
  };

  const handleForgotPasswordClick = () => {
    setForgotPassword(true);
  };

  const handleLoginClick = () => {
    setForgotPassword(false);
  };

  const handleSignInClick = () => {
    setRightPanelActive(false);
  };

  const handleSignUp: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      await api.post("/signup", { email, password });
    } catch (error) {
      console.error("Sign-up error:", error);
    }
  };

  const handleSignIn: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    if (!email.trim() || !password.trim()) {
      setEmailError({
        ...emailError,
        loginemail: email.trim() ? "" : "Email is required",
        loginPassword: password.trim() ? "" : "Password is required"
      });
      return;
    }

    dispatch(
      loginAction({ email, password }, () => {
        navigate("/");
      })
    );
  };

  const handleForgotPassword = () => {
    if (!registeredEmail.trim()) {
      setEmailError({ ...emailError, email: "Email is required" });
      return;
    }

    dispatch(forgetPasswordAction(registeredEmail)).then(() => {
      setRegisteredEmail("");
    });
  };

  return (
    <div className={`${styles.container} ${rightPanelActive ? styles.rightPanelActive : ""}`} id="container">
      <div className={`${styles.formContainer} ${styles.signUpContainer}`}>
        <form onSubmit={handleSignUp}>
          <div className={styles.formIn}>
            <div className={styles.logo}>
              <div className={styles.logoL}>{spendAbleLogo}</div>
              <div className={styles.logoR}>
                <h1 className={styles.title}>SpendAble</h1>
                <div className={styles.subTitle}>Enabling Choices, Empowering Change</div>
              </div>
            </div>
            <div className={styles.header}>
              <h1>Sign up</h1>
              <p>If it&apos;s your first time here, you&apos;re in the right place</p>
            </div>
            <div className={styles.formInput}>
              <div className={styles.firstName}>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="Enter your name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className={styles.contact}>
                <label htmlFor="contact">Phone Number</label>
                <input
                  type="number"
                  id="contact"
                  placeholder="e.g. 0412345678"
                  value={contact}
                  // onChange={(e) =>
                  //   setSignUpForm({ ...signUpForm, contact: e.target.value })
                  // }
                  onChange={(e) => setContact(Number(e.target.value))}
                />
              </div>
              <div className={styles.email}>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  placeholder="example@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className={styles.password}>
                <label htmlFor="pass">Password</label>
                <input
                  type="password"
                  id="pass"
                  placeholder="Create a password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={agreeToTerms}
                  // onChange={(e) =>
                  //   setSignUpForm({
                  //     ...signUpForm,
                  //     agreeToTerms: e.target.checked,
                  //   })
                  // }
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                />
                <span>
                  I agree to the<b> Terms and conditions</b>
                </span>
              </div>
            </div>
            <div className={styles.signUp}>
              <Button type="submit" variant="contained" className={styles.SignUpBtn} disabled={loading}>
                Sign Up
              </Button>
              <Button variant="contained" className={styles.providerBtn}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.9667 8.95003L16.8334 7.63336C16.6167 7.38336 16.4417 6.9167 16.4417 6.58336V5.1667C16.4417 4.28336 15.7167 3.55836 14.8334 3.55836H13.4167C13.0917 3.55836 12.6167 3.38336 12.3667 3.1667L11.0501 2.03336C10.4751 1.5417 9.5334 1.5417 8.95007 2.03336L7.64173 3.17503C7.39173 3.38336 6.91673 3.55836 6.59173 3.55836H5.15006C4.26673 3.55836 3.54173 4.28336 3.54173 5.1667V6.5917C3.54173 6.9167 3.36673 7.38336 3.1584 7.63336L2.0334 8.95836C1.55007 9.53336 1.55007 10.4667 2.0334 11.0417L3.1584 12.3667C3.36673 12.6167 3.54173 13.0834 3.54173 13.4084V14.8334C3.54173 15.7167 4.26673 16.4417 5.15006 16.4417H6.59173C6.91673 16.4417 7.39173 16.6167 7.64173 16.8334L8.9584 17.9667C9.5334 18.4584 10.4751 18.4584 11.0584 17.9667L12.3751 16.8334C12.6251 16.6167 13.0917 16.4417 13.4251 16.4417H14.8417C15.7251 16.4417 16.4501 15.7167 16.4501 14.8334V13.4167C16.4501 13.0917 16.6251 12.6167 16.8417 12.3667L17.9751 11.05C18.4584 10.475 18.4584 9.52503 17.9667 8.95003ZM13.4667 8.42503L9.44173 12.45C9.32507 12.5667 9.16673 12.6334 9.00007 12.6334C8.8334 12.6334 8.67507 12.5667 8.5584 12.45L6.54173 10.4334C6.30007 10.1917 6.30007 9.7917 6.54173 9.55003C6.7834 9.30836 7.1834 9.30836 7.42507 9.55003L9.00007 11.125L12.5834 7.5417C12.8251 7.30003 13.2251 7.30003 13.4667 7.5417C13.7084 7.78336 13.7084 8.18336 13.4667 8.42503Z"
                    fill="#17181A"
                  />
                </svg>
                <span>I&apos;m From A Provider</span>
              </Button>
            </div>
            <div className={styles.overlayContainerL}>
              <div className={styles.overlayL}>
                <div className={`${styles.overlayPanelL} ${styles.overlayLeft}`}>
                  <span>
                    Already have an account?
                    <b className={styles.ghost} id="signIn" onClick={handleSignInClick}>
                      . Sign in
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {rightPanelActive ? null : (
        <div className={`${styles.formContainer} ${styles.signInContainer}`}>
          <form onSubmit={handleSignIn}>
            <div className={styles.formIn}>
              {loading ? (
                <CircularProgress sx={{ color: "#0f172a" }} />
              ) : (
                <>
                  <div className={styles.logo}>
                    <div className={styles.logoL}>{spendAbleLogo}</div>
                    <div className={styles.logoR}>
                      <h1 className={styles.title}>SpendAble</h1>
                      <div className={styles.subTitle}>Enabling Choices, Empowering Change</div>
                    </div>
                  </div>
                  {forgotPassword ? (
                    <>
                      <div className={styles.header}>
                        <h1>Password Recovery</h1>
                      </div>
                      {forgetPasswordLoading ? (
                        <CircularProgress sx={{ color: "#0f172a" }} />
                      ) : (
                        <>
                          <div className={styles.formInput}>
                            <div className={styles.email}>
                              <label htmlFor="emailAdd">Registered Email</label>
                              <TextField
                                type="email"
                                id="RegistEmail"
                                placeholder="EMAIL"
                                value={registeredEmail}
                                onChange={(e) => {
                                  setRegisteredEmail(e.target.value);
                                  if (!e.target.value) {
                                    setEmailError({ ...emailError, email: "Email is required" });
                                  } else {
                                    setEmailError({ ...emailError, email: "" });
                                  }
                                }}
                                sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                              />
                              {emailError.email && (
                                <div className={styles.error}>
                                  <ErrorOutlineIcon className={styles.errIcon} />
                                  {emailError.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={styles.signIn}>
                            <Button
                              variant="contained"
                              className={styles.SignInBtn}
                              disabled={forgetPasswordLoading}
                              onClick={handleForgotPassword}
                            >
                              Send
                            </Button>
                          </div>
                          <div className={styles.overlayContainerR}>
                            <div className={styles.overlayR}>
                              <div className={`${styles.overlayPanelR} ${styles.overlayRight}`}>
                                <span>
                                  Go Back To
                                  <b onClick={handleLoginClick}>. Login</b>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className={styles.header}>
                        <h1>Welcome back</h1>
                      </div>
                      <div className={styles.formInput}>
                        <div className={styles.email}>
                          <label htmlFor="emailAdd">Email Address</label>
                          <TextField
                            type="email"
                            id="emailAdd"
                            placeholder="EMAIL"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                          />
                          {emailError.loginemail && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {emailError.loginemail}
                            </div>
                          )}
                        </div>
                        <div className={styles.password}>
                          <label htmlFor="passw">Password</label>
                          <TextField
                            type={showPassword ? "text" : "password"}
                            id="passw"
                            placeholder="PASSWORD"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            sx={{ width: "100%", borderRadius: "6px", borderColor: "#ced4da" }}
                          />
                          {emailError.loginPassword && (
                            <div className={styles.error}>
                              <ErrorOutlineIcon className={styles.errIcon} />
                              {emailError.loginPassword}
                            </div>
                          )}
                        </div>
                        <div className={styles.checkboxContainer}>
                          <div className={styles.checkboxL}>
                            {/* <input type="checkbox" />
                            <span>Remember for 14 days</span> */}
                          </div>
                          <div className={styles.checkboxR}>
                            <a href="#" onClick={handleForgotPasswordClick}>
                              Forgot Password
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className={styles.signIn}>
                        <Button type="submit" variant="contained" className={styles.SignInBtn} disabled={loading}>
                          Sign In
                        </Button>
                        {/* <CognitoAuth /> */}
                      </div>
                      <div className={styles.overlayContainerR}>
                        <div className={styles.overlayR}>
                          <div className={`${styles.overlayPanelR} ${styles.overlayRight}`}>
                            <span>
                              Don&apos;t have an account?
                              <b className={styles.ghost} id="signUp" onClick={handleSignUpClick}>
                                . Sign up
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      )}
      <div className={styles.overlayContainer}>
        <div className={styles.overlay}>
          <div className={`${styles.overlayPanel} ${styles.overlayLeft}`}>
            <div className={styles.leftBg}>
              <div className={styles.leftCard}>
                <div className={styles.headText}>
                  <span>Support good </span>
                  <span>money handling</span>
                </div>
                <div className={styles.midText}>
                  Access the tools and accounts to safely handle money for older people and people with disability
                </div>
                <div className={styles.bottomText}>
                  <div className={styles.icon}>
                    <AvatarGroup className={styles.AvatarGroup}>
                      <Avatar
                        alt="Remy Sharp"
                        src={"https://randomuser.me/api/portraits/men/1.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Travis Howard"
                        src={"https://randomuser.me/api/portraits/women/2.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Cindy Baker"
                        src={"https://randomuser.me/api/portraits/men/3.jpg"}
                        className={styles.avatar}
                      />
                      <Avatar
                        alt="Agnes Walker"
                        src={"https://randomuser.me/api/portraits/women/4.jpg"}
                        className={styles.avatar}
                      />
                    </AvatarGroup>
                  </div>
                  <span>Join 1,000’s of other people!</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.overlayPanel} ${styles.overlayRight}`}>
            <div className={styles.rightBg}>
              <div className={styles.rightCard}>
                <div className={styles.topText}>You&apos;re part of the solution to</div>
                <div className={styles.bottomText}>financial abuse</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
