import { Avatar } from "@mui/material";
import styles from "../recentrans.module.scss";
import { colors } from "src/variable/randomColor";

interface Data {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
}

const columns = [
  {
    title: "Merchant",
    dataIndex: "merchant",
    key: "merchant",
    width: 150,
    render: (merchantName: string, data: Data) => (
      <span className={styles.tableTrans}>
        {data.merchantName === null || data.merchantName === undefined || data.merchantName.trim() === ""
          ? "---"
          : data.merchantName}
      </span>
    )
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 100,
    render: (amount: number) => <>${amount}</>
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 150,
    render: (date: number) => {
      const dateValue = date;

      if (dateValue === undefined) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }
      const ndate = new Date(dateValue * 1000);
      const formattedDate = ndate.toLocaleDateString();
      // const formattedTime = date.toLocaleTimeString();
      const formattedTime = ndate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

      return (
        <>
          <div>{formattedDate}</div>
          <div>{formattedTime}</div>
        </>
      );
    }
  },
  {
    title: "Wallet",
    dataIndex: "wallet",
    key: "wallet",
    width: 200,
    render: (img: string, data: Data) => (
      <div className={styles.tableWallet}>
        <div className={styles.tableHolderImg}>
          {/* <img src={wallet.img} alt="Wallet" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: colors[data.wallet.id % 10] }}>
            {data.wallet.participant.fullName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div>
          <div className={styles.tableHolderName}>{data.wallet.participant.fullName}</div>
          <div>{data.wallet.description}</div>
        </div>
      </div>
    )
  }
  // {
  //   title: "",
  //   dataIndex: "",
  //   key: "operations",
  //   render: () => (
  //     <a href="#">
  //       <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  //         <path
  //           d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
  //           stroke="#64748B"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //       </svg>
  //     </a>
  //   ),
  //   width: 100
  // }
];

export default columns;
