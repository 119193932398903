// import { Avatar } from "@mui/material";
// import styles from "./depositCard.module.scss";
// import { colors } from "src/variable/randomColor";

// interface Data {
//   id: number;
//   wallet: { id: number; description: string; participant: { fullName: string } };
//   amount: number;
//   transactionDate?: number;
//   updatedAt?: number;
//   createdAt?: number;
//   merchantName?: string;
//   date?: number;
// }

const columns = [
  {
    title: "Date",
    dataIndex: "transactionDate",
    key: "transactionDate",
    width: 150,
    render: (dateValue: number) => {
      if (dateValue === undefined) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }
      const ndate = new Date(dateValue * 1000);
      const formattedDate = ndate.toLocaleDateString();
      // const formattedTime = date.toLocaleTimeString();
      const formattedTime = ndate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

      return (
        <>
          <div>{formattedDate}</div>
          <div>{formattedTime}</div>
        </>
      );
    }
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: 100,
    render: (amountAdded: number) => <>${amountAdded}</>
  }
];

export default columns;
