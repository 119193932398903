import { LoginData } from "src/types/commonTypes";
import api from "../api";

export const loginService = (data: LoginData) => {
  return api.post("/login", data);
};

export const forgetPassword = (email: string) => {
  return api.post(`/public/forgetpassword`, email, {
    headers: {
      "Content-Type": "text/plain"
    }
  });
};
export const resetValidate = (token: string) => {
  return api.post(`public/validatetoken`, token, {
    headers: {
      "Content-Type": "text/plain"
    }
  });
};

export interface resetPasswordRequest {
  token: string | null;
  password: string;
}
export const resetPassword = (data: resetPasswordRequest) => {
  return api.post(`public/resetpassword`, data);
};

export const changePassword = (data: resetPasswordRequest) => {
  return api.put(`/users/changepassword`, data);
};

export const fetchConfig = () => {
  return api.get(`/config`);
};

export const fetchMenu = () => {
  return api.get(`/config/menu`);
};
