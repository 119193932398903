import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./userListFilter.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField } from "@mui/material";
import { fetchUserListAction } from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import ToggleSwitch from "src/components/UI/toggleSwitch/ToggleSwitch";
import { PARTICIPANT, SUPPORTER } from "src/components/constant/constant";
import { useSearchParams } from "react-router-dom";
import {
  WAL_END_DATE,
  WAL_MAX_AMT,
  WAL_MIN_AMT,
  WAL_PARTICIPANTS,
  WAL_START_DATE,
  WAL_SUPPORTERS
} from "src/components/constant/queryConstants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface FilterTransModalProps {
  handleClose: () => void;
  handleFilter: () => void;
}
export default function UserListFilter({ handleClose, handleFilter }: FilterTransModalProps) {
  const [toggleValue, setToggleValue] = useState<string>(PARTICIPANT);
  const [error, setError] = useState({ Amt: "", Date: "" });
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get(WAL_START_DATE) || "";
  const endDate = searchParams.get(WAL_END_DATE) || "";
  const minAmt = searchParams.get(WAL_MIN_AMT) || "";
  const maxAmt = searchParams.get(WAL_MAX_AMT) || "";
  // const selectedParticipants = searchParams.get(WAL_PARTICIPANTS) || "";
  // const selectedSupporters = searchParams.get(WAL_SUPPORTERS) || "";

  const { users } = useAppSelector((state) => state.transaction);
  const dispatch = useAppDispatch();

  const handleApplyFilter = () => {
    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
      return;
    }

    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
      return;
    }

    setSearchParams(searchParams);
    handleFilter();
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleValue = (val?: string | number | boolean) => {
    if (typeof val === "string") {
      setToggleValue(val);
      searchParams.delete(toggleValue === PARTICIPANT ? WAL_PARTICIPANTS : WAL_SUPPORTERS);
      setSearchParams(searchParams);
    }
  };

  const handleReset = () => {
    setSearchParams({});
    setError({ Amt: "", Date: "" });
    handleFilter();
    // handleClose();
  };

  useEffect(() => {
    const page = 0;
    const filterContext = toggleValue;
    const isActive = true;

    dispatch(fetchUserListAction({ page, filterContext, isActive }));
  }, [dispatch, toggleValue]);

  useEffect(() => {
    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
    } else {
      setError({
        ...error,
        Amt: ""
      });
    }
  }, [minAmt, maxAmt, error]);

  useEffect(() => {
    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
    } else {
      setError({
        ...error,
        Date: ""
      });
    }
  }, [startDate, endDate, error]);

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Transactions</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.filterByDate}>
            <div className={styles.head}>Filter by Date</div>
            <div className={styles.date}>
              <div className={styles.startDate}>
                <label>Start Date</label>
                <TextField
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    searchParams.set(WAL_START_DATE, e.target.value);
                    setSearchParams(searchParams);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className={styles.endDate}>
                <label>End Date</label>
                <TextField
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    searchParams.set(WAL_END_DATE, e.target.value);
                    setSearchParams(searchParams);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "MuiInputLabel-outlined"
                    }
                  }}
                />
              </div>
            </div>
            {error.Date && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Date}
              </div>
            )}
          </div>
          <div className={styles.filterByAmt}>
            <div className={styles.head}>Filter by Amount</div>
            <div className={styles.amt}>
              <div className={styles.min}>
                <label>Minimum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={minAmt ? Number(minAmt) : undefined}
                  onChange={(e) => {
                    searchParams.set(WAL_MIN_AMT, e.target.value);
                    setSearchParams(searchParams);
                  }}
                />
              </div>
              <div className={styles.max}>
                <label>Maximum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={maxAmt ? Number(maxAmt) : undefined}
                  onChange={(e) => {
                    searchParams.set(WAL_MAX_AMT, e.target.value);
                    setSearchParams(searchParams);
                  }}
                />
              </div>
            </div>
            {error.Amt && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Amt}
              </div>
            )}
          </div>
          <div className={styles.addUser}>
            <div className={styles.byUser}>
              <div className={styles.byUserHead}>
                <label className={styles.userlabel}>By User</label>
                <ToggleSwitch
                  leftValueIn={"Participant"}
                  rightValueIn={"Supporter"}
                  leftValueOut={PARTICIPANT}
                  rightValueOut={SUPPORTER}
                  getValue={handleValue}
                  toggleBodyOver={styles.toggleBodyOver}
                  contentOver={styles.contentOver}
                  className={styles.myToggleSwitch}
                />
              </div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={users.list}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                onChange={(event, value) => {
                  if (value && value.length > 0) {
                    searchParams.set(
                      toggleValue === PARTICIPANT ? WAL_PARTICIPANTS : WAL_SUPPORTERS,
                      value.map((user) => user?.id).join("&")
                    );
                  } else {
                    searchParams.delete(toggleValue === PARTICIPANT ? WAL_PARTICIPANTS : WAL_SUPPORTERS);
                  }
                  setSearchParams(searchParams);
                }}
                renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                value={
                  toggleValue === PARTICIPANT
                    ? searchParams
                        .get(WAL_PARTICIPANTS)
                        ?.split("&")
                        .map((id) => users.list.find((user) => user.id === Number(id))) || []
                    : searchParams
                        .get(WAL_SUPPORTERS)
                        ?.split("&")
                        .map((id) => users.list.find((user) => user.id === Number(id))) || []
                }
              />
            </div>
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset}>
              Reset Filter
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
