import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./filterTransaction.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField, FormControl, MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { fetchUserListAction, fetchWalletListAction, setUsers } from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { PARTICIPANT, SUPPORTER } from "src/components/constant/constant";
import ToggleSwitch from "src/components/UI/toggleSwitch/ToggleSwitch";
import { useSearchParams } from "react-router-dom";
import {
  TXN_START_DATE,
  TXN_END_DATE,
  TXN_MIN_AMT,
  TXN_MAX_AMT,
  TXN_SUPPORTERS,
  TXN_PARTICIPANTS,
  TXN_RECIEPT,
  TXN_REVIEWED
} from "src/components/constant/queryConstants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { resetTransactionFilters, setTransactionUsers, setTransactionWallets } from "src/page/filtersSlice";
import { Wallet } from "src/page/superLedger/superLedgerSlice";
import { User } from "src/types/commonTypes";
import { debounce } from "lodash";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface FilterTransModalProps {
  handleClose: () => void;
}

export default function FilterTransactionModal({ handleClose }: FilterTransModalProps) {
  const [toggleValue, setToggleValue] = useState<string>(PARTICIPANT);
  const [searchParams, setSearchParams] = useSearchParams();
  const reciept = searchParams.get(TXN_RECIEPT) || null;
  const reviewed = searchParams.get(TXN_REVIEWED) || null;
  const startDate = searchParams.get(TXN_START_DATE) || "";
  const endDate = searchParams.get(TXN_END_DATE) || "";
  const minAmt = searchParams.get(TXN_MIN_AMT) || "";
  const maxAmt = searchParams.get(TXN_MAX_AMT) || "";
  const [error, setError] = useState({ Amt: "", Date: "" });

  const { users, walletList } = useAppSelector((state) => state.transaction);
  const { transaction: transactionFilters } = useAppSelector((state) => state.filters);

  const [selectedWallets, setSelectedWallets] = useState<Wallet[]>(transactionFilters.wallets);
  const [selectedParticipants, setSelectedParticipants] = useState<User[]>(transactionFilters.users.participants);
  const [selectedSupporters, setSelectedSupporters] = useState<User[]>(transactionFilters.users.supporters);
  const dispatch = useAppDispatch();

  const handleApplyFilter = () => {
    dispatch(setTransactionWallets(selectedWallets));
    if (toggleValue === PARTICIPANT) {
      dispatch(
        setTransactionUsers({
          participants: selectedParticipants,
          supporters: []
        })
      );
    } else {
      dispatch(
        setTransactionUsers({
          participants: [],
          supporters: selectedSupporters
        })
      );
    }

    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
      return;
    }

    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
      return;
    }

    setSearchParams(searchParams);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleValue = (val?: string | number | boolean) => {
    dispatch(setUsers([]));
    if (typeof val === "string") {
      setToggleValue(val);
      searchParams.delete(val === PARTICIPANT ? TXN_PARTICIPANTS : TXN_SUPPORTERS);
    }
  };

  const handleReset = () => {
    dispatch(resetTransactionFilters());
    setSelectedParticipants([]);
    setSelectedSupporters([]);
    setSelectedWallets([]);
    setSearchParams({});
    setError({ Amt: "", Date: "" });
    // handleClose();
  };

  const handleWalletInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    dispatch(fetchWalletListAction({ page: 0, status: true, searchText: input, limit: 20 }));
  }, 500);

  const handleUserInput = debounce((event: React.ChangeEvent<object>, input: string) => {
    if (input.length < 2) return;
    const page = 0;
    const filterContext = toggleValue;
    const isActive = true;

    dispatch(fetchUserListAction({ page, filterContext, isActive, searchText: input }));
  }, 500);

  useEffect(() => {
    if (Number(minAmt) > Number(maxAmt) && maxAmt !== "") {
      setError({
        ...error,
        Amt: "Maximum amount should always be larger than minimum amount"
      });
    } else {
      setError({
        ...error,
        Amt: ""
      });
    }
  }, [minAmt, maxAmt, error]);

  useEffect(() => {
    if (new Date(startDate) > new Date(endDate) && endDate !== "") {
      setError({
        ...error,
        Date: "End date should always be larger than start date"
      });
    } else {
      setError({
        ...error,
        Date: ""
      });
    }
  }, [startDate, endDate, error]);

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Filter Transactions</h5>
            <button onClick={handleCancel}>
              <CloseIcon onClick={handleCancel} />
            </button>
          </div>

          <div className={styles.filterByDate}>
            <div className={styles.head}>Filter by Date</div>
            <div className={styles.date}>
              <div className={styles.startDate}>
                <label>Start Date</label>
                <TextField
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    searchParams.set(TXN_START_DATE, e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
              <div className={styles.endDate}>
                <label>End Date</label>
                <TextField
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    searchParams.set(TXN_END_DATE, e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: "MuiInputLabel-outlined"
                    }
                  }}
                />
              </div>
            </div>
            {error.Date && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Date}
              </div>
            )}
          </div>
          <div className={styles.addUser}>
            <div className={styles.byWallet}>
              <label>By Wallet</label>

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={walletList.list}
                disableCloseOnSelect
                getOptionLabel={(option) => `${option?.description}`}
                onChange={(event, value) => {
                  setSelectedWallets(value);
                }}
                onInputChange={handleWalletInput}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.id}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option?.description}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Wallets...." />}
                value={selectedWallets}
              />
            </div>
            <div className={styles.byUser}>
              <div className={styles.byUserHead}>
                <label>By User</label>
                <ToggleSwitch
                  leftValueIn={"Participant"}
                  rightValueIn={"Supporter"}
                  leftValueOut={PARTICIPANT}
                  rightValueOut={SUPPORTER}
                  getValue={handleValue}
                  toggleBodyOver={styles.toggleBodyOver}
                  contentOver={styles.contentOver}
                  className={styles.myToggleSwitch}
                />
              </div>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={users.list}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                onChange={(event, value) => {
                  if (toggleValue === PARTICIPANT) {
                    setSelectedParticipants(value);
                  } else {
                    setSelectedSupporters(value);
                  }
                }}
                onInputChange={handleUserInput}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option?.firstName} {option?.lastName}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Users...." />}
                value={toggleValue === PARTICIPANT ? selectedParticipants : selectedSupporters}
              />
            </div>
          </div>
          <div className={styles.categoryFilter}>
            <div className={styles.byCategory}>
              <div className={styles.head}>By Reciept</div>

              <FormControl>
                <Select
                  value={reciept || "reciept"}
                  onChange={(e) => {
                    searchParams.set(TXN_RECIEPT, e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 65,
                    color: {
                      true: "",
                      false: "",
                      reciept: "grey",
                      default: "grey"
                    }[reciept ?? "default"]
                  }}
                >
                  <MenuItem value="reciept" sx={{ color: "grey" }}>
                    Reciept
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.byCategory}>
              <div className={styles.head}>By Review</div>

              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={reviewed || "review"}
                  onChange={(e) => {
                    searchParams.set(TXN_REVIEWED, e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: 65,
                    color: {
                      true: "",
                      false: "",
                      reviewed: "grey",
                      default: "grey"
                    }[reviewed ?? "default"]
                  }}
                >
                  <MenuItem value="review" sx={{ color: "grey" }}>
                    Review
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterByAmt}>
            <div className={styles.head}>Filter by Amount</div>
            <div className={styles.amt}>
              <div className={styles.min}>
                <label>Minimum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={minAmt ? Number(minAmt) : undefined}
                  onChange={(e) => {
                    searchParams.set(TXN_MIN_AMT, e.target.value);
                  }}
                />
              </div>
              <div className={styles.max}>
                <label>Maximum</label>
                <input
                  type="number"
                  placeholder="$0"
                  value={maxAmt ? Number(maxAmt) : undefined}
                  onChange={(e) => {
                    searchParams.set(TXN_MAX_AMT, e.target.value);
                  }}
                />
              </div>
            </div>
            {error.Amt && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.Amt}
              </div>
            )}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleReset}>
              Reset Filter
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
