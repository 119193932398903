import styles from "./superLedgers.module.scss";
import { Avatar } from "@mui/material";
import { unix } from "moment";
import { colors } from "src/variable/randomColor";
import { SuperLedger } from "./superLedgerSlice";

export const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Participant Name",
    dataIndex: "participantName",
    key: "participantName",
    render: (participantName: string, data: SuperLedger) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          {/* <img src={} alt="Member" /> */}
          <Avatar id="av" aria-label="Recipe" style={{ backgroundColor: colors[data.id % 10] }}>
            {/* {this.props.userName.charAt(0).toLocaleUpperCase()} */}
            {participantName.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>{data?.participantName}</div>
        </div>
      </div>
    )
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount: number) => <div>${amount}</div>
  },
  {
    title: "Transaction Type",
    dataIndex: "txnType",
    key: "txnType"
  },
  {
    title: "Wallet ID",
    dataIndex: "walletId",
    key: "walletId"
    // render: (WalletId: number) => <div>{WalletId}</div>
  },
  {
    title: "Transaction By",
    dataIndex: "transactionBy",
    key: "transactionBy"
  },
  {
    title: "Date / Time",
    dataIndex: "transactionDate",
    key: "transactionDate",
    render: (transactionDate: number) => {
      if (transactionDate === undefined) {
        return (
          <>
            <div>{"No date available"}</div>
            <div>{"No time available"}</div>
          </>
        );
      }

      const formattedDate = unix(transactionDate).format("L");
      const formattedTime = unix(transactionDate).format("LT");

      return (
        <>
          <div>{formattedDate}</div>
          <div>{formattedTime}</div>
        </>
      );
    }
  },
  {
    title: "PPAN",
    dataIndex: "ppan",
    key: "ppan"
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance: number) => <div>${balance}</div>
  },
  {
    title: "Repeat Pay Balance",
    dataIndex: "repeatPayBalance",
    key: "repeatPayBalance",
    render: (repeatPayBalance: number) => <div>${repeatPayBalance ? repeatPayBalance : 0}</div>
  }
  //   {
  //     title: "",
  //     dataIndex: "",
  //     key: "operations",
  //     render: (card: Card) => <CardEdit card={card} />
  //   }
];
