import { createBrowserRouter } from "react-router-dom";
import Users from "src/page/users/Users";
import Wallet from "src/page/wallets/Wallets";
import Transactions from "src/page/transactions/Transactions";
import UserDetail from "src/page/userDetail/UserDetail";
import WalletDetail from "src/page/walletDetail/WalletDetail";
import Dashboard from "src/page/dashboard/Dashboard";
import Login from "./auth/auth";
import { lazy } from "react";
import ResetPassword from "./auth/ResetPassword";
import Cards from "./cards/cards";
import Organisation from "./organisation/Organisation";
import OrganisationDetail from "./organisationDetail/OrganisationDetail";
import Reports from "./report/Report";
import SuperLedgers from "./superLedger/SuperLedgers";

const Layout = lazy(() => import("src/components/layout/Layout"));

const router = createBrowserRouter([
  {
    path: "/sign-in",
    element: <Login />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: "/users",
        element: <Users />
      },
      {
        path: "/wallets",
        element: <Wallet />
      },
      {
        path: "/transactions",
        element: <Transactions />
      },
      {
        path: "/users/:userId",
        element: <UserDetail />
      },
      {
        path: "/wallets/:walletId",
        element: <WalletDetail />
      },
      {
        path: "/wallets/:walletId/transactions",
        element: <Transactions />
      },
      {
        path: "/users/:userId/transactions",
        element: <Transactions />
      },
      {
        path: "/cards",
        element: <Cards />
      },
      {
        path: "/organisation",
        element: <Organisation />
      },
      {
        path: "/organisation/:organisationId",
        element: <OrganisationDetail />
      },
      {
        path: "/organisation/:organisationId/transactions",
        element: <Transactions />
      },
      {
        path: "/organisation/:organisationId/users",
        element: <Users />
      },
      {
        path: "/organisation/:organisationId/wallets",
        element: <Wallet />
      },
      {
        path: "/organisation/:organisationId/cards",
        element: <Cards />
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path: "/superLedgers",
        element: <SuperLedgers />
      }
    ]
  }
]);

export default router;
