import styles from "../budgets.module.scss";

interface TableData {
  id: number;
  budgetname: string;
  amount: number;
  isMasterItem: boolean;
  isNewRow: boolean;
}

type HandleChangeType = (_id: number, _key: keyof TableData, _value: string | number) => void;
type HandleToggleMasterItemType = (_id: number) => void;

const columns = (handleChange: HandleChangeType, handleToggleMasterItem: HandleToggleMasterItemType) => [
  // {
  //   title: "Budget Name",
  //   dataIndex: "budgetname",
  //   key: "budgetname",
  //   width: 300,
  //   render: (text: string, record: TableData) => (
  //     <div className={styles.budgetname}>
  //       <input
  //         type="text"
  //         value={record.budgetname}
  //         onChange={(e) => handleChange(record.id, "budgetname", e.target.value)}
  //         disabled={record.isMasterItem}
  //         style={{ backgroundColor: record.isMasterItem ? "#d2d2d25c" : undefined }}
  //       />
  //       <div className={styles.masterItem} onClick={() => handleToggleMasterItem(record.id)}>
  //         {record.isMasterItem ? "Enter Custom Name" : "Choose from MasterList"}
  //       </div>
  //     </div>
  //   )
  // },
  {
    title: "Budget Name",
    dataIndex: "budgetname",
    key: "budgetname",
    width: 300,
    render: (text: string, record: TableData) => (
      <div className={styles.budgetname}>
        <input
          type="text"
          value={record?.budgetname}
          onChange={(e) => handleChange(record.id, "budgetname", e.target.value)}
          disabled={record.isMasterItem}
          // style={{ backgroundColor: record.isMasterItem ? "#d2d2d25c" : undefined }}
        />
        <div className={styles.masterItem} onClick={() => handleToggleMasterItem(record.id)}>
          {record.isNewRow ? "Choose from MasterList" : null}
        </div>
      </div>
    )
  },
  {
    title: "$Amount",
    dataIndex: "amount",
    key: "amount",
    width: 300,
    render: (text: number, record: TableData) => (
      <div className={styles.amount}>
        <input
          type="number"
          inputMode="numeric"
          value={text}
          onChange={(e) => handleChange(record.id, "amount", e.target.value)}
        />
      </div>
    )
  }
];

export default columns;
