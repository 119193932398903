import TablePagination from "@mui/material/TablePagination";
import styles from "./cards.module.scss";
import SearchBar from "src/components/UI/searchBar/SearchBar";
import Button from "@mui/material/Button";
import Table from "rc-table";
import { columns } from "./cardsConfig";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchWalletListAction, getAllCardsAction, setUsersAction } from "./cardSlice";
import { Autocomplete, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { CardAccessType } from "src/types/commonTypes";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { CARD_ACCESS_TYPE, CARD_LIMIT, CARD_PAGE, CARD_STATUS } from "src/components/constant/queryConstants";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OrderCardModal from "src/components/orderCardModal/OrderCardModal";
import Modalui from "src/components/UI/modal/Modalui";
import { debounce } from "lodash";
import { Wallet } from "../superLedger/superLedgerSlice";

function Cards() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();

  const { cards, walletOptions } = useAppSelector((state) => state.cards);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [openOrderCard, setOpenOrderCard] = useState(false);

  const page = searchParams.get(CARD_PAGE) ?? "0";
  const rowsPerPage = searchParams.get(CARD_LIMIT) ?? "10";
  const cardStatus = searchParams.get(CARD_STATUS);
  const cardAccessType = searchParams.get(CARD_ACCESS_TYPE);
  const [wallet, setWallet] = useState<Wallet | null>(null);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(CARD_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(CARD_LIMIT, event.target.value as string);
    searchParams.set(CARD_PAGE, "0");
    setSearchParams(searchParams);
  };

  const handleClearFilter = () => {
    searchParams.delete(CARD_ACCESS_TYPE);
    searchParams.delete(CARD_STATUS);
    setWallet(null);

    setSearchParams(searchParams);
  };
  const fetchCardsData = useCallback(() => {
    let cardStatusValue = null;

    if (cardStatus) {
      cardStatusValue = cardStatus === "active";
    }

    let organisationId = undefined;

    if (params.organisationId) {
      organisationId = parseInt(params.organisationId);
    }

    dispatch(
      getAllCardsAction({
        page: parseInt(page),
        limit: parseInt(rowsPerPage),
        active: cardStatusValue,
        accessTypes: cardAccessType != null ? [cardAccessType as CardAccessType] : undefined,
        searchText,
        organisationId,
        walletIds: wallet != null && Number(wallet?.id) != 0 ? [Number(wallet?.id)] : []
      })
    );
  }, [cardAccessType, cardStatus, dispatch, page, rowsPerPage, searchText, location.pathname, wallet]);

  useEffect(() => {
    fetchCardsData();
  }, [fetchCardsData, location.pathname]);

  function handleCardStatusSelect(event: SelectChangeEvent): void {
    if (event.target.value != "status") {
      searchParams.set(CARD_STATUS, event.target.value);
    } else {
      searchParams.delete(CARD_STATUS);
    }
    setSearchParams(searchParams);
  }

  function handleCardTypeSelect(event: SelectChangeEvent): void {
    if (event.target.value != "type") {
      searchParams.set(CARD_ACCESS_TYPE, event.target.value.toUpperCase());
    } else {
      searchParams.delete(CARD_ACCESS_TYPE);
    }
    setSearchParams(searchParams);
  }

  function handleSearchText(value: string) {
    setSearchText(value);
  }

  function handleOrderCardModal() {
    setOpenOrderCard(!openOrderCard);
    dispatch(setUsersAction([]));
  }

  const fetchWalletOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      limit: 20,
      searchText: inputValue
    };

    dispatch(fetchWalletListAction(data));
  }, 500);
  const handleWalletInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchWalletOptions(newInputValue.toUpperCase());
  };

  return (
    <div className={styles.cardsWrapper}>
      <div className={styles.WelCard}>
        <TransactionWelCard heading={"View Your Cards"} subHeading={"View details of all the cards"} />
      </div>
      <div className={styles.cardList}>
        <div className={styles.cardListHead}>
          <div className={styles.cardListHeadIn}>
            <div className={styles.cardListHeadD}>
              <h3>Cards list</h3>
              <p>Manage all the cards</p>
            </div>
            <div className={styles.cardListHeadBtn}>
              <Button variant="contained" className={styles.clearFilter} onClick={handleClearFilter}>
                Clear Filters
              </Button>
              <div onClick={handleOrderCardModal}>
                <Button variant="contained" className={styles.orderCardBtn}>
                  <div>
                    <ShoppingCartIcon />
                  </div>
                  <div>Order Card</div>
                </Button>
              </div>
              <Modalui open={openOrderCard} handleClose={handleOrderCardModal} modaluiOver={styles.modaluiOver}>
                <OrderCardModal onSuccess={fetchCardsData} handleClose={handleOrderCardModal} />
              </Modalui>
            </div>
          </div>
          <div className={styles.cardListFilter}>
            <div className={styles.filterSearch}>
              <div className={styles.statusFilter}>
                <FormControl>
                  <Select
                    value={cardStatus === null ? "status" : cardStatus === "active" ? "active" : "inactive"}
                    onChange={handleCardStatusSelect}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 40,
                      color: {
                        active: "",
                        inactive: "",
                        cardStatus: "grey",
                        default: "grey"
                      }[cardStatus != null ? cardStatus + "" : "default"]
                    }}
                  >
                    <MenuItem value="status" sx={{ color: "grey" }}>
                      Status
                    </MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    value={cardAccessType === null ? "type" : cardAccessType === "PRIVATE" ? "private" : "shared"}
                    onChange={handleCardTypeSelect}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: 40,
                      color: {
                        SHARED: "",
                        PRIVATE: "",
                        type: "grey",
                        default: "grey"
                      }[cardAccessType ?? "default"]
                    }}
                  >
                    <MenuItem value="type" sx={{ color: "grey" }}>
                      Access Type
                    </MenuItem>
                    <MenuItem value="private">Private</MenuItem>
                    <MenuItem value="shared">Shared</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={styles.walletFilter}>
                <Autocomplete
                  options={walletOptions.list}
                  // getOptionLabel={(option) => option.email}
                  getOptionLabel={(option) => `${option?.description}`}
                  // style={{ width: 245 }}
                  value={wallet}
                  onInputChange={handleWalletInputChange}
                  onChange={(event, newValue) => {
                    if (newValue?.id) {
                      setWallet(newValue);
                    } else {
                      setWallet(null);
                    }
                    setSearchParams(searchParams);
                  }}
                  renderInput={(params) => <TextField {...params} placeholder="Wallet" variant="outlined" />}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        fontSize: "14px"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "8px"
                        }}
                      >
                        {option?.id}
                        {") "}
                        <div>
                          <div>{option?.description}</div>
                        </div>
                      </div>
                    </li>
                  )}
                  ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                />
              </div>
            </div>
            <div className={styles.cardListSearch}>
              <SearchBar onSearch={handleSearchText} />
            </div>
          </div>
        </div>
        <div className={styles.cardListTable}>
          <Table columns={columns} data={cards.list} rowKey="id" />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cards.total ?? 0}
            page={parseInt(page)}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(rowsPerPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default Cards;
