import React, { useState, useEffect } from "react";
import styles from "./UserListCard.module.scss";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./listTable/userListTable";
import Modalui from "src/components/UI/modal/Modalui";
import AddUserModal from "./addUserModal/AddUserModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchUserListAction, addUserAction, connectUserAction } from "src/page/users/usersSlice";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ToggleSwitch from "../UI/toggleSwitch/ToggleSwitch";
import {
  CAN_ADD_USER,
  CAN_CONNECT_CARD_TO_ORGANISATION,
  PARTICIPANT,
  SUPPORTER,
  reverseRoleMapping,
  roleMapping
} from "../constant/constant";
import { useParams } from "react-router-dom";
import AttachUser from "./attachUser/AttachUser";
import DeleteSettingModal from "../connectCard/connectMember/deleteSupporterModal/DeleteSupporterModal";
import LoadingModal from "../UI/loadingModal/LoadingModal";

type AddUserIn = {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
};

interface TableData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

function UserListCard() {
  const [open, setOpen] = useState(false);
  const [attachUser, setAttachUser] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState<boolean | null>(null);
  const [toggleValue, setToggleValue] = useState<string>(PARTICIPANT);
  const [role, setRole] = useState<string | null>(null);
  const [unlinkModal, setUnlinkModal] = useState(false);
  const [unlinkData, setUnlinkData] = useState({ id: 0, name: "", img: "", email: "", role: "" });
  const [searchText, setSearchText] = useState("");
  const { users, userLoading } = useAppSelector((state) => state.user);
  const { config } = useAppSelector((state) => state.auth);
  const { organisationId } = useParams();

  const dispatch = useAppDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;

    if (value === "active") {
      setStatus(true);
    } else if (value === "inactive") {
      setStatus(false);
    } else {
      setStatus(null);
    }
  };

  const handleRoleChange = (e: SelectChangeEvent<string>) => {
    if (e.target.value !== "Role") {
      setRole(e.target.value);
    } else {
      setRole(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRowsPerPage(parseInt(event.target.value as string, 10));
    setPage(0);
  };

  const handleValue = (val?: string | number | boolean) => {
    if (typeof val === "string") {
      setToggleValue(val);
    }
  };

  const handleAddUser = (userData: AddUserIn, close: boolean) => {
    const usersData = {
      ...userData,
      organisationId: Number(organisationId) || Number(config?.defaultOrganisation?.id) || null
    };

    dispatch(addUserAction(usersData)).then(() => {
      const data = {
        page,
        limit: rowsPerPage,
        searchText,
        filterContext: organisationId || config?.defaultOrganisation?.id ? role : toggleValue,
        isActive: status,
        organisationId: Number(organisationId) || Number(config?.defaultOrganisation?.id) || null
      };

      dispatch(fetchUserListAction(data));
      if (close) {
        handleClose();
      }
    });
  };

  const handleAttachUser = () => {
    setAttachUser(!attachUser);
  };

  const onAttachUser = (emailId: string, Role: string | null, close: boolean) => {
    const data = {
      emailId,
      role: Role,
      action: "LINK"
    };

    dispatch(connectUserAction(Number(organisationId || config?.defaultOrganisation?.id), data, "Connected")).then(
      () => {
        const Data = {
          page,
          limit: rowsPerPage,
          searchText,
          filterContext: organisationId || config?.defaultOrganisation?.id ? role : toggleValue,
          isActive: status,
          organisationId: Number(organisationId)
        };

        dispatch(fetchUserListAction(Data));
        if (close) {
          handleAttachUser();
        }
      }
    );
  };

  useEffect(() => {
    const data = {
      page,
      limit: rowsPerPage,
      searchText,
      filterContext: organisationId || config?.defaultOrganisation?.id ? role : toggleValue,
      isActive: status,
      organisationId: Number(organisationId) || Number(config?.defaultOrganisation?.id) || null
    };

    dispatch(fetchUserListAction(data));
  }, [
    config?.defaultOrganisation?.id,
    dispatch,
    organisationId,
    page,
    role,
    rowsPerPage,
    searchText,
    status,
    toggleValue
  ]);

  const handleUnlinkClose = () => {
    setUnlinkModal(false);
  };

  const handleUnlinkWallet = (data: TableData) => {
    console.log("dataaa", data);
    setUnlinkData({
      id: data.id,
      name: `${data.firstName} ${data.lastName}`,
      img: data.firstName?.charAt(0).toLocaleUpperCase(),
      email: data.email,
      role: data.role
    });
    setUnlinkModal(true);
  };

  let unlinkOperation;

  if (
    config?.allowedPermissions.includes(CAN_CONNECT_CARD_TO_ORGANISATION) &&
    (organisationId || config?.defaultOrganisation?.id)
  ) {
    unlinkOperation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: TableData) => (
        <div onClick={() => handleUnlinkWallet(record)} className={styles.unlinkOperation}>
          <Tooltip title="Unlink" arrow>
            <LinkOffIcon />
          </Tooltip>
        </div>
      ),
      width: 100
    };
  } else {
    unlinkOperation = {};
  }

  const updatedColumns = [...columns, unlinkOperation];

  const onUnlinkWallet = (email: string) => {
    const data = {
      emailId: email,
      role: reverseRoleMapping[unlinkData.role],
      action: "UNLINK"
    };

    dispatch(connectUserAction(Number(organisationId || config?.defaultOrganisation?.id), data, "Unlinked")).then(
      () => {
        const Data = {
          page,
          limit: rowsPerPage,
          searchText,
          filterContext: organisationId || config?.defaultOrganisation?.id ? role : toggleValue,
          isActive: status,
          organisationId: Number(organisationId)
        };

        dispatch(fetchUserListAction(Data));
      }
    );
  };

  const deleteInput = {
    buttonInfo: "Unlink",
    whomToRemove: "user's",
    headInfo: "Are you sure, you want to Unlink this user?"
  };

  return (
    <>
      <Modalui open={attachUser} handleClose={handleAttachUser}>
        <AttachUser handleClose={handleAttachUser} onConnectUser={onAttachUser} />
      </Modalui>
      <Modalui open={unlinkModal} handleClose={handleUnlinkClose}>
        <DeleteSettingModal
          img={unlinkData.img}
          name={unlinkData.name}
          email={unlinkData.email}
          supporterId={unlinkData.id}
          handleClose={handleUnlinkClose}
          onDeleteSupporter={onUnlinkWallet}
          deleteInput={deleteInput}
          modalOf={"UNLINK"}
        />
      </Modalui>
      <div className={styles.userList}>
        {userLoading ? <LoadingModal /> : ""}
        <div className={styles.userListHead}>
          <div className={styles.userListHeadIn}>
            <div className={styles.userListHeadD}>
              <h3>Users list</h3>
              <p>See information about all of your connected users</p>
            </div>
            {config?.allowedPermissions.includes(CAN_ADD_USER) && (
              <div className={styles.userListHeadBtn}>
                {config?.allowedPermissions.includes(CAN_CONNECT_CARD_TO_ORGANISATION) &&
                  (organisationId || config?.defaultOrganisation?.id) && (
                    <Button variant="outlined" className={styles.filterBtn} onClick={handleAttachUser}>
                      Connect User
                    </Button>
                  )}
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  className={styles.addUserBtn}
                  startIcon={<PersonAddAlt1Icon />}
                >
                  Add User
                </Button>
                <Modalui open={open} handleClose={handleClose}>
                  <AddUserModal handleClose={handleClose} onAddUser={handleAddUser} />
                </Modalui>
              </div>
            )}
          </div>
          <div className={styles.userListFilter}>
            <div className={styles.filterSearch}>
              {organisationId || config?.defaultOrganisation?.id ? (
                <FormControl sx={{ m: 1, margin: 0 }}>
                  <Select
                    value={role ? role : "Role"}
                    onChange={handleRoleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: 40 }}
                  >
                    <MenuItem value="Role">Role</MenuItem>
                    {config?.rolesCanBeAdded &&
                      config.rolesCanBeAdded.map((roles) => <MenuItem value={roles}>{roleMapping[roles]}</MenuItem>)}
                  </Select>
                </FormControl>
              ) : (
                <ToggleSwitch
                  leftValueIn={"Participant"}
                  rightValueIn={"Supporter"}
                  leftValueOut={PARTICIPANT}
                  rightValueOut={SUPPORTER}
                  getValue={handleValue}
                />
              )}
              <FormControl sx={{ m: 1, margin: 0 }}>
                <Select
                  value={status === null ? "status" : status ? "active" : "inactive"}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                >
                  <MenuItem value="status">Status</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={styles.userListSearch}>
              <SearchBar onSearch={handleSearch} />
            </div>
          </div>
        </div>
        <div className={styles.userListTable}>
          <Table columns={updatedColumns} data={users.list} />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}

export default UserListCard;
