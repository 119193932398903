import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styles from "./modalui.module.scss";

interface ModaluiProps {
  open: boolean;
  handleClose?: () => void;
  children: React.ReactNode;
  modaluiOver?: string;
}

export default function Modalui({ open, handleClose, children, modaluiOver }: ModaluiProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.modalShadow}
    >
      <Box className={`${styles.modalui} ${modaluiOver}`}>{children}</Box>
    </Modal>
  );
}
