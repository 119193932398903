import { createSlice } from "@reduxjs/toolkit";
import { Wallet } from "./superLedger/superLedgerSlice";
import { User } from "src/types/commonTypes";

const initialTransactionFilters: {
  wallets: Wallet[];
  users: { participants: User[]; supporters: User[] };
} = {
  wallets: [],
  users: {
    participants: [],
    supporters: []
  }
};

const initialState = {
  transaction: initialTransactionFilters
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetFilters: () => {
      return { ...initialState };
    },
    setTransactionWallets: (state, action) => {
      state.transaction.wallets = action.payload;
    },
    setTransactionUsers: (state, action) => {
      state.transaction.users = action.payload;
    },
    resetTransactionFilters: (state) => {
      state.transaction = initialTransactionFilters;
    }
  }
});

export const { setTransactionUsers, setTransactionWallets, resetFilters, resetTransactionFilters } =
  filterSlice.actions;

export default filterSlice.reducer;
