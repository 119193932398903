import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  fetchWallets,
  walletListRequest,
  fetchWalletTransaction,
  walletTransRequest
} from "src/network/services/walletService";
import { fetchPaymentRequest, TransListRequest } from "src/network/services/transactionServices";

type Wallet = {
  id: number;
  participant: { fullName: string };
  description: string;
  balance: number;
  cardBalance: number;
};
const initialWallets: Wallet[] = [];

type Transaction = {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
};

const initialTransaction: Transaction[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: { [key: string]: walletTransaction } = {};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    walletLoading: false,
    transLoading: false,
    walletTransLoading: false,
    error: null,
    topWallets: {
      wallets: initialWallets,
      total: 0
    },
    transList: {
      trans: initialTransaction,
      total: 0
    },
    walletTransaction: initialwalletTransaction
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = action.payload?.list ?? [];
      state.topWallets.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.topWallets.wallets = [];
      state.topWallets.total = 0;
      state.error = action.payload;
    },
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = {};
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  fetchWalletTransactionsStart,
  fetchWalletTransactionsSuccess,
  fetchWalletTransactionsFail
} = dashboardSlice.actions;

export const fetchTopWalletAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchTransListAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await fetchPaymentRequest(data);

      dispatch(fetchTransListSuccess(response));
    } catch (error) {
      dispatch(fetchTransListFail(error));
    }
  };
};

export const fetchWalletTransactionAction = (Data: walletTransRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletTransactionsStart());
    try {
      const response = await fetchWalletTransaction({
        ...Data,
        showTotalWalletBalance: true,
        showTotalTransactions: true,
        showTotalTransactionsWithoutReceipts: true,
        showTotalCardBalance: true
      });

      dispatch(fetchWalletTransactionsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletTransactionsFail(error));
    }
  };
};

export default dashboardSlice.reducer;
