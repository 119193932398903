import Button from "@mui/material/Button";
import styles from "./budgetDeleteModal.module.scss";
// import CloseIcon from "@mui/icons-material/Close";
// import { Avatar } from "@mui/material";
// import { colors } from "src/variable/randomColor";

interface Iprops {
  id: number | undefined;
  budgetId: number | null | undefined;
  handleClose: () => void;
  onDeleteBudget: (_id: number | undefined, _budgetId: number | null | undefined) => void;
}

export default function BudgetDeleteModal({ id, budgetId, handleClose, onDeleteBudget }: Iprops) {
  const handleDeleteBudget = () => {
    onDeleteBudget(id, budgetId);
    handleClose();
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          {/* <div className={styles.close}>
            <h5>Supporter Settings</h5>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div> */}

          <div className={styles.setting}>
            <div className={styles.settingL}>
              <h4>Do you want to remove this Budget?</h4>
            </div>
            {/* <div className={styles.settingR}>
              <div className={styles.image}>
                 <Avatar
                  id="av"
                  className={styles.av}
                  aria-label="Recipe"
                  style={{ backgroundColor: colors[supporterId % 10] }}
                >
                  {img}
                </Avatar>
              </div>
              <div className={styles.supportInfo}>
                <h6>{name}</h6>
                <p>{email}</p>
              </div>
            </div> */}
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <div className={styles.btnL}>
            {/* <Button className={styles.removeBtn} variant="outlined" onClick={handleDeleteSupporter}>
              Remove From Wallet
            </Button> */}
            <Button className={styles.reportBtn} variant="text" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          <div className={styles.btnR}>
            <Button className={styles.sendBtn} variant="contained" onClick={handleDeleteBudget}>
              Remove Budget
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
