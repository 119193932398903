import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  fetchPaymentRequest,
  TransListRequest,
  fetchPaymentData,
  fetchTransactionDetail,
  transactionDetailRequest,
  updateTransactionDetail,
  uploadPhoto,
  getPhoto
} from "src/network/services/transactionServices";
import { getUserDetail, fetchUserList, UserListRequest } from "src/network/services/UsersServices";
import { fetchWallets, walletListRequest } from "src/network/services/walletService";
import { getMasterList } from "src/network/services/budgetServices";

import swal from "sweetalert2";
import { User } from "src/types/commonTypes";

type Transaction = {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
};

const initialTransaction: Transaction[] = [];

type UserDetail = {
  id: number;
  role: string;
};

const initialUserDetail: UserDetail = {
  id: 0,
  role: ""
};

const initialUsers: User[] = [];

type Wallet = {
  id: number;
  participant: { fullName: string; role: string; firstName: string; lastName: string };
  description: string;
};
const initialWallets: Wallet[] = [];

type TransactionData = {
  id: number;
  wallet: {
    participant: { id: number; email: string; firstName: string; lastName: string; role: string };
    description: string;
    balance: number;
    status: boolean;
    createdOn: number;
    updatedOn: number;
  };
  merchantName: string;
  amount: number;
  date: number;
  balance: number;
  categoryName: string;
  receiptPresent: boolean;
  maskedCardNumber: string;
  note: string;
  purchaseMadeBy: { id: number; email: string; firstName: string; lastName: string };
  itemCategoryId: number;
  authledgerId: number;
  shouldUpdateItemCategory: boolean;
  receipts: {
    id: number;
    receiptUrl: string;
  }[];
  photos: {
    id: number;
    photoUrl: string;
  }[];
};

const initialTransDetail: TransactionData = {
  id: 0,
  wallet: {
    participant: { id: 0, email: "", firstName: "", lastName: "", role: "" },
    description: "",
    balance: 0,
    status: false,
    createdOn: 0,
    updatedOn: 0
  },
  merchantName: "",
  amount: 0,
  date: 0,
  balance: 0,
  categoryName: "",
  receiptPresent: false,
  maskedCardNumber: "",
  note: "",
  purchaseMadeBy: { id: 0, email: "", firstName: "", lastName: "" },
  itemCategoryId: 0,
  authledgerId: 0,
  shouldUpdateItemCategory: false,
  receipts: [],
  photos: []
};

type recieptPhoto = {
  data: string;
};

const initialRecieptPhoto: recieptPhoto = {
  data: ""
};

type uploadRecieptPhoto = {
  data: string;
};

const initialUploadRecieptPhoto: uploadRecieptPhoto = {
  data: ""
};

type masterList = {
  id: number;
  categoryName: string;
};

const initialMasterList: masterList[] = [];

const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    transLoading: false,
    userDetailLoading: false,
    userLoading: false,
    walletLoading: false,
    paymentDataLoading: false,
    transDetailLoading: false,
    recieptPhotoLoading: false,
    photoUploadLoading: false,
    masterListLoading: false,
    error: null,
    transList: {
      trans: initialTransaction,
      total: 0
    },
    userDetail: initialUserDetail,
    users: {
      list: initialUsers,
      total: 0
    },
    walletList: {
      list: initialWallets,
      total: 0
    },
    transDetail: initialTransDetail,
    transRecieptPhoto: initialRecieptPhoto,
    uploadRecieptPhoto: initialUploadRecieptPhoto,
    mastersList: {
      total: 0,
      list: initialMasterList
    }
  },
  reducers: {
    fetchTransListStart: (state) => {
      state.transLoading = true;
    },
    fetchTransListSuccess: (state, action) => {
      state.transLoading = false;
      state.transList.trans = action.payload?.list ?? [];
      state.transList.total = action.payload?.total ?? 0;
    },
    fetchTransListFail: (state, action) => {
      state.transLoading = false;
      state.transList.trans = [];
      state.transList.total = 0;
      state.error = action.payload;
    },
    fetchUserDetailStart: (state) => {
      state.userDetailLoading = true;
    },
    fetchUserDetailSuccess: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = action.payload ?? initialUserDetail;
    },
    fetchUserDetailFail: (state, action) => {
      state.userDetailLoading = false;
      state.userDetail = initialUserDetail;
      state.error = action.payload;
    },
    setUsers: (state, action) => {
      state.users = {
        list: action.payload,
        total: action.payload?.length || 0
      };
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    fetchPaymentDataStart: (state) => {
      state.paymentDataLoading = true;
    },
    fetchPaymentDataSuccess: (state) => {
      state.paymentDataLoading = false;
    },
    fetchPaymentDataFail: (state, action) => {
      state.paymentDataLoading = false;
      state.error = action.payload;
    },
    fetchTransDetailStart: (state) => {
      state.transDetailLoading = true;
    },
    fetchTransDetailSuccess: (state, action) => {
      state.transDetailLoading = false;
      state.transDetail = action.payload;
    },
    fetchTransDetailFail: (state, action) => {
      state.transDetailLoading = false;
      state.transDetail = initialTransDetail;
      state.error = action.payload;
    },
    updateTransDetailStart: (state) => {
      state.transDetailLoading = true;
    },
    updateTransactionDetailSuccess: (state) => {
      state.transDetailLoading = false;
    },
    updateTransactionDetailFail: (state, action) => {
      state.transDetailLoading = true;
      state.error = action.payload;
    },
    fetchRecieptPhotoStart: (state) => {
      state.recieptPhotoLoading = true;
    },
    fetchRecieptPhotoSuccess: (state, action) => {
      state.recieptPhotoLoading = false;
      state.transRecieptPhoto = action.payload;
    },
    fetchRecieptPhotoFail: (state, action) => {
      state.recieptPhotoLoading = false;
      state.transRecieptPhoto = initialRecieptPhoto;
      state.error = action.payload;
    },
    updateRecieptPhotoStart: (state) => {
      state.photoUploadLoading = true;
    },
    updateRecieptPhotoSuccess: (state, action) => {
      state.photoUploadLoading = false;
      state.uploadRecieptPhoto = action.payload;
    },
    updateRecieptPhotoFail: (state, action) => {
      state.photoUploadLoading = false;
      state.uploadRecieptPhoto = initialUploadRecieptPhoto;
      state.error = action.payload;
    },
    getMasterListStart: (state) => {
      state.masterListLoading = true;
    },
    getMasterListSuccess: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = action?.payload.total ?? 0;
      state.mastersList.list = action?.payload.list ?? [];
    },
    getMasterListFail: (state, action) => {
      state.masterListLoading = false;
      state.mastersList.total = 0;
      state.mastersList.list = [];
      state.error = action?.payload;
    }
  }
});

const {
  fetchTransListStart,
  fetchTransListSuccess,
  fetchTransListFail,
  fetchUserDetailStart,
  fetchUserDetailSuccess,
  fetchUserDetailFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchPaymentDataStart,
  fetchPaymentDataSuccess,
  fetchPaymentDataFail,
  fetchTransDetailStart,
  fetchTransDetailSuccess,
  fetchTransDetailFail,
  updateTransDetailStart,
  updateTransactionDetailSuccess,
  updateTransactionDetailFail,
  fetchRecieptPhotoStart,
  fetchRecieptPhotoSuccess,
  fetchRecieptPhotoFail,
  updateRecieptPhotoStart,
  updateRecieptPhotoSuccess,
  updateRecieptPhotoFail,
  getMasterListStart,
  getMasterListSuccess,
  getMasterListFail
} = transactionSlice.actions;

export const { setUsers } = transactionSlice.actions;

export const fetchTransListAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransListStart());
    try {
      const response = await fetchPaymentRequest(data);

      dispatch(fetchTransListSuccess(response));
    } catch (error) {
      dispatch(fetchTransListFail(error));
    }
  };
};

export const fetchUserDetailAction = (data: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDetailStart());
    try {
      const response = await getUserDetail(data);

      dispatch(fetchUserDetailSuccess(response));
    } catch (error) {
      dispatch(fetchUserDetailFail(error));
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchWalletListAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchPaymentDataAction = (data: TransListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchPaymentDataStart());
    try {
      const response = await fetchPaymentData(data);

      console.log("resss1", response);
      const url = window.URL.createObjectURL(new Blob([response as unknown as BlobPart], { type: "text/csv" }));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "payment_data.csv");
      document.body.appendChild(link);
      link.click();
      dispatch(fetchPaymentDataSuccess());
    } catch (error) {
      dispatch(fetchPaymentDataFail(error));
    }
  };
};

export const fetchTransDetailAction = (id: number) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchTransDetailStart());
    try {
      const response = await fetchTransactionDetail(id);

      dispatch(fetchTransDetailSuccess(response));
    } catch (error) {
      dispatch(fetchTransDetailFail(error));
    }
  };
};

export const updateTransDetailAction = (id: number, data: transactionDetailRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateTransDetailStart());
    try {
      await updateTransactionDetail(id, data);

      dispatch(updateTransactionDetailSuccess());
      swal.fire({
        position: "center",
        icon: "success",
        title: "Successfully saved",
        showConfirmButton: false,
        timer: 2000
      });
    } catch (error) {
      dispatch(updateTransactionDetailFail(error));
      swal.fire({
        position: "center",
        icon: "warning",
        title: "Something went Wrong",
        showConfirmButton: false,
        timer: 2000
      });
    }
  };
};

export const fetchRecieptPhotoAction = (data: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchRecieptPhotoStart());
    try {
      const response = await getPhoto(data);

      dispatch(fetchRecieptPhotoSuccess(response));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(fetchRecieptPhotoFail(error));
    }
  };
};

export const updateRecieptPhotoAction = (file: File) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateRecieptPhotoStart());
    try {
      const formData = new FormData();

      formData.append("file", file);
      const response = await uploadPhoto(formData);

      dispatch(updateRecieptPhotoSuccess(response));
    } catch (error) {
      dispatch(updateRecieptPhotoFail(error));
    }
  };
};

export const getMasterListAction = () => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getMasterListStart());

    try {
      const response = await getMasterList();

      dispatch(getMasterListSuccess(response));
    } catch (error) {
      dispatch(getMasterListFail(error));
    }
  };
};

export default transactionSlice.reducer;
