import { PaginationRequest } from "src/types/commonTypes";
import config from "src/config/Config";
import store from "src/page/store";
import { immediateToast } from "izitoast-react";

export interface reportRequest extends PaginationRequest {
  startDateInZonedDateTime: string;
  endDateInZonedDateTime: string;
  walletIds: number[];
  organisationIds: number[] | null;
  statuses: string[];
}

export const downloadReport = async (body: reportRequest) => {
  try {
    const {
      auth: { token, config: authConfig }
    } = store.getState();

    let defaultOrg = null;

    if (authConfig != null && authConfig.organisations !== undefined && authConfig.organisations !== null)
      defaultOrg = authConfig?.organisations[0];

    if (authConfig?.defaultOrganisation) defaultOrg = authConfig?.defaultOrganisation;

    const headers: { [key: string]: string } = {};

    headers["Authorization"] = `Bearer ${token}`;
    headers["Accept"] = "application/json, text/plain, */*";
    headers["Content-Type"] = "application/json";

    if (defaultOrg?.id != undefined) {
      headers["organisation_id"] = defaultOrg?.id;
    }

    const response = await fetch(`${config.SIMS_URL}/paymentrequests/transaction-statement`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: headers
    });

    const data = response.status === 200 ? await response.blob() : await response.json();

    if (response.status === 400) {
      if (data?.description) {
        immediateToast("error", { message: data.description, timeout: 2000, position: "topCenter" });
      } else {
        immediateToast("error", {
          message: "Opps, Your request is invalid! Please try again",
          timeout: 2000,
          position: "topCenter"
        });
      }
    }

    return response.status === 200 ? Promise.resolve(data) : Promise.reject(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
