import { Suspense, useEffect, useState } from "react";
import styles from "./budgets.module.scss";
import columns from "src/components/budgets/budgetTable/budgetTable";
import Table from "rc-table";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Modalui from "../UI/modal/Modalui";
import BudgetSupporterModal from "src/components/budgets/budgetSupporterModal/BudgetSupporterModal";
import { Button } from "@mui/material";
import MasterListModal from "./masterListModal/MasterListModal";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { deleteBudgetAction } from "src/page/walletDetail/walletDetailSlice";
import BudgetDeleteModal from "./budgetDeleteModal/BudgetDeleteModal";
interface TableData {
  id: number;
  budgetId: number | null;
  budgetname: string;
  itemId: number;
  amount: number;
  isMasterItem: boolean;
  isNewRow: boolean;
}

interface IBudgets {
  budgetOver?: string;
  budgetSupporter: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
  }[];
  onSave: (_data: TableData[]) => void;
}

export default function Budgets({ budgetOver, budgetSupporter, onSave }: IBudgets) {
  const dispatch = useAppDispatch();
  const {
    updateBudgetLoading,
    walletDetail: { budgets }
  } = useAppSelector((state) => state.walletDetail);

  const [open, setOpen] = useState(false);
  const [openMasterModal, setOpenMasterModal] = useState(false);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [budgetDeleteOpen, setBudgetDeleteOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<{ id: number; budgetId: number | null } | null>(null);

  useEffect(() => {
    const initialTableData = budgets.map((item) => ({
      id: item?.id,
      budgetId: item?.id,
      budgetname: item?.itemCategory?.categoryName,
      itemId: item?.itemCategory?.id,
      amount: item?.budgetAvailable,
      isMasterItem: item.itemCategory?.isMasterItem,
      isNewRow: false
    }));

    setTableData(initialTableData);
  }, [budgets]);

  const handleOpen = (id: number) => {
    setSelectedRowId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBudgetDeleteOpen = (id: number, budgetId: number | null) => {
    setSelectedRow({ id, budgetId });
    setBudgetDeleteOpen(true);
  };

  const handleBudgetDeleteClose = () => {
    setBudgetDeleteOpen(false);
  };

  const handleOpenMasterModal = (id: number) => {
    setSelectedRowId(id);
    setOpenMasterModal(true);
  };

  const handleCloseMasterModal = () => {
    setOpenMasterModal(false);
  };

  const handleAddRow = () => {
    const lastId = tableData.length > 0 ? tableData[tableData.length - 1].id : 0;
    const newId = lastId + 1;
    const newRow = {
      id: newId,
      budgetId: null,
      budgetname: "",
      itemId: 0,
      amount: 0,
      isMasterItem: false,
      isNewRow: true
    };

    setTableData([...tableData, newRow]);
    setDataChanged(true);
  };

  const handleDeleteRow = (id: number | null | undefined, budgetId: number | null | undefined) => {
    if (typeof budgetId === "number") {
      dispatch(deleteBudgetAction(budgetId)).then(() => {
        const updatedTableData = tableData.filter((row) => row.id !== id);

        setTableData(updatedTableData);
        // setDataChanged(true);
      });
    } else if (budgetId === null) {
      const updatedTableData = tableData.filter((row) => row.id !== id);

      setTableData(updatedTableData);
      // setDataChanged(true);
    }
  };

  const handleChange = (id: number, key: keyof TableData, value: string | number) => {
    const updatedTableData = tableData.map((row) => (row.id === id ? { ...row, [key]: value } : row));

    setTableData(updatedTableData);
    setDataChanged(true);
  };

  const handleToggleMasterItem = (id: number) => {
    const updatedTableData = tableData.map((row) =>
      row.id === id ? { ...row, isMasterItem: !row.isMasterItem, budgetname: "" } : row
    );

    setTableData(updatedTableData);
    setDataChanged(true);
    // if (updatedTableData.find((row) => row.id === id)?.isMasterItem) {
    handleOpenMasterModal(id);
    // }
  };

  const handleAddMasterItem = (id: number, categoryName: string, categoryId: number) => {
    const updatedTableData = tableData.map((row) =>
      row.id === id ? { ...row, budgetname: categoryName, itemId: categoryId, isMasterItem: true } : row
    );

    setTableData(updatedTableData);
    setDataChanged(true);
  };

  const handleCancelCloseMasterModal = (id: number) => {
    const updatedTableData = tableData.map((row) => (row.id === id ? { ...row, isMasterItem: false } : row));

    setTableData(updatedTableData);
    setDataChanged(true);
  };

  // const handleAddMasterItem = (masterItem: string) => {
  //   setTableData((prevTableData) => {
  //     const updatedTableData = prevTableData.map((row) =>
  //       row.id === selectedRowId ? { ...row, budgetname: masterItem } : row
  //     );

  //     setDataChanged(true);
  //     return updatedTableData;
  //   });
  // };

  const handleSave = async () => {
    setDataChanged(false);
    onSave(tableData);
  };

  const deleteIcon = {
    title: "",
    dataIndex: "delete",
    key: "operations",
    render: (delte: string, record: TableData) => (
      <DeleteOutlineIcon
        className={styles.editIcon}
        onClick={() => handleBudgetDeleteOpen(record.id, record.budgetId)}
      />
    )
  };

  const selectSupporter = {
    title: "Select Supporter",
    dataIndex: "",
    key: "operations",
    width: 150,
    render: (text: string, record: TableData) =>
      !record.isNewRow && (
        <div className={styles.edit} onClick={() => handleOpen(record.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )
  };

  const updatedColumns = [...columns(handleChange, handleToggleMasterItem), selectSupporter, deleteIcon];

  return (
    <>
      {budgetDeleteOpen && (
        <Suspense fallback={null}>
          <Modalui open={budgetDeleteOpen} handleClose={handleBudgetDeleteClose}>
            <BudgetDeleteModal
              id={selectedRow?.id}
              budgetId={selectedRow?.budgetId}
              onDeleteBudget={handleDeleteRow}
              handleClose={handleBudgetDeleteClose}
            />
          </Modalui>
        </Suspense>
      )}
      <Modalui open={open} handleClose={handleClose}>
        <BudgetSupporterModal rowId={selectedRowId} budgetSupporter={budgetSupporter} />
      </Modalui>
      <Modalui open={openMasterModal} handleClose={handleCloseMasterModal}>
        <MasterListModal
          rowId={selectedRowId}
          handleClose={handleCloseMasterModal}
          handleAddMasterItem={handleAddMasterItem}
          handleCancel={handleCancelCloseMasterModal}
        />
      </Modalui>
      <div className={`${styles.Budget} ${budgetOver}`}>
        <div className={styles.BudgetBox}>
          <div className={styles.head}>
            <div className={styles.titles}>
              <h6>Budgets</h6>
              <p>Set budgets for the wallet by limiting the spend</p>
            </div>
            <div className={styles.button}>
              <div className={styles.buttonR}>
                <AddIcon className={styles.addIcon} onClick={handleAddRow} />
              </div>
              {dataChanged && (
                <div className={styles.btn}>
                  <Button
                    className={styles.sendBtn}
                    variant="contained"
                    onClick={handleSave}
                    disabled={updateBudgetLoading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.table}>
            <Table columns={updatedColumns} data={tableData} className={styles.rcTable} />
          </div>
        </div>
      </div>
    </>
  );
}
