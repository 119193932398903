export const addFundFields = [
  {
    key: "Account Name",
    value: "Gimme IT Pty Ltd"
  },
  {
    key: "BSB",
    value: "062 692"
  },
  {
    key: "Account #",
    value: "4531 7702"
  },
  {
    key: "Description",
    value: "[Wallet ID]"
  }
];
