import styles from "./addFund.module.scss";
import { addFundFields } from "./data";

function AddFund() {
  return (
    <div className={styles.modalBody}>
      <p>Load money to your Spendable wallet by making a bank transfer to the below account details:</p>
      <div className={styles.details}>
        {addFundFields.map((field) => (
          <div key={field.key} className={styles.fRow}>
            <div className={styles.item}>{field.key}</div>
            <div className={styles.item}>:</div>
            <div className={styles.item}>
              <strong>{field.value}</strong>
            </div>
          </div>
        ))}
      </div>
      <p>Ensure you include the ID number in the description to make sure we get it to the right participant.</p>
      <p className={styles.note}>*Deposits can take 1-2 business days to process</p>
    </div>
  );
}

export default AddFund;
