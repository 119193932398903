import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { fetchWallets, walletListRequest } from "src/network/services/walletService";
import { downloadReport, reportRequest } from "src/network/services/reportServices";

type Wallet = {
  id: number;
  participant: { fullName: string; role: string; firstName: string; lastName: string };
  description: string;
};
const initialWallets: Wallet[] = [];

const reportSlice = createSlice({
  name: "reports",
  initialState: {
    walletLoading: false,
    downloadReportLoading: false,
    error: null,
    walletList: {
      list: initialWallets,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    downloadReportStart: (state) => {
      state.downloadReportLoading = true;
    },
    downloadReportSuccess: (state) => {
      state.downloadReportLoading = false;
    },
    downloadReportFail: (state, action) => {
      state.downloadReportLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  downloadReportStart,
  downloadReportSuccess,
  downloadReportFail
} = reportSlice.actions;

export const fetchWalletListAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await fetchWallets(data);

      dispatch(fetchWalletsSuccess(response));
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const downloadReportAction = (data: reportRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(downloadReportStart());
    try {
      const response = await downloadReport(data);
      const url = window.URL.createObjectURL(new Blob([response as unknown as BlobPart]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      dispatch(downloadReportSuccess());
    } catch (error) {
      dispatch(downloadReportFail(error));
    }
  };
};

export default reportSlice.reducer;
