import { useEffect } from "react";
import styles from "./Wallet.module.scss";
import WalletWelCard from "src/components/walletWelCard/WalletWelCard";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
import WalletListCard from "src/components/walletList/WalletListCard";
import { fetchWalletTransactionAction } from "./walletSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { fourPillarLogo, envelopLogo, supporterLogo, cardLogo } from "src/components/nav/logo";
import { useParams } from "react-router-dom";

export default function Wallet() {
  const { walletTransaction } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();
  const { organisationId } = useParams();

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;
  const cardDetailOver = styles.cardDetailOver;
  const cardDetailTitleOver = styles.cardDetailTitleOver;
  const cardDetailValueOver = styles.cardDetailValueOver;

  useEffect(() => {
    const data = {
      organisationId: Number(organisationId)
    };

    dispatch(fetchWalletTransactionAction(data));
  }, [dispatch, organisationId]);

  return (
    <div className={styles.wallet}>
      <div className={styles.walletWelCard}>
        <WalletWelCard />
      </div>
      {walletTransaction && (
        <div className={styles.transCards}>
          {Object.entries(walletTransaction).map(([key, transItem]) => {
            let title;
            let icon;

            if (key === "totalWalletBalance") {
              title = "Total of all wallets";
              icon = fourPillarLogo;
            } else if (key === "totalCardBalance") {
              title = "Total of all card balance";
              icon = envelopLogo;
            } else if (key === "totalSupportersConnectedWallet") {
              title = "Users connected";
              icon = supporterLogo;
            } else if (key === "totalTransactions") {
              title = "No. of transactions (30days)";
              icon = cardLogo;
            } else if (key === "totalWalletRepeatPayBalance") {
              title = "Total of balance locked for repeat pay";
              icon = cardLogo;
            }

            return (
              [
                "totalWalletBalance",
                "totalCardBalance",
                "totalWalletRepeatPayBalance",
                "totalSupportersConnectedWallet",
                "totalTransactions"
              ].includes(key) &&
              transItem && (
                <WalletTransCard
                  key={transItem.id}
                  icon={icon}
                  title={title}
                  value={
                    title &&
                    [
                      "Total of all wallets",
                      "Total of all card balance",
                      "Total of balance locked for repeat pay"
                    ].includes(title)
                      ? `$${Number(transItem.current).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : transItem.current
                  }
                  WalletTransCardClass={WalletTransCardOver}
                  balCardClass={balCardOver}
                  cardDetailClass={cardDetailOver}
                  cardDetailTitleClass={cardDetailTitleOver}
                  cardDetailValueClass={cardDetailValueOver}
                />
              )
            );
          })}
        </div>
      )}
      <div className={styles.walletListtable} style={{ marginTop: !walletTransaction ? "20px" : "" }}>
        <WalletListCard />
      </div>
    </div>
  );
}
