import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import styles from "./transactionEditModal.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css";
// eslint-disable-next-line import/default
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// eslint-disable-next-line import/default
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// eslint-disable-next-line import/default
import FilePondPluginGetFile from "filepond-plugin-get-file";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { FilePond, registerPlugin } from "react-filepond";
import { Avatar, CircularProgress } from "@mui/material";
import { fetchTransDetailAction, updateTransDetailAction } from "src/page/transactions/transactionSlice";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { colors } from "src/variable/randomColor";
import { getPhoto, uploadPhoto } from "src/network/services/transactionServices";
import { ActualFileObject, FilePondFile, FilePondInitialFile } from "filepond";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginGetFile);

interface Props {
  transaction: number;
  handleClose: () => void;
}

export default function TransactionEditModal({ transaction, handleClose }: Props) {
  const { transDetail, transDetailLoading } = useAppSelector((state) => state.transaction);
  const dispatch = useAppDispatch();
  const { mastersList } = useAppSelector((state) => state.transaction);
  const [selectedMasterItem, setSelectedMasterItem] = useState<string>("");
  const [selectedMasterId, setSelectedMasterId] = useState<number>(0);
  const [note, setNote] = useState<string>(transDetail.note);
  const [receipts, setReceipts] = useState<(FilePondInitialFile | ActualFileObject)[]>([]);
  const [photos, setPhotos] = useState<(FilePondInitialFile | ActualFileObject)[]>([]);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  console.log(selectedMasterItem);
  console.log(selectedMasterId);

  const handleUpdateReceipts = (fileItems: FilePondFile[]) => {
    const updatedReceipts = fileItems.map((file) => {
      const id = file.getMetadata("id");

      if (id === undefined) {
        return file.file;
      } else {
        return {
          source: file.getMetadata("source"),
          options: {
            type: "local",
            metadata: {
              id,
              receiptUrl: file.getMetadata("receiptUrl"),
              source: file.getMetadata("source")
            }
          }
        } as FilePondInitialFile;
      }
    });

    setReceipts(updatedReceipts);
  };

  const handleUpdatePhotos = (fileItems: FilePondFile[]) => {
    const updatedPhotos = fileItems.map((file) => {
      const id = file.getMetadata("id");

      if (id === undefined) {
        return file.file;
      } else {
        return {
          source: file.getMetadata("source"),
          options: {
            type: "local",
            metadata: {
              id,
              photoUrl: file.getMetadata("photoUrl"),
              source: file.getMetadata("source")
            }
          }
        } as FilePondInitialFile;
      }
    });

    setPhotos(updatedPhotos);
  };

  const formatDateAndTime = (ogDate: number) => {
    const dateValue = new Date(ogDate * 1000);
    const date = dateValue.toLocaleDateString();

    const time = dateValue.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit"
    });

    return { date, time };
  };

  const { date: formattedDate, time: formattedTime } = formatDateAndTime(transDetail.date);

  useEffect(() => {
    dispatch(fetchTransDetailAction(transaction));
    // dispatch(getMasterListAction());
  }, [dispatch, transaction]);

  useEffect(() => {
    if (transDetail) {
      setNote(transDetail.note || "");
    }
  }, [transDetail]);

  useEffect(() => {
    if (transDetail.receipts) {
      Promise.all(
        transDetail.receipts.map(async (receipt) => {
          const imgUrl = await getPhoto(receipt.receiptUrl);

          return {
            source: imgUrl.data as string,
            options: {
              type: "local",
              metadata: {
                id: receipt.id,
                receiptUrl: receipt.receiptUrl,
                source: imgUrl.data as string
              }
            }
          } as FilePondInitialFile;
        })
      ).then((imgs) => {
        setReceipts(imgs);
      });
    }
    if (transDetail.photos) {
      Promise.all(
        transDetail.photos.map(async (photo) => {
          const imgUrl = await getPhoto(photo.photoUrl);

          return {
            id: photo.id,
            photoUrl: photo.photoUrl,
            source: imgUrl.data as string,
            options: {
              type: "local",
              metadata: {
                id: photo.id,
                photoUrl: photo.photoUrl,
                source: imgUrl.data as string
              }
            }
          } as FilePondInitialFile;
        })
      ).then((imgs) => {
        setPhotos(imgs);
      });
    }
  }, [dispatch, transDetail]);

  const handleSaveNotes = async () => {
    setUploadLoading(true);
    const updatedReceipts = await Promise.all(
      receipts.map(async (receipt) => {
        if ("source" in receipt) {
          return {
            id: receipt.options.metadata?.id,
            receiptUrl: receipt.options.metadata?.receiptUrl
          };
        } else {
          const formData = new FormData();

          formData.append("file", receipt);

          const { data } = await uploadPhoto(formData);

          return {
            id: null,
            receiptUrl: data
          };
        }
      })
    );

    const updatedPhotos = await Promise.all(
      photos.map(async (photo) => {
        if ("source" in photo) {
          return {
            id: photo.options.metadata?.id,
            photoUrl: photo.options.metadata?.photoUrl
          };
        } else {
          const formData = new FormData();

          formData.append("file", photo);

          const { data } = await uploadPhoto(formData);

          return {
            id: null,
            photoUrl: data
          };
        }
      })
    );

    setUploadLoading(false);
    const request = {
      note,
      itemCategoryId: selectedMasterId || transDetail.itemCategoryId,
      authledgerId: transDetail.authledgerId,
      receipts: updatedReceipts,
      photos: updatedPhotos
    };

    dispatch(updateTransDetailAction(transaction, request)).then(() => {
      handleClose();
    });
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <div className={styles.topL}>
          <p>
            Transaction Id: {transDetail?.id}
            <br />
            <span>
              {formattedDate} &nbsp; {formattedTime}
            </span>
          </p>
        </div>
        <div className={styles.topR}>
          <Button className={styles.closeBtn} variant="contained" onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
      {transDetailLoading || uploadLoading ? (
        <div className={styles.progress}>
          <CircularProgress sx={{ color: "#0f172a" }} />
        </div>
      ) : (
        <>
          <div className={styles.referModalBottom} id={styles.scrollBar}>
            <form id={styles.scrollBarH}>
              <div className={styles.bottomI}>
                <div className={styles.Amount}>
                  <span className={styles.title}>Amount</span>
                  <span className={styles.input}>${transDetail?.amount}</span>
                </div>
                <div className={styles.Merchant}>
                  <span className={styles.title}>Merchant</span>
                  <span className={styles.input}>{transDetail?.merchantName}</span>
                </div>
              </div>
              <div className={styles.bottomII}>
                <div className={styles.Wallet}>
                  <span className={styles.title}>Wallet</span>
                  <div className={styles.input}>
                    <div className={styles.inputL}>
                      <Avatar
                        id="av"
                        aria-label="Recipe"
                        style={{ backgroundColor: colors[(transDetail?.wallet?.participant?.id || 0) % 10] }}
                      >
                        {transDetail?.wallet?.participant?.firstName?.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className={styles.inputR}>
                      <span className={styles.name}>
                        {transDetail?.wallet?.participant?.firstName} {transDetail?.wallet?.participant?.lastName}
                      </span>
                      <span className={styles.walletName} style={{ color: "#64748B" }}>
                        {transDetail?.wallet?.description}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.Category}>
                  <span className={styles.title}>Category</span>
                  <div className={styles.input}>
                    {transDetail.shouldUpdateItemCategory ? (
                      <Autocomplete
                        options={mastersList.list}
                        getOptionLabel={(option) => option.categoryName}
                        value={mastersList.list.find((option) => option.id === transDetail.itemCategoryId)}
                        onChange={(event, newValue) => {
                          setSelectedMasterItem(newValue?.categoryName || "");
                          setSelectedMasterId(newValue?.id || 0);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" placeholder="Miscellaneous" />
                        )}
                      />
                    ) : (
                      <span>{transDetail.categoryName}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.bottomIII}>
                <div className={styles.purchasedBy}>
                  <span className={styles.title}>Purchase made by</span>
                  <div className={styles.input}>
                    <div className={styles.inputL}>
                      <Avatar
                        id="av"
                        aria-label="Recipe"
                        style={{ backgroundColor: colors[(transDetail?.purchaseMadeBy?.id || 0) % 10] }}
                      >
                        {transDetail?.purchaseMadeBy?.firstName?.charAt(0).toLocaleUpperCase()}
                      </Avatar>
                    </div>
                    <div className={styles.inputR}>
                      <span className={styles.name}>
                        {transDetail?.purchaseMadeBy?.firstName} {transDetail?.purchaseMadeBy?.lastName}
                      </span>
                      <span className={styles.email} style={{ color: "#64748B" }}>
                        {transDetail?.purchaseMadeBy?.email}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.cardUsed}>
                  <span className={styles.title}>Card Used</span>
                  <span className={styles.input}>{transDetail?.maskedCardNumber}</span>
                </div>
              </div>
              <div className={styles.dropNdrag}>
                <div className={styles.bottomIV}>
                  <div className={styles.receipt}>
                    <div className={styles.title}>Receipt</div>
                    <div className={styles.filepondWrapper}>
                      <FilePond
                        files={receipts}
                        server={{
                          load: (source, load) => {
                            console.log(source);
                            const myRequest = new Request(source);

                            fetch(myRequest).then(function (response) {
                              response.blob().then(function (myBlob) {
                                load(myBlob);
                              });
                            });
                          }
                        }}
                        allowBrowse={true}
                        onupdatefiles={handleUpdateReceipts}
                        allowMultiple={true}
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        credits={false}
                        styleItemPanelAspectRatio="1"
                        acceptedFileTypes={["image/jpeg", "image/png", "image/heic", "image/jpg"]}
                        className={styles.filePond}
                        labelInvalidField="Invalid file format. Please upload an image file (JPEG, PNG, HEIC)."
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.bottomV}>
                  <div className={styles.photo}>
                    <div className={styles.title}>Photos</div>
                    <div className={styles.filepondWrapper}>
                      <FilePond
                        files={photos}
                        server={{
                          load: (source, load) => {
                            console.log(source);
                            const myRequest = new Request(source);

                            fetch(myRequest).then(function (response) {
                              response.blob().then(function (myBlob) {
                                load(myBlob);
                              });
                            });
                          }
                        }}
                        onupdatefiles={handleUpdatePhotos}
                        allowMultiple={true}
                        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        credits={false}
                        styleItemPanelAspectRatio="1"
                        acceptedFileTypes={["image/jpeg", "image/png", "image/heic", "imgage/jpg"]}
                        labelInvalidField="Invalid file format. Please upload an image file (JPEG, PNG, HEIC)."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.bottomVI}>
                <span className={styles.title}>Notes</span>
                <textarea
                  className={styles.input}
                  placeholder="Type your comments..."
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </form>
          </div>

          <div className={styles.footer}>
            <div className={styles.saveBtn}>
              <Button
                variant="contained"
                className={styles.requestBtn}
                onClick={handleSaveNotes}
                disabled={uploadLoading || transDetailLoading}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
