import React, { useState } from "react";
import styles from "./toggleSwitch.module.scss";

interface toggleSwitchProp {
  leftValueIn?: string | number;
  rightValueIn?: string | number;
  leftValueOut?: string | number;
  rightValueOut?: string | number;
  toggleBodyOver?: string;
  contentOver?: string;
  className?: string;
  getValue: (_value: string | number | boolean) => void;
}

export default function ToggleSwitch({
  leftValueIn,
  rightValueIn,
  leftValueOut,
  rightValueOut,
  getValue,
  toggleBodyOver,
  contentOver,
  className
}: toggleSwitchProp) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const value = {
    isDarkMode: isDarkMode ? `${leftValueOut}` : `${rightValueOut}`
  };

  const handleColorModeChange = () => {
    setIsDarkMode(!isDarkMode);
    if (getValue) {
      getValue(value.isDarkMode);
    }
  };

  return (
    <div className={`${styles.btnContainer} ${className}`} onClick={handleColorModeChange}>
      <label className={`${styles.switch}  ${styles.btnColorModeSwitch} ${contentOver}`}>
        <input type="checkbox" name="color_mode" id={styles.colorMode} value="1" checked={isDarkMode} readOnly />
        <label
          htmlFor="color_mode"
          data-on={rightValueIn}
          data-off={leftValueIn}
          className={`${styles.btnColorModeSwitchInner} ${toggleBodyOver}`}
        ></label>
      </label>
      {/* <p>Current mode: {value.isDarkMode}</p> */}
    </div>
  );
}
