// import randomColor from "src/variable/randomColor";
import styles from "./personWelCard.module.scss";
import { Avatar } from "@mui/material";
import { colors } from "src/variable/randomColor";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch, { SwitchProps } from "@mui/material/Switch";
// import { styled } from "@mui/material/styles";

// const IOSSwitch = styled((props: SwitchProps & { checked?: boolean }) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 35,
//   height: 20,
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,
//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0f172a",
//         opacity: 1,
//         border: 0
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5
//       }
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff"
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3
//     }
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 15,
//     height: 16
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === "light" ? "#0f172a5e" : "#39393D",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500
//     })
//   }
// }));

type Iprops = {
  id?: number;
  img: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email: string;
  connectedTo?: string;
  welCardLOver?: string;
  status?: boolean;
};

export default function PersonWelCard({ img, firstName, lastName, name, email, id, welCardLOver }: Iprops) {
  // const [userStatus, setUserStatus] = useState(status);

  // const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setUserStatus(event.target.checked);
  // };

  return (
    <div className={styles.welCard}>
      <div className={styles.welCardL}>
        <div className={styles.userImage}>
          <Avatar
            id="av"
            className={`${styles.av} ${welCardLOver}`}
            aria-label="Recipe"
            style={{ backgroundColor: colors[(id || 0) % 10] }}
          >
            {img}
          </Avatar>
        </div>
        <div className={styles.userName}>
          <h3>
            {firstName} {lastName}
          </h3>
          <h3>{name}</h3>
          <p>
            {email} | ID: {id}
          </p>
          {/* {connectedTo === "Users" && (
            <FormGroup className={styles.formGroup}>
              <FormControlLabel
                // key={[]}
                className={styles.formControlLabel}
                control={<IOSSwitch sx={{ m: 1 }} checked={userStatus} onChange={handleToggle} />}
                label="Active"
              />
            </FormGroup>
          )} */}
        </div>
      </div>
      <div className={styles.welCardR}>
        {/* <Button variant="contained" className={styles.cancel}>
          Cancel
        </Button>
        <Button variant="contained" className={styles.save}>
          Save
        </Button> */}
      </div>
    </div>
  );
}
