import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./masterListModal.module.scss";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getMasterListAction } from "src/page/walletDetail/walletDetailSlice";

interface MasterListModalProps {
  rowId: number;
  handleClose: () => void;
  handleAddMasterItem: (_rowId: number, _masterItem: string, _masterId: number) => void;
  handleCancel: (_rowId: number) => void;
}

export default function MasterListModal({
  rowId,
  handleClose,
  handleAddMasterItem,
  handleCancel
}: MasterListModalProps) {
  const dispatch = useAppDispatch();
  const { mastersList } = useAppSelector((state) => state.walletDetail);
  const [selectedMasterItem, setSelectedMasterItem] = useState<string>("");
  const [selectedMasterId, setSelectedMasterId] = useState<number>(0);

  useEffect(() => {
    dispatch(getMasterListAction());
  }, [dispatch]);

  const handleAdd = () => {
    if (selectedMasterItem) {
      handleAddMasterItem(rowId, selectedMasterItem, selectedMasterId);
      handleClose();
    }
  };

  const handleCancelClose = () => {
    handleCancel(rowId);
    handleClose();
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Master list </h5>
          </div>
          <div>
            <Autocomplete
              options={mastersList.list}
              getOptionLabel={(option) => option.categoryName}
              onChange={(event, newValue) => {
                setSelectedMasterItem(newValue?.categoryName || "");
                setSelectedMasterId(newValue?.id || 0);
              }}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Item name" />}
            />
          </div>
        </form>
      </div>

      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="outlined" onClick={handleCancelClose}>
              Cancel
            </Button>
            <Button
              className={styles.sendBtn}
              variant="contained"
              onClick={handleAdd}
              disabled={!selectedMasterItem}
              style={{
                backgroundColor: !selectedMasterItem ? "#9f9f9f" : undefined,
                color: !selectedMasterItem ? "white" : undefined
              }}
            >
              Add
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
