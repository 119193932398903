import Button from "@mui/material/Button";
import styles from "./welcomeCard.module.scss";
import { useState } from "react";
import WalletSvg from "./WalletSvg";
import Modalui from "../UI/modal/Modalui";
import AddFund from "../addFund/AddFund";

interface WelcomeCardProps {
  name?: string;
}

function WelcomeCard({ name = "" }: WelcomeCardProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleAddFundClick = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className={styles.welcomeCardBox}>
      <div className={styles.welcomeMessage}>
        <div className={styles.welcomeMessageIn}>
          <h1>
            Hello <span>{name}</span>
          </h1>
          <p>Welcome to the SpendAble Budget Hub</p>
        </div>
        <div className={styles.welcomeBtn}>
          <div className={styles.notification}>
            {/* <div>
              <Button variant="contained">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <circle cx="10" cy="10.5" r="4" fill="#FF6B00" />
                </svg>
                Notification
              </Button>
            </div> */}
          </div>
          <div className={styles.addFund}>
            <Modalui open={isModalOpen} handleClose={handleAddFundClick} modaluiOver={styles.modaluiOver}>
              <AddFund />
            </Modalui>
            <div>
              <Button variant="contained" onClick={handleAddFundClick}>
                <WalletSvg />
                Add Funds
              </Button>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
    </div>
  );
}

export default WelcomeCard;
