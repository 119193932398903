import { createSlice } from "@reduxjs/toolkit";
import { fetchSuperledgers, superledgerRequest } from "src/network/services/superLedgerServices";
import { StoreDispatch } from "../store";
import { fetchWallets, walletListRequest } from "src/network/services/walletService";
import { UserListRequest, fetchUserList } from "src/network/services/UsersServices";

export type SuperLedger = {
  id: number;
  balance: number;
  txnType: string;
  ppan: string;
  participantName: string;
  walletId: number;
  transactionBy: string;
  transactionDate: number;
  amount: number;
  repeatPayBalance: number;
};

export type Wallet = {
  id: number;
  description: string;
  participant: { fullName: string };
};

export type UserList = {
  id: number;
  fullName: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
};

const initialSuperledger: SuperLedger[] = [];
const initialWalletOptions: Wallet[] = [];
const initialUsers: UserList[] = [];

const superledgerSlice = createSlice({
  name: "superLedger",
  initialState: {
    superledgerLoading: false,
    walletOptionLoading: false,
    userOptionLoading: false,
    error: null,
    superledger: {
      list: initialSuperledger,
      total: 0
    },
    wallets: {
      list: initialWalletOptions
    },
    users: {
      list: initialUsers
    }
  },
  reducers: {
    fetchSuperledgerStart: (state) => {
      state.superledgerLoading = true;
      state.error = null;
    },
    fetchSuperledgerSuccess: (state, action) => {
      state.superledgerLoading = false;
      state.error = null;
      state.superledger = action.payload;
    },
    fetchSuperledgerFail: (state, action) => {
      state.superledgerLoading = false;
      state.error = action.payload;
    },
    fetchWalletOptionStart: (state) => {
      state.walletOptionLoading = true;
      state.error = null;
    },
    fetchWalletOptionSuccess: (state, action) => {
      state.walletOptionLoading = false;
      state.error = null;
      state.wallets = action.payload;
    },
    fetchWalletOptionFail: (state, action) => {
      state.walletOptionLoading = false;
      state.error = action.payload;
    },
    fetchUserOptionStart: (state) => {
      state.userOptionLoading = true;
      state.error = null;
    },
    fetchUserOptionSuccess: (state, action) => {
      state.userOptionLoading = false;
      state.error = null;
      state.users = action.payload;
    },
    fetchUserOptionFail: (state, action) => {
      state.userOptionLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchSuperledgerStart,
  fetchSuperledgerSuccess,
  fetchSuperledgerFail,
  fetchWalletOptionStart,
  fetchWalletOptionSuccess,
  fetchWalletOptionFail,
  fetchUserOptionStart,
  fetchUserOptionSuccess,
  fetchUserOptionFail
} = superledgerSlice.actions;

export const fetchSuperledgerAction = (data: superledgerRequest) => {
  return async (dispatch: StoreDispatch) => {
    try {
      dispatch(fetchSuperledgerStart());
      const response = await fetchSuperledgers(data);

      dispatch(fetchSuperledgerSuccess(response));
    } catch (error) {
      dispatch(fetchSuperledgerFail(error));
    }
  };
};

export const fetchWalletListAction = (data: walletListRequest) => {
  return async (dispatch: StoreDispatch) => {
    try {
      dispatch(fetchWalletOptionStart());
      const response = await fetchWallets(data);

      dispatch(fetchWalletOptionSuccess(response));
    } catch (error) {
      dispatch(fetchWalletOptionFail(error));
    }
  };
};

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserOptionStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUserOptionSuccess(response));
    } catch (error) {
      dispatch(fetchUserOptionFail(error));
    }
  };
};
export default superledgerSlice.reducer;
