import api from "src/network/api";
import { PaginationRequest } from "src/types/commonTypes";

export interface TransListRequest extends PaginationRequest {
  status?: boolean;
  receiptPresent?: boolean | null;
  isReviewed?: boolean | null;
  walletIds?: number[] | null;
  participantIds?: number[] | null;
  supporterIds?: number[] | null;
  organisationIds?: number[] | null;
  startDateInZonedDateTime?: string | null;
  endDateInZonedDateTime?: string | null;
  minRequestedAmount?: number | null;
  maxRequestedAmount?: number | null;
  searchText?: string;
  statuses?: string[];
}

export const fetchPaymentRequest = async (data: TransListRequest) => {
  return api.post("/paymentrequests/search", data);
};

export interface repeatPayRequest {
  walletId: number;
  amountToSet?: number | null;
  repeatpay: boolean;
  loadCard: boolean;
  useWholeBalance?: boolean;
}

export const fetchRepeatPayRequest = async (data: repeatPayRequest) => {
  return api.post("paymentrequests/initiate", data);
};

export const fetchPaymentData = async (data: TransListRequest) => {
  return api.post("/paymentrequests/search/download", data);
};

export const fetchTransactionDetail = async (id: number) => {
  return api.get(`/paymentrequests/${id}`);
};

export interface transactionDetailRequest {
  note: string;
  itemCategoryId: number;
  authledgerId: number;
  receipts: {
    id: number | null;
    receiptUrl: string;
  }[];
  photos: {
    id: number | null;
    photoUrl: string;
  }[];
}
export const updateTransactionDetail = async (id: number, data: transactionDetailRequest) => {
  return api.put(`/paymentrequests/${id}`, data);
};

export const getPhoto = async (param: string) => {
  return api.get(`/file?fileName=${param}`);
};

export const uploadPhoto = async (file: FormData) => {
  return api.post("/file/upload", file, {
    headers: {
      "Content-Type": "form-data"
    }
  });
};
