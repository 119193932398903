import { useState, useEffect } from "react";
import styles from "./dashboard.module.scss";
import { Link } from "react-router-dom";
import WalletCard from "../../components/walletCard/WalletCard";
import WalletTransCard from "../../components/walletTransCard/WalletTransCard";
import RecentTrans from "../../components/recentTrans/RecentTrans";
// import BalGraph from "../../components/balGraph/BalGraph";   // No need to remove this
import WelcomeCard from "../../components/welcomeCard/WelcomeCard";
import SearchBar from "src/components/UI/searchBar/SearchBar";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { fetchTopWalletAction, fetchTransListAction, fetchWalletTransactionAction } from "./dashboardSlice";
import { colors } from "src/variable/randomColor";
import { walletTransCardLogo, whiteCardLogo, whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";

function Dashboard() {
  const [searchWalletText, setSearchWalletText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { userInfo, config } = useAppSelector((state) => state.auth);
  const { topWallets, transList, walletTransaction } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const handleWalletSearch = (value: string) => {
    setSearchWalletText(value);
  };

  useEffect(() => {
    const data = {
      page: 0,
      limit: 5,
      status: true,
      searchText: searchWalletText,
      organisationId: Number(config?.defaultOrganisation?.id) || null
    };

    dispatch(fetchTopWalletAction(data));
  }, [dispatch, searchWalletText, config?.defaultOrganisation]);

  useEffect(() => {
    dispatch(
      fetchTransListAction({
        page: 0,
        limit: 5,
        status: true,
        receiptPresent,
        isReviewed,
        statuses: ["Success", "Complete", "Hold"]
      })
    );
  }, [dispatch, receiptPresent, isReviewed]);

  useEffect(() => {
    const data = {
      organisationId: Number(config?.defaultOrganisation?.id) || null
    };

    dispatch(fetchWalletTransactionAction(data));
  }, [dispatch, config?.defaultOrganisation]);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.welcomeCard}>
        <WelcomeCard name={userInfo?.fullName} />
      </div>
      <div className={styles.topWallet}>
        {topWallets?.total > 0 && (
          <div className={styles.topWalletHead}>
            <div className={styles.topWalletHeadIn}>
              <div className={styles.topWalletHeadL}>
                <h1>Top Wallets</h1>
                <div className={styles.walletSearch}>
                  <SearchBar onSearch={handleWalletSearch} />
                </div>
              </div>
              <div className={styles.topWalletHeadR}>
                <Link to="/wallets">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#1e293b",
                      height: "34px",
                      width: "82px",
                      fontSize: "13px",
                      fontWeight: "600",
                      lineHeight: "normal",
                      margin: "0",
                      padding: "0"
                    }}
                  >
                    View All
                  </Button>
                </Link>
              </div>
            </div>
            <div className={styles.walletCards}>
              {topWallets.wallets.map((wallet) => (
                <Link to={`/wallets/${wallet.id}`}>
                  <WalletCard
                    key={wallet.id}
                    avatar={wallet.participant.fullName?.charAt(0).toLocaleUpperCase()}
                    title={wallet.participant.fullName}
                    subtitle={wallet.description}
                    walletBalance={wallet.balance}
                    cardBalance={wallet.cardBalance}
                    bg={colors[wallet.id % 10]}
                  />
                </Link>
              ))}
            </div>
          </div>
        )}
        <div className={styles.transCards}>
          {Object.entries(walletTransaction).map(([key, transItem]) => {
            /////
            // let title = key.split(/(?=[A-Z])/).join(" "); // Convert camelCase to sentence

            // title = title.charAt(0).toUpperCase() + title.slice(1); // Capitalize the first character
            /////
            let title;
            let icon;

            if (key === "totalWalletBalance") {
              title = "Total Wallet Balances";
              icon = walletTransCardLogo;
            } else if (key === "totalTransactions") {
              title = "Transactions (30d)";
              icon = whiteFourPillarLogo;
            } else if (key === "totalTransactionsWithoutReceipts") {
              title = "Trans. without recipt (30d)";
              icon = receiptLogo;
            } else if (key === "totalCardBalance") {
              title = "Total Card Balance";
              icon = whiteCardLogo;
            }

            return (
              [
                "totalWalletBalance",
                "totalTransactions",
                "totalTransactionsWithoutReceipts",
                "totalCardBalance"
              ].includes(key) &&
              transItem && (
                <WalletTransCard
                  key={key}
                  icon={icon}
                  title={title}
                  // value={title === "Total Wallet Balances" ? `$${transItem.current}` : transItem.current}
                  value={
                    title && ["Total Wallet Balances", "Total Card Balance"].includes(title)
                      ? `$${Number(transItem.current).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : transItem.current
                  }
                  growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                  growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                  from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                />
              )
            );
          })}
        </div>
      </div>
      <div className={styles.DashTrans}>
        <div className={styles.recTransTable}>
          <RecentTrans
            transList={transList.trans}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made"}
            page={"Dashboard"}
          />
        </div>
        {/* WORK ON THIS IN FUTURE */}
        {/* <div className={styles.balGraph}>
          <BalGraph />
        </div> */}
      </div>
    </div>
  );
}

export default Dashboard;
