import { ChangeEvent, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./addOrgModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export type orgList = {
  id: number | null;
  name: string;
  abn: string;
  address: string;
  phone: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referencePhone: string;
  referenceEmail: string;
  active: boolean;
};

interface AddOrgModalProps {
  handleClose: () => void;
  onAddOrg: (_input: orgList) => void;
  orgInfo?: orgList;
}

export default function AddOrgModal({ handleClose, onAddOrg, orgInfo }: AddOrgModalProps) {
  const [input, setInput] = useState({
    id: orgInfo?.id || null,
    name: orgInfo?.name || "",
    abn: orgInfo?.abn || "",
    address: orgInfo?.address || "",
    phone: orgInfo?.phone || "",
    website: orgInfo?.website || "",
    facebook: orgInfo?.facebook || "",
    linkedIn: orgInfo?.linkedIn || "",
    instagram: orgInfo?.instagram || "",
    referenceName: orgInfo?.referenceName || "",
    referenceRole: orgInfo?.referenceRole || "",
    referencePhone: orgInfo?.referencePhone || "",
    referenceEmail: orgInfo?.referenceEmail || "",
    active: orgInfo?.active || true
  });
  const [error, setError] = useState({
    name: "",
    abn: "",
    address: "",
    phone: "",
    website: "",
    referenceName: "",
    referenceRole: "",
    referencePhone: "",
    referenceEmail: ""
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const handleAddOrg = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    if (
      !input.name.trim() ||
      !input.abn.trim() ||
      !input.address.trim() ||
      !input.phone.trim() ||
      !input.website.trim() ||
      !input.referenceName.trim() ||
      !input.referenceRole.trim() ||
      !input.referenceEmail.trim() ||
      !input.referencePhone.trim()
    ) {
      setError({
        name: input.name.trim() ? "" : "Company name is required",
        abn: input.abn.trim() ? "" : "Company's Abn is required",
        address: input.address.trim() ? "" : "Company's address is required",
        phone: input.phone.trim() ? "" : "Company's phone is required",
        website: input.website.trim() ? "" : "Company's website is required",
        referenceName: input.referenceName.trim() ? "" : "Name is required",
        referenceEmail: input.referenceEmail.trim() ? "" : "Email name is required",
        referenceRole: input.referenceRole.trim() ? "" : "Role is required",
        referencePhone: input.referencePhone.trim() ? "" : "Phone number is required"
      });
      return;
    }

    if (input.abn.length < 11) {
      setError({
        ...error,
        abn: "ABN number should be equal to 11 digits"
      });
      return;
    }

    if (!emailRegex.test(input.referenceEmail)) {
      setError({
        ...error,
        referenceEmail: "Invalid email format"
      });
      return;
    }

    onAddOrg(input);
    setInput({
      id: null,
      name: "",
      abn: "",
      address: "",
      phone: "",
      website: "",
      facebook: "",
      linkedIn: "",
      instagram: "",
      referenceName: "",
      referenceRole: "",
      referencePhone: "",
      referenceEmail: "",
      active: true
    });
    setError({
      name: "",
      abn: "",
      address: "",
      phone: "",
      website: "",
      referenceName: "",
      referenceRole: "",
      referencePhone: "",
      referenceEmail: ""
    });
    handleClose();
  };

  return (
    <div className={styles.rootContainer}>
      <div className={styles.modalTop}>
        <form>
          <div className={styles.generalCompany}>
            <div className={styles.close}>
              <h5>General Company Info</h5>
              <button onClick={handleCancelClose}>
                <CloseIcon onClick={handleCancelClose} />
              </button>
            </div>
            <div className={styles.row}>
              <div className={styles.companyName}>
                <label>Company Name :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.name}
                    name="name"
                    onChange={handleInput}
                  />
                  {error.name && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.name}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.abnName}>
                <label>ABN :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    maxLength={11}
                    value={input.abn}
                    name="abn"
                    onChange={handleInput}
                  />
                  {error.abn && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.abn}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.address}>
                <label>Address :</label>
                <div className={styles.inputWerror}>
                  <textarea placeholder="Enter Text Here" value={input.address} name="address" onChange={handleInput} />
                  {error.address && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.address}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.mobile}>
                <label>Contact Phone :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="number"
                    placeholder="Enter Text Here"
                    value={input.phone}
                    name="phone"
                    onChange={handleInput}
                  />
                  {error.phone && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.phone}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.website}>
                <label>Website :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.website}
                    name="website"
                    onChange={handleInput}
                  />
                  {error.website && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.website}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.facebook}>
                <label>Facebook :</label>
                <input
                  type="text"
                  placeholder="Enter Text Here"
                  value={input.facebook}
                  name="facebook"
                  onChange={handleInput}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.linkedIn}>
                <label>LinkedIn :</label>
                <input
                  type="text"
                  placeholder="Enter Text Here"
                  value={input.linkedIn}
                  name="linkedIn"
                  onChange={handleInput}
                />
              </div>
              <div className={styles.instagram}>
                <label>Instagram :</label>
                <input
                  type="text"
                  placeholder="Enter Text Here"
                  value={input.instagram}
                  name="instagram"
                  onChange={handleInput}
                />
              </div>
            </div>
          </div>
          <div className={styles.mainContact}>
            <div className={styles.close}>
              <h5>Main Contact</h5>
            </div>
            <div className={styles.row}>
              <div className={styles.name}>
                <label>Name :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.referenceName}
                    name="referenceName"
                    onChange={handleInput}
                  />
                  {error.referenceName && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.referenceName}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.role}>
                <label>Role :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.referenceRole}
                    name="referenceRole"
                    onChange={handleInput}
                  />
                  {error.referenceRole && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.referenceRole}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.phone}>
                <label>Phone :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="number"
                    placeholder="Enter Text Here"
                    value={input.referencePhone}
                    name="referencePhone"
                    onChange={handleInput}
                  />
                  {error.referencePhone && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.referencePhone}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.email}>
                <label>Email :</label>
                <div className={styles.inputWerror}>
                  <input
                    type="text"
                    placeholder="Enter Text Here"
                    value={input.referenceEmail}
                    name="referenceEmail"
                    onChange={handleInput}
                  />
                  {error.referenceEmail && (
                    <div className={styles.error}>
                      <ErrorOutlineIcon className={styles.errIcon} />
                      {error.referenceEmail}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.modalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddOrg}>
              Submit
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
