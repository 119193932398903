import { useState, ChangeEvent, useEffect } from "react";
import styles from "./settingCard.module.scss";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { fetchRepeatPayAction } from "src/page/walletDetail/walletDetailSlice";
import LoadingModal from "../UI/loadingModal/LoadingModal";
import { immediateToast } from "izitoast-react";
import IOSSwitch from "./IOSSwitch";
import { CAN_UPDATE_REPEAT_PAY } from "../constant/constant";

const getChecked = (option: Option, isAllowRepeatChecked: boolean, isSetBudgetChecked: boolean) => {
  if (option.id === "allowRepeat") {
    return isAllowRepeatChecked;
  } else if (option.id === "setBudgetForThisWallet") {
    return isSetBudgetChecked;
  }
  return option.checked;
};

interface Option {
  id: string;
  checked?: boolean;
  label: string;
  onChange?: (_event: ChangeEvent<HTMLInputElement>, _optionId?: string) => void;
}

interface SettingCardProps {
  userSettingTitle: string;
  userSettingSubTitle: string;
  settingOptions: {
    id: number;
    head: string;
    option: Option[];
  }[];
  onSetBudgetToggle: (_event: ChangeEvent<HTMLInputElement>, _optionId: string) => void;
  onAllowRepeatToggle: (_event: ChangeEvent<HTMLInputElement>, _optionId: string) => void;
  onSetWholeBudget: () => void;
}

export default function SettingCard({
  userSettingTitle,
  userSettingSubTitle,
  settingOptions,
  onSetBudgetToggle,
  onAllowRepeatToggle,
  onSetWholeBudget
}: SettingCardProps) {
  // const [hiddenRepeatPay, setHiddenRepeatPay] = useState(false);
  const [isSetBudgetEnabled, setIsSetBudgetEnabled] = useState(true);
  const [isSetBudgetChecked, setIsSetBudgetChecked] = useState(false);
  const [isAllowRepeatChecked, setIsAllowRepeatChecked] = useState(false);
  const [useWholeBalance, setUseWholeBalance] = useState(false);
  const [requestedAmount, setRequestedAmount] = useState<number>();
  const { repeatPayLoading, walletDetail } = useAppSelector((state) => state.walletDetail);
  const { config } = useAppSelector((state) => state.auth);
  const { walletId } = useParams();
  const dispatch = useAppDispatch();

  // const handleHiddenRepeatPay = (event: ChangeEvent<HTMLInputElement>, optionId: string) => {
  //   if (optionId === "hiddenRepeatPayBox") {
  //     setHiddenRepeatPay(event.target.checked);
  //   }
  // };

  const handleAllowRepeatToggle = (event: ChangeEvent<HTMLInputElement>, optionId: string) => {
    const allowRepeatChecked = event.target.checked;

    onAllowRepeatToggle(event, optionId); // Pass the event and optionId to the onAllowRepeatToggle function

    setIsAllowRepeatChecked(allowRepeatChecked);
    // setIsSetBudgetEnabled(!allowRepeatChecked);

    if (allowRepeatChecked === false) {
      const data = {
        walletId: Number(walletId),
        repeatpay: true,
        loadCard: allowRepeatChecked
      };

      dispatch(fetchRepeatPayAction(data)).then(() => {
        setUseWholeBalance(false);
        setIsAllowRepeatChecked(allowRepeatChecked);
        setIsSetBudgetEnabled(!allowRepeatChecked);
      });
    }
  };

  const handleUseWholeBalanceToggle = async (event: ChangeEvent<HTMLInputElement>) => {
    const useWholeBalanceChecked = event.target.checked;

    setUseWholeBalance(useWholeBalanceChecked);

    setIsSetBudgetEnabled(!useWholeBalanceChecked);

    if (useWholeBalanceChecked) {
      const data = {
        walletId: Number(walletId),
        repeatpay: isAllowRepeatChecked,
        loadCard: true,
        useWholeBalance: useWholeBalanceChecked
      };

      dispatch(fetchRepeatPayAction(data))
        .then(() => {
          setUseWholeBalance(useWholeBalanceChecked);
          setIsSetBudgetEnabled(!useWholeBalanceChecked);
          setIsSetBudgetChecked(false);
          onSetWholeBudget();
        })
        .catch(() => {
          setUseWholeBalance(false);
        });
    }
  };

  const handleSetLimitClick = () => {
    dispatch(
      fetchRepeatPayAction({
        walletId: Number(walletId),
        amountToSet: requestedAmount,
        repeatpay: isAllowRepeatChecked,
        loadCard: true,
        useWholeBalance: false
      })
    );
  };

  const handleRequestedAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRequestedAmount(event.target.value.length > 0 ? Number(event.target.value) : undefined);
  };

  const handleDefaultWalletToggle = () => {
    immediateToast("error", {
      message: "You are not allowed to disable Default Wallet",
      timeout: 3000,
      position: "topCenter"
    });
  };

  useEffect(() => {
    if (walletDetail.wallet.repeatPay) {
      setIsAllowRepeatChecked(true);
      setUseWholeBalance(walletDetail.wallet.useWholeBalance);
      setRequestedAmount(walletDetail.wallet.repeatPayBalance);
    }
  }, [walletDetail.wallet]);

  useEffect(() => {
    setIsSetBudgetEnabled(!useWholeBalance);
  }, [useWholeBalance]);

  const handleSetBudgetToggle = (e: ChangeEvent<HTMLInputElement>, optionId: string) => {
    setIsSetBudgetChecked(!isSetBudgetChecked);
    onSetBudgetToggle(e, optionId); // Pass useWholeBalance to the onSetBudgetToggle function
  };

  return (
    <div className={styles.settingCard}>
      {repeatPayLoading ? <LoadingModal /> : ""}
      <div className={styles.title}>
        <h3>{userSettingTitle}</h3>
        <p>{userSettingSubTitle}</p>
      </div>

      {settingOptions.map((settingOption) => (
        <div key={settingOption.id} className={styles.head}>
          <h6>{settingOption.head}</h6>
          <FormGroup className={styles.formGroup}>
            {settingOption.option.map((option) => (
              <FormControlLabel
                key={option.id}
                className={styles.formControlLabel}
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={getChecked(option, isAllowRepeatChecked, isSetBudgetChecked)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (option.id === "setBudgetForThisWallet") {
                        handleSetBudgetToggle(e, option.id); // Pass useWholeBalance to the onSetBudgetToggle function
                      } else if (option.id === "allowRepeat") {
                        handleAllowRepeatToggle(e, option.id);
                      } else if (option.id === "defaultWallet" && option.onChange) {
                        if (option.checked === false) {
                          option.onChange(e);
                        } else if (option.checked === true) {
                          if (config?.allowedPermissions.includes(CAN_UPDATE_REPEAT_PAY)) {
                            option.onChange(e);
                          } else {
                            handleDefaultWalletToggle();
                          }
                        }
                      } else if (option.id === "Active" && option.onChange) {
                        option.onChange(e);
                      }
                    }}
                    disabled={option.id === "setBudgetForThisWallet" && !isSetBudgetEnabled}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </div>
      ))}

      {walletDetail?.wallet?.status && isAllowRepeatChecked && (
        <div className={styles.hiddenoption}>
          <FormGroup className={styles.formGroup}>
            <FormControlLabel
              className={styles.formControlLabel}
              control={<IOSSwitch sx={{ m: 1 }} checked={useWholeBalance} onChange={handleUseWholeBalanceToggle} />}
              label="Add whole balance to repeat pay?"
            />
          </FormGroup>

          {!useWholeBalance && (
            <div className={styles.hidenInput}>
              <input
                type="number"
                value={requestedAmount}
                onChange={handleRequestedAmountChange}
                placeholder="Add Amount Here"
              />
              <Button variant="contained" onClick={handleSetLimitClick}>
                Set Limit
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
