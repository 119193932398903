import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./transactionListCard.module.scss";
import Button from "@mui/material/Button";
import SearchBar from "../UI/searchBar/SearchBar";
import Table from "rc-table";
import columns from "./transTable/TransTable";
import Modalui from "src/components/UI/modal/Modalui";
import TransactionEditModal from "./transactionEditModal/TransactionEditModal";
import TablePagination from "@mui/material/TablePagination";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchTransListAction,
  // fetchUserDetailAction,
  fetchPaymentDataAction
} from "src/page/transactions/transactionSlice";
// import { useParams, useLocation } from "react-router-dom";
import { TransListRequest } from "src/network/services/transactionServices";
// import { SUPPORTER, PARTICIPANT } from "src/components/constant/constant";
import FilterTransModal from "./filterTransModal/FilterTransactionModal";
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import {
  TXN_START_DATE,
  TXN_END_DATE,
  TXN_MIN_AMT,
  TXN_MAX_AMT,
  TXN_ORGANISATIONS,
  TXN_PAGE,
  TXN_LIMIT,
  TXN_RECIEPT,
  TXN_REVIEWED
} from "src/components/constant/queryConstants";
import { utc } from "moment";
import { CAN_EDIT_TRANSACTION } from "../constant/constant";

interface TableData {
  id: number;
  wallet: { id: number; description: string; participant: { fullName: string } };
  amount: number;
  transactionDate?: number;
  updatedAt?: number;
  createdAt?: number;
  merchantName?: string;
  date?: number;
}

export default function TransactionListCard() {
  // const [selectedRows, setSelectedRows] = useState<number[]>([]);
  // const [selectAllRows, setSelectAllRows] = useState(false);
  const location = useLocation();
  const { role } = location.state || { role: "" };
  const { walletId, userId, organisationId } = useParams();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedTranId, setSelectedTransId] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { transList } = useAppSelector((state) => state.transaction);
  const { config } = useAppSelector((state) => state.auth);
  const { transaction: transactionFilters } = useAppSelector((state) => state.filters);
  const dispatch = useAppDispatch();

  const organisationIds = useMemo(() => {
    if (searchParams.get(TXN_ORGANISATIONS)) {
      return searchParams.get(TXN_ORGANISATIONS)?.split("&").map(Number) || [];
    } else if (role === "OrganisationId") {
      return [Number(organisationId)];
    } else if (organisationId) {
      return [Number(organisationId)];
    } else if (config?.defaultOrganisation?.id) {
      return [Number(config?.defaultOrganisation?.id)];
    }
    return [];
  }, [config?.defaultOrganisation?.id, organisationId, role, searchParams]);

  const fetchTransactionData = useCallback(() => {
    const minBalanceParam = searchParams.get(TXN_MIN_AMT);
    const maxBalanceParams = searchParams.get(TXN_MAX_AMT);
    const txnLimit = searchParams.get(TXN_LIMIT);
    const txnPage = searchParams.get(TXN_PAGE);

    let recieptPresent;

    if (searchParams.get(TXN_RECIEPT) === "true") {
      recieptPresent = true;
    } else if (searchParams.get(TXN_RECIEPT) === "false") {
      recieptPresent = false;
    } else {
      recieptPresent = null;
    }

    let isReviewed;

    if (searchParams.get(TXN_REVIEWED) === "true") {
      isReviewed = true;
    } else if (searchParams.get(TXN_REVIEWED) === "false") {
      isReviewed = false;
    } else {
      isReviewed = null;
    }
    let participantIds: number[] = [];
    let supporterIds: number[] = [];
    let walletIds: number[] = [];

    if (transactionFilters.users.participants.length > 0) {
      participantIds = transactionFilters.users.participants.map((item) => item.id);
    } else if (role === "PARTICIPANT") {
      participantIds = [Number(userId)];
    }

    if (transactionFilters.users.supporters.length > 0) {
      supporterIds = transactionFilters.users.supporters.map((item) => item.id);
    } else if (role === "SUPPORTER") {
      supporterIds = [Number(userId)];
    }

    if (transactionFilters.wallets.length > 0) {
      walletIds = transactionFilters.wallets.map((wallet) => wallet.id);
    } else if (role === "WalletId") {
      walletIds = [Number(walletId)];
    }

    const data = {
      page: txnPage ? Number(txnPage) : 0,
      limit: txnLimit ? Number(txnLimit) : 25,
      searchText,
      startDateInZonedDateTime: utc(searchParams.get(TXN_START_DATE)).toISOString(),
      endDateInZonedDateTime: utc(searchParams.get(TXN_END_DATE)).endOf("day").toISOString(),
      minRequestedAmount: minBalanceParam ? Number(minBalanceParam) : null,
      maxRequestedAmount: maxBalanceParams ? Number(maxBalanceParams) : null,
      participantIds,
      supporterIds,
      walletIds,
      organisationIds,
      receiptPresent: recieptPresent || null,
      isReviewed: isReviewed || null,
      statuses: ["Success", "Complete", "Hold"]
    };

    dispatch(fetchTransListAction(data));
  }, [dispatch, organisationIds, role, searchParams, searchText, transactionFilters, userId, walletId]);

  const handleOpen = (id: number) => {
    setOpen(true);
    setSelectedTransId(id);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const handleClose = () => {
    setOpen(false);
    fetchTransactionData();
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    searchParams.set(TXN_PAGE, newPage + "");
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParams.set(TXN_LIMIT, event.target.value);
    searchParams.delete(TXN_PAGE);
    setSearchParams(searchParams);
    fetchTransactionData();
  };

  // const handleRowSelection = (selectedRowKey: number) => {
  //   const isSelected = selectedRows.includes(selectedRowKey);

  //   if (isSelected) {
  //     setSelectedRows(selectedRows.filter((id) => id !== selectedRowKey));
  //   } else {
  //     setSelectedRows([...selectedRows, selectedRowKey]);
  //   }
  // };

  // const handleHeaderCheckboxChange = () => {
  //   setSelectAllRows(!selectAllRows);
  //   setSelectedRows(!selectAllRows ? transList.trans.map((row) => row.id) : []);
  // };

  const handleOpenFilterTrans = () => {
    setOpenFilter(true);
  };

  const handleCloseFilterTrans = () => {
    setOpenFilter(false);
  };

  const handleDownload = () => {
    const minBalanceParam = searchParams.get(TXN_MIN_AMT);
    const maxBalanceParams = searchParams.get(TXN_MAX_AMT);
    const txnPage = searchParams.get(TXN_PAGE);

    let recieptPresent;

    if (searchParams.get(TXN_RECIEPT) === "true") {
      recieptPresent = true;
    } else if (searchParams.get(TXN_RECIEPT) === "false") {
      recieptPresent = false;
    } else {
      recieptPresent = null;
    }

    let isReviewed;

    if (searchParams.get(TXN_REVIEWED) === "true") {
      isReviewed = true;
    } else if (searchParams.get(TXN_REVIEWED) === "false") {
      isReviewed = false;
    } else {
      isReviewed = null;
    }

    let participantIds: number[] = [];
    let supporterIds: number[] = [];
    let walletIds: number[] = [];

    if (transactionFilters.users.participants.length > 0) {
      participantIds = transactionFilters.users.participants.map((item) => item.id);
    } else if (role === "PARTICIPANT") {
      participantIds = [Number(userId)];
    }

    if (transactionFilters.users.supporters.length > 0) {
      supporterIds = transactionFilters.users.supporters.map((item) => item.id);
    } else if (role === "SUPPORTER") {
      supporterIds = [Number(userId)];
    }

    if (transactionFilters.wallets.length > 0) {
      walletIds = transactionFilters.wallets.map((wallet) => wallet.id);
    } else if (role === "WalletId") {
      walletIds = [Number(walletId)];
    }

    const data: TransListRequest = {
      page: txnPage ? Number(txnPage) : 0,
      status: true,
      startDateInZonedDateTime: utc(searchParams.get(TXN_START_DATE)).toISOString(),
      endDateInZonedDateTime: utc(searchParams.get(TXN_END_DATE)).toISOString(),
      minRequestedAmount: minBalanceParam ? Number(minBalanceParam) : null,
      maxRequestedAmount: maxBalanceParams ? Number(maxBalanceParams) : null,
      participantIds,
      supporterIds,
      walletIds,
      organisationIds,
      receiptPresent: recieptPresent || null,
      isReviewed: isReviewed || null,
      statuses: ["Success", "Complete", "Hold"]
    };

    dispatch(fetchPaymentDataAction(data));
  };

  // const checkboxColumn = {
  //   title: <input type="checkbox" checked={selectAllRows} onChange={handleHeaderCheckboxChange} />,
  //   key: "checkbox",
  //   render: (row: { id: number }) => (
  //     <input type="checkbox" checked={selectedRows.includes(row?.id)} onChange={() => handleRowSelection(row?.id)} />
  //   )
  // };

  let operation;

  if (config?.allowedPermissions.includes(CAN_EDIT_TRANSACTION)) {
    operation = {
      title: "",
      dataIndex: "",
      key: "operations",
      render: (record: TableData) => (
        <div onClick={() => handleOpen(record.id)} className={styles.operation}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      width: 100
    };
  } else {
    operation = {};
  }

  const updatedColumns = [...columns, operation];

  useEffect(() => {
    fetchTransactionData();
  }, [searchText, location.pathname, fetchTransactionData]);

  return (
    <>
      {selectedTranId && (
        <Modalui open={open} handleClose={handleClose} modaluiOver={styles.modaluiOver}>
          <TransactionEditModal transaction={selectedTranId} handleClose={handleClose} />
        </Modalui>
      )}
      <div className={styles.transactionList}>
        <div className={styles.transactionListHead}>
          <div className={styles.transactionListHeadIn}>
            <div className={styles.transactionListHeadD}>
              <h3>Transactions</h3>
              <p>Filter, select and download</p>
            </div>
            <div className={styles.transactionListHeadBtn}>
              <Button variant="outlined" className={styles.filterBtn} onClick={handleOpenFilterTrans}>
                <div>Filter Transactions</div>
              </Button>
              <Button variant="contained" className={styles.addTransactionBtn} onClick={handleDownload}>
                Download Transactions
              </Button>
              <Modalui open={openFilter} handleClose={handleCloseFilterTrans} modaluiOver={styles.filterModalOver}>
                <FilterTransModal handleClose={handleCloseFilterTrans} />
              </Modalui>
            </div>
          </div>
          <div className={styles.transactionListFilter}>
            {/* <FormControl sx={{ m: 1, margin: 0 }}>
              <Select
                value={age}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{ height: 40 }}
              >
                <MenuItem value="">Sort By</MenuItem>
                <MenuItem value={10}>Date</MenuItem>
                <MenuItem value={20}>Time</MenuItem>
              </Select>
            </FormControl> */}
            <div className={styles.transactionListSearch}>
              {/* <FormControl sx={{ m: 1, minWidth: 150, margin: 0 }}>
                <Select
                  value={ag}
                  onChange={handleChangee}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: 40 }}
                  style={{ color: "#202124" }}
                >
                  <MenuItem value="">Search By</MenuItem>
                  <MenuItem value={11}>Merchant</MenuItem>
                  <MenuItem value={12}>Wallet</MenuItem>
                </Select>
              </FormControl> */}
              <div className={styles.searchBar}>
                <SearchBar onSearch={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.transactionListTable}>
          <Table columns={updatedColumns} data={transList.trans} rowKey={(record) => record.id} />
        </div>
        <div className={styles.paginationWrapper}>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={transList.total}
            page={searchParams.get(TXN_PAGE) ? Number(searchParams.get(TXN_PAGE)) : 0}
            rowsPerPage={searchParams.get(TXN_LIMIT) ? Number(searchParams.get(TXN_LIMIT)) : 25}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
}
