import { useState, useEffect, ChangeEvent } from "react";
import styles from "./walletDetail.module.scss";
import SettingCard from "src/components/SettingCard/SettingCard";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import WalletTransCard from "src/components/walletTransCard/WalletTransCard";
import Budgets from "src/components/budgets/Budgets";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import AddIcon from "@mui/icons-material/Add";
import Modalui from "src/components/UI/modal/Modalui";
import SupporterModal from "src/components/connectCard/supporterModal/SupporterModal";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchTransListAction,
  fetchWalletDetailAction,
  fetchWalletTransactionAction,
  addWalletAction,
  addWalletSupporterAction,
  deleteWalletSupporterAction,
  updateWalletStatusAction,
  updateBudgetAction,
  addCardAction
} from "./walletDetailSlice";
import { walletTransCardLogo, whiteCardLogo, whiteFourPillarLogo, receiptLogo } from "src/components/nav/logo";
import { getWalletSettingOptions } from "src/components/SettingCard/settingOption/settingOption";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import DepositCard from "src/components/depositCard/DepositeCard";
import {
  CAN_CONNECT_USER_TO_WALLET,
  CAN_UPDATE_BUDGET,
  CAN_UPDATE_REPEAT_PAY,
  CAN_UPDATE_WALLET_SETTINGS,
  CAN_CONNECT_CARD_TO_WALLET
} from "src/components/constant/constant";
import AttachCard from "src/components/attachCard/AttachCard";

type TableData = {
  id: number;
  budgetId: number | null;
  budgetname: string;
  itemId: number;
  amount: number;
  isMasterItem: boolean;
  isNewRow: boolean;
};

export default function WalletPage() {
  const [open, setOpen] = useState(false);
  const [cardModal, setCardModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSetBudgetChecked, setIsSetBudgetChecked] = useState(false);
  const [isAllowRepeatChecked, setIsAllowRepeatChecked] = useState(false);
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const { walletId } = useParams();
  const { transList, walletDetail, walletTransaction, walletDetailLoading } = useAppSelector(
    (state) => state.walletDetail
  );
  const [isDefaultWallet, setIsDefaultWallet] = useState(walletDetail.wallet.defaultWallet);
  const [isActiveChecked, setIsActiveChecked] = useState(walletDetail.wallet.status);
  const { config } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    }
  }, [dispatch, walletId]);

  useEffect(() => {
    setIsDefaultWallet(walletDetail.wallet.defaultWallet);
    setIsActiveChecked(walletDetail.wallet.status);
  }, [walletDetail.wallet]);

  useEffect(() => {
    const page = 0;
    const limit = 5;
    const status = walletDetail.wallet.status;

    if (walletId) {
      dispatch(
        fetchTransListAction({
          page,
          limit,
          status,
          searchText,
          receiptPresent,
          isReviewed,
          walletIds: [parseInt(walletId!)],
          statuses: ["Success", "Complete", "Hold"]
        })
      );
    }
  }, [dispatch, walletId, walletDetail.wallet.status, searchText, receiptPresent, isReviewed]);

  useEffect(() => {
    if (walletId) {
      dispatch(fetchWalletTransactionAction({ walletIds: [Number(walletId)] }));
    }
  }, [dispatch, walletId]);

  const handleSetBudgetToggle = (event: ChangeEvent<HTMLInputElement>, optionId: string) => {
    if (optionId === "setBudgetForThisWallet") {
      setIsSetBudgetChecked(event.target.checked);

      if (isAllowRepeatChecked) {
        setIsAllowRepeatChecked(false);
      }
    }
  };

  // const handleSetBudgetToggle = (checked: boolean, optionId: string) => {
  //   if (optionId === "setBudgetForThisWallet") {
  //     setIsSetBudgetChecked(checked);

  //     if (isAllowRepeatChecked) {
  //       setIsAllowRepeatChecked(false);
  //     }
  //   }
  // };

  const handleAllowRepeatToggle = (event: ChangeEvent<HTMLInputElement>, optionId?: string) => {
    if (optionId === "allowRepeat") {
      if (isDefaultWallet) {
        setIsAllowRepeatChecked(event.target.checked);
      }
    }
  };

  const handleSetWholeBalance = () => {
    setIsSetBudgetChecked(false);
  };

  const handleDefaultWalletToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDefaultWallet(event.target.checked);
    const walletData = {
      id: Number(walletId),
      participantId: walletDetail.wallet.participant.id,
      defaultWallet: event.target.checked
    };

    dispatch(addWalletAction(walletData)).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
      dispatch(fetchWalletTransactionAction({ walletIds: [Number(walletId)] }));
    });
  };

  const handleActiveToggle = (event: ChangeEvent<HTMLInputElement>) => {
    setIsActiveChecked(event.target.checked);

    dispatch(updateWalletStatusAction(Number(walletId), event.target.checked)).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardModal = () => {
    setCardModal(!cardModal);
  };

  const handleAddSupporter = (email: string, userId: number, close: boolean, connectedToParticipant: boolean) => {
    const data = {
      emailId: email,
      userId: userId || null,
      applyToAll: connectedToParticipant,
      action: "LINK"
    };

    dispatch(addWalletSupporterAction(data, Number(walletId))).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    });

    if (close) {
      handleClose();
    }
  };

  const handleAddCard = (cardNumber: string, cardId: number, close: boolean, connectedToParticipant: boolean) => {
    const data = {
      cardId,
      applyToAll: connectedToParticipant,
      action: "LINK"
    };

    dispatch(addCardAction(data, Number(walletId))).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    });

    if (close) {
      setCardModal(false);
    }
  };

  const handleDeleteSupporter = (email?: string, supporterId?: number, removeItFromAll?: boolean) => {
    dispatch(
      deleteWalletSupporterAction(
        { supporterEmail: email },
        Number(walletId),
        Number(supporterId),
        removeItFromAll || false
      )
    ).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    });
  };

  const handleDeleteCard = (cardNumber?: string, cardId?: number, removeItFromAll?: boolean) => {
    const data = {
      cardId,
      applyToAll: removeItFromAll,
      action: "UNLINK"
    };

    dispatch(addCardAction(data, Number(walletId))).then(() => {
      dispatch(fetchWalletDetailAction(Number(walletId)));
    });
  };

  const handleSaveBudgets = (tableData: TableData[]) => {
    const budgets = tableData.map((data) => ({
      budgetId: data.budgetId,
      amount: data.amount,
      itemCategory: {
        id: data.itemId,
        categoryName: data.budgetname
      }
    }));

    dispatch(
      updateBudgetAction({ budgets, walletId: Number(walletId) }, () => {
        dispatch(fetchWalletDetailAction(Number(walletId)));
      })
    );
  };

  const WalletTransCardOver = styles.WalletTransCardOver;
  const balCardOver = styles.balCardOver;

  const settingOptions = getWalletSettingOptions(
    walletDetail,
    isDefaultWallet,
    isActiveChecked,
    isSetBudgetChecked,
    isAllowRepeatChecked,
    handleDefaultWalletToggle,
    handleActiveToggle,
    // handleSetBudgetToggle,
    handleAllowRepeatToggle,
    config?.allowedPermissions
  );

  const walletConnectHeading = "Users Connect";
  const deleteCardInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Wallet Card?"
  };
  const deleteWalletInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Supporter?"
  };
  const deleteSupporterInput = {
    buttonInfo: "Remove from wallet",
    whomToRemove: "wallet's",
    headInfo: "Do you want to remove this Supporter?"
  };

  return (
    <>
      <Modalui open={open} handleClose={handleClose}>
        <SupporterModal
          handleClose={handleClose}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddSupporter}
        />
      </Modalui>
      <Modalui open={cardModal} handleClose={handleCardModal}>
        <AttachCard
          handleClose={handleCardModal}
          participantName={walletDetail.wallet.description}
          organisationId={walletDetail?.wallet?.organisationId}
          onAddSupporter={handleAddCard}
        />
      </Modalui>
      <div className={styles.walletPage}>
        {walletDetailLoading ? <LoadingModal /> : ""}
        <div className={styles.walletPageWelCard}>
          {walletDetail && walletDetail?.wallet && (
            <PersonWelCard
              id={walletDetail.wallet.id}
              img={walletDetail.wallet.participant?.firstName?.charAt(0).toLocaleUpperCase()} //image
              name={walletDetail.wallet.description} //type of wallet
              email={walletDetail.wallet.participant.fullName} // name
            />
          )}
        </div>
        <div className={styles.userPageHead}>
          <div className={styles.transCard}>
            {Object.entries(walletTransaction).map(([key, transItem]) => {
              let title;
              let icon;

              if (key === "totalWalletBalance") {
                title = "Wallet Balance";
                icon = walletTransCardLogo;
              } else if (key === "totalCardBalance") {
                title = "Card Balance";
                icon = whiteCardLogo;
              } else if (key === "totalTransactionsWithoutReceipts") {
                title = "Transactions without receipt (30 days)";
                icon = receiptLogo;
              } else if (key === "totalTransactions") {
                title = "Transactions (30 Days)";
                icon = whiteFourPillarLogo;
              }

              return (
                [
                  "totalWalletBalance",
                  "totalCardBalance",
                  "totalTransactionsWithoutReceipts",
                  "totalTransactions"
                ].includes(key) &&
                transItem && (
                  <WalletTransCard
                    key={transItem.id}
                    icon={icon}
                    title={title}
                    value={
                      title && ["Wallet Balance", "Card Balance"].includes(title)
                        ? `$${Number(transItem.current).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}`
                        : transItem.current
                    }
                    growthval={transItem.previous !== null ? Number(transItem.diff) : undefined}
                    growthind={transItem.previous !== null ? Boolean(transItem.positive) : undefined}
                    from={transItem.previous !== null ? `from ${transItem.previous}` : undefined}
                    WalletTransCardClass={WalletTransCardOver}
                    balCardClass={balCardOver}
                  />
                )
              );
            })}
          </div>
          <div className={styles.settingCard}>
            {config?.allowedPermissions.includes(
              CAN_UPDATE_WALLET_SETTINGS || CAN_UPDATE_BUDGET || CAN_UPDATE_REPEAT_PAY
            ) ? (
              <SettingCard
                userSettingTitle={"Wallet settings"}
                userSettingSubTitle={"Here you can set preferences for your wallet."}
                settingOptions={settingOptions}
                onSetBudgetToggle={handleSetBudgetToggle}
                onAllowRepeatToggle={handleAllowRepeatToggle}
                onSetWholeBudget={handleSetWholeBalance}
              />
            ) : (
              walletDetail &&
              walletDetail?.supporter &&
              walletDetail?.wallet?.status && (
                <ConnectCard
                  walletUsers={walletDetail.supporter}
                  heading={walletConnectHeading}
                  onDeleteSupporter={handleDeleteSupporter}
                  connectedTo={"Wallet"}
                  deleteInput={deleteSupporterInput}
                >
                  {config?.allowedPermissions.includes(CAN_CONNECT_USER_TO_WALLET) && (
                    <AddIcon className={styles.editIcon} onClick={handleOpen} />
                  )}
                </ConnectCard>
              )
            )}
          </div>
        </div>
        <div className={styles.userPageBottom}>
          <div className={styles.userPageBottomL}>
            {isSetBudgetChecked && (
              <Budgets
                budgetOver={styles.budgetOver}
                budgetSupporter={walletDetail.supporter}
                onSave={handleSaveBudgets} // Add this line
              />
            )}
            <RecentTrans
              transList={transList.trans}
              setSearchText={setSearchText}
              setReceiptPresent={setReceiptPresent}
              setIsReviewed={setIsReviewed}
              walletId={Number(walletId)}
              role={"WalletId"}
              recentTransOver={styles.recentTransOver}
              subTitle={"These are the details of the transactions made by this wallet"}
            />
          </div>
          <div className={styles.userPageBottomR}>
            {config?.allowedPermissions.includes(
              CAN_UPDATE_WALLET_SETTINGS || CAN_UPDATE_BUDGET || CAN_UPDATE_REPEAT_PAY
            ) &&
              walletDetail &&
              walletDetail?.supporter &&
              walletDetail?.wallet?.status && (
                <ConnectCard
                  walletUsers={walletDetail.supporter}
                  heading={walletConnectHeading}
                  onDeleteSupporter={handleDeleteSupporter}
                  connectedTo={"Wallet"}
                  deleteInput={deleteWalletInput}
                >
                  {config?.allowedPermissions.includes(CAN_CONNECT_USER_TO_WALLET) && (
                    <AddIcon className={styles.editIcon} onClick={handleOpen} />
                  )}
                </ConnectCard>
              )}
            {walletDetail && walletDetail?.supporter && walletDetail?.wallet?.status && (
              <ConnectCard
                walletUsers={walletDetail.cards}
                heading={"Card Connect"}
                onDeleteSupporter={handleDeleteCard}
                connectedTo={"WalletCards"}
                deleteInput={deleteCardInput}
              >
                {config?.allowedPermissions.includes(CAN_CONNECT_CARD_TO_WALLET) && (
                  <AddIcon className={styles.editIcon} onClick={handleCardModal} />
                )}
              </ConnectCard>
            )}
            <DepositCard userId={walletDetail.wallet.participant.id} />
          </div>
        </div>
      </div>
    </>
  );
}
