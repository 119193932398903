import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./orderCardModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchOrgListAction,
  fetchUserDataAction,
  fetchUserListAction,
  orderCardAction,
  setUsersAction,
  setOrganisationAction,
  setWalletOptionsAction
} from "src/page/cards/cardSlice";
import { debounce } from "lodash";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ToggleSwitch from "../UI/toggleSwitch/ToggleSwitch";
import { PARTICIPANT, PRIVATE, SHARED, SUPPORTER } from "../constant/constant";
import { useParams } from "react-router-dom";
import { Organisation } from "src/page/auth/loginSlice";
import { getOrgDetail } from "src/network/services/organisationService";
import { User } from "src/types/commonTypes";
import { fetchWallets } from "src/network/services/walletService";

interface Iprops {
  handleClose: () => void;
  onSuccess: () => void;
  participantName?: string;
  organisationId?: number;
}

type Wallet = {
  id: number;
  description: string;
};

export default function OrderCardModal({ handleClose, onSuccess }: Iprops) {
  const { users, userData, orgLists, orderCardLoading } = useAppSelector((Rstate) => Rstate.cards);
  const { config } = useAppSelector((Rstate) => Rstate.auth);
  const dispatch = useAppDispatch();
  const [accessType, setAccessType] = useState<string>("");
  const [toggleValue, setToggleValue] = useState<string>(PARTICIPANT);
  const [supporterEmail, setSupporterEmail] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [firstName, setFirstName] = useState(userData.firstName || "");
  const [lastName, setLastName] = useState(
    userData.role === PARTICIPANT && accessType === "SHARED" ? "Supporter" : userData.lastName || ""
  );
  const [address, setAddress] = useState(userData.address || "");
  const [city, setCity] = useState(userData.city || "");
  const [state, setState] = useState(userData.state || "");
  const [postCode, setPostCode] = useState(userData.zipcode || "");
  const [organisationId, setOrganisationId] = useState<number | null>(null);
  const [walletOptions, setWalletOptions] = useState<Wallet[]>([]);
  const [wallet, setWallet] = useState<Wallet | null>();
  const [error, setError] = useState({
    email: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postcode: "",
    accessType: ""
  });
  const [defaultOrganisation, setDefaultOrganisation] = useState<Organisation>();
  const params = useParams();

  const handleAddAndClose = () => {
    if (
      !supporterEmail.trim() ||
      !firstName.trim() ||
      !lastName.trim() ||
      !address.trim() ||
      !city.trim() ||
      !state.trim() ||
      !postCode.trim() ||
      !accessType.trim()
    ) {
      setError({
        email: supporterEmail ? "" : "User should be selected",
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        address: address ? "" : "Address is required",
        city: city ? "" : "City is required",
        state: state ? "" : "State is required",
        postcode: postCode ? "" : "Postcode is required",
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }

    const data = {
      userId,
      firstName,
      lastName,
      address,
      city,
      state,
      postcode: postCode,
      organisationId,
      accessType,
      walletId: wallet?.id
    };

    dispatch(
      orderCardAction(data, () => {
        handleClose();
        dispatch(setUsersAction([]));
        dispatch(setOrganisationAction([]));
        onSuccess();
      })
    );
  };

  const handleAddAndStay = () => {
    if (
      !supporterEmail.trim() ||
      !firstName.trim() ||
      !lastName.trim() ||
      !address.trim() ||
      !city.trim() ||
      !state.trim() ||
      !postCode.trim() ||
      !accessType.trim()
    ) {
      setError({
        email: supporterEmail ? "" : "User should be selected",
        firstName: firstName ? "" : "First name is required",
        lastName: lastName ? "" : "Last name is required",
        address: address ? "" : "Address is required",
        city: city ? "" : "City is required",
        state: state ? "" : "State is required",
        postcode: postCode ? "" : "Postcode is required",
        accessType: !accessType ? "Access type is required" : ""
      });
      return;
    }
    const data = {
      userId,
      firstName,
      lastName,
      address,
      city,
      state,
      postcode: postCode,
      organisationId,
      accessType,
      walletId: wallet?.id
    };

    dispatch(orderCardAction(data)).then(() => {
      dispatch(setUsersAction([]));
      dispatch(setOrganisationAction([]));
      onSuccess();
    });
  };

  const handleCancelClose = () => {
    handleClose();
    dispatch(setUsersAction([]));
    dispatch(setOrganisationAction([]));
  };

  const handleValue = (val?: string | number | boolean) => {
    if (typeof val === "string") {
      setToggleValue(val);
      dispatch(setUsersAction([]));
      dispatch(setOrganisationAction([]));
    }
  };

  const fetchOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];

    let orgId: number | undefined;

    if (params.organisationId) {
      orgId = parseInt(params.organisationId);
    }

    const data = {
      page: 0,
      limit: 30,
      searchText: inputValue,
      filterContext: toggleValue,
      organisationId: orgId
    };

    dispatch(fetchUserListAction(data));
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    setUserId(0);
    setSupporterEmail("");
    fetchOptions(newInputValue);
  };

  const handleSelectionChange = (event: React.ChangeEvent<object>, newValue: any) => {
    const id = newValue?.id || 0;
    const userEmail = newValue?.email || "";
    const userRole = newValue?.role || "";
    const selectedUser = users.list.find((item) => item.id === id);

    setUserId(id);
    setSupporterEmail(userEmail);
    setUser(selectedUser);
    if (id) {
      dispatch(fetchUserDataAction(Number(id)));
      setLastName(userRole === PARTICIPANT && accessType === SHARED ? "Supporter" : newValue?.lastName);
    }
  };

  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName || "");
      setLastName(userData.role === PARTICIPANT && accessType === SHARED ? "Supporter" : userData.lastName || "");
      setAddress(userData.address || "");
      setCity(userData.city || "");
      setState(userData.state || "");
      setPostCode(userData.zipcode || "");

      setError({
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        state: "",
        postcode: "",
        accessType: ""
      });
    }
    if (config?.defaultOrganisation?.id) {
      setOrganisationId(Number(config.defaultOrganisation.id));
    }
  }, [accessType, config?.defaultOrganisation?.id, userData]);

  useEffect(() => {
    setSupporterEmail("");
    setUserId(0);
  }, [toggleValue]);

  useEffect(() => {
    if (params.organisationId) {
      getOrgDetail(params.organisationId).then((resp) => {
        setDefaultOrganisation((resp as unknown as { organisation: Organisation }).organisation);
      });
    }
  }, [params.organisationId]);

  useEffect(() => {
    if (organisationId) {
      fetchWallets({
        page: 0,
        organisationId: organisationId,
        supporterIds: toggleValue === PARTICIPANT ? [] : [userId],
        participantIds: toggleValue === SUPPORTER ? [] : [userId]
      }).then((resp: any) => {
        setWalletOptions(resp?.list as any[]);
      });
    }
  }, [organisationId]);

  const fetchOrgOptions = debounce((inputValue: string) => {
    dispatch(setOrganisationAction([]));
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      searchText: inputValue,
      active: true,
      userIds: [Number(userId)]
    };

    dispatch(fetchOrgListAction(data));
  }, 500);

  const fetchWalletOptions = debounce((inputValue: string) => {
    dispatch(setWalletOptionsAction([]));
    if (!inputValue || inputValue?.length < 2) return [];
    const data: {
      page: number;
      searchText: string;
      active: boolean;
      organisationId: number | null;
      participantIds?: number[];
      supporterIds?: number[];
    } = {
      page: 0,
      searchText: inputValue,
      active: true,
      organisationId
    };

    if (toggleValue === PARTICIPANT) {
      data.participantIds = [userId];
    } else {
      data.supporterIds = [userId];
    }
    fetchWallets(data).then((resp: any) => setWalletOptions(resp.list));
  }, 500);

  const handleOrgInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchOrgOptions(newInputValue);
  };

  const handleWalletInputChange = (event: React.ChangeEvent<object>, value: string) => {
    fetchWalletOptions(value);
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError((prev) => ({
      ...prev,
      accessType: ""
    }));
    setAccessType(e.target.value);
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Order Card</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon />
            </button>
          </div>
          {orderCardLoading ? (
            <div className={styles.progress}>
              <CircularProgress sx={{ color: "#0f172a" }} />
            </div>
          ) : (
            <>
              <div className={styles.email}>
                <div className={styles.emailtoggle}>
                  <label>User</label>
                  <ToggleSwitch
                    leftValueIn={"Participant"}
                    rightValueIn={"Supporter"}
                    leftValueOut={PARTICIPANT}
                    rightValueOut={SUPPORTER}
                    getValue={handleValue}
                    toggleBodyOver={styles.toggleBodyOver}
                    contentOver={styles.contentOver}
                    className={styles.myToggleSwitch}
                  />
                </div>

                <Autocomplete
                  options={users.list}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                  onInputChange={handleInputChange}
                  onChange={handleSelectionChange}
                  value={user}
                  renderInput={(parms) => <TextField {...parms} variant="outlined" />}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start"
                      }}
                    >
                      <span>
                        {option.firstName} {option.lastName}
                      </span>
                      <span>{option.email}</span>
                    </li>
                  )}
                  ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                />

                {error.email && (
                  <div className={styles.error}>
                    <ErrorOutlineIcon className={styles.errIcon} />
                    {error.email}
                  </div>
                )}
              </div>
              {supporterEmail.trim() && (
                <div className={styles.formfill}>
                  {userData?.role === PARTICIPANT && (
                    <div className={styles.RadioInput}>
                      <div className={styles.inputs}>
                        <div className={styles.inputYes}>
                          <input
                            type="radio"
                            name="accessType"
                            value="PRIVATE"
                            id="private"
                            checked={accessType === PRIVATE}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="private">Private</label>
                        </div>
                        <div className={styles.inputNo}>
                          <input
                            type="radio"
                            name="accessType"
                            value="SHARED"
                            id="shared"
                            checked={accessType === SHARED}
                            onChange={handleRadioChange}
                          />
                          <label htmlFor="shared">Shared</label>
                        </div>
                      </div>
                      {error.accessType && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.accessType}
                        </div>
                      )}
                    </div>
                  )}
                  <div className={styles.name}>
                    <div className={styles.Fname}>
                      <label>First Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {error.firstName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.firstName}
                        </div>
                      )}
                    </div>
                    <div className={styles.Lname}>
                      <label>Last Name</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {error.lastName && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.lastName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.addressrowI}>
                    <div className={styles.address}>
                      <label>Address</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      {error.address && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.address}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.addressrowII}>
                    <div className={styles.city}>
                      <label>City</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {error.city && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.city}
                        </div>
                      )}
                    </div>
                    <div className={styles.state}>
                      <label>State</label>
                      <input
                        type="text"
                        placeholder="Enter Text Here"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                      {error.state && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.state}
                        </div>
                      )}
                    </div>
                    <div className={styles.postCode}>
                      <label>PostCode</label>
                      <input
                        type="number"
                        placeholder="Enter Text Here"
                        value={postCode}
                        onChange={(e) => setPostCode(e.target.value)}
                      />
                      {error.postcode && (
                        <div className={styles.error}>
                          <ErrorOutlineIcon className={styles.errIcon} />
                          {error.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.orgn}>
                    <div className={styles.organisation}>
                      <label>Organisation (optional)</label>
                      {config?.defaultOrganisation || params.organisationId ? (
                        <input
                          type="text"
                          placeholder="Enter Text Here"
                          value={config?.defaultOrganisation?.name || defaultOrganisation?.name}
                          style={{ backgroundColor: "#80808036" }}
                          disabled
                        />
                      ) : (
                        <div className={styles.autoComplete}>
                          <Autocomplete
                            options={orgLists.list}
                            getOptionLabel={(option) => `${option.name}`}
                            onInputChange={handleOrgInputChange}
                            onChange={(event, newValue) => {
                              setOrganisationId(newValue?.id || 0);
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            renderOption={(props, option) => <li {...props}>{`${option.id}) ${option.name}`}</li>}
                            ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.orgn}>
                    <div className={styles.organisation}>
                      <label>Wallet (optional)</label>
                      <div className={styles.autoComplete}>
                        <Autocomplete
                          disabled={organisationId === null}
                          options={walletOptions}
                          getOptionLabel={(option) => `${option.description}`}
                          onInputChange={handleWalletInputChange}
                          onChange={(event, newValue) => {
                            setWallet(newValue);
                          }}
                          renderInput={(parms) => <TextField {...parms} variant="outlined" />}
                          renderOption={(props, option) => <li {...props}>{`${option.id}) ${option.description}`}</li>}
                          ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleAddAndClose}>
              Order & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddAndStay}>
              Order Another Card
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
