import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import styles from "./attachCard.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/page/store";
import { getAllCardsAction } from "src/page/walletDetail/walletDetailSlice";
import { debounce } from "lodash";
// import { roleMapping } from "src/components/constant/constant";
// import { roleColors } from "src/variable/randomColor";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { SHARED } from "../constant/constant";

interface Iprops {
  handleClose: () => void;
  participantName: string;
  organisationId?: number;
  onAddSupporter: (_cardNumber: string, _cardId: number, _close: boolean, _connectedToParticipant: boolean) => void;
}

export default function AttachCard({ handleClose, participantName, organisationId, onAddSupporter }: Iprops) {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [cardId, setCardId] = useState<number>(0);
  const [connectToParticipant, setConnectToParticipant] = useState<boolean>(false);
  const [error, setError] = useState({ cardNumber: "" });

  const { cards } = useAppSelector((state) => state.walletDetail);
  const dispatch = useAppDispatch();

  const handleAddAndClose = () => {
    if (!cardNumber.trim()) {
      setError({
        cardNumber: cardNumber ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(cardNumber, cardId, true, connectToParticipant);
  };

  const handleAddAndStay = () => {
    if (!cardNumber.trim()) {
      setError({
        cardNumber: cardNumber ? "" : "User email is required"
      });
      return;
    }
    onAddSupporter(cardNumber, cardId, false, connectToParticipant);
  };

  const handleCancelClose = () => {
    handleClose();
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConnectToParticipant(e.target.value === "Yes");
  };

  const fetchOptions = debounce((inputValue: string) => {
    if (!inputValue || inputValue?.length < 2) return [];
    const data = {
      page: 0,
      active: true,
      searchText: inputValue,
      accessType: [SHARED]
    };

    dispatch(getAllCardsAction(data));
  }, 500);

  const handleInputChange = (event: React.ChangeEvent<object>, newInputValue: string) => {
    fetchOptions(newInputValue.toUpperCase());
  };

  return (
    <div className={styles.referModalBox}>
      <div className={styles.referModalTop}>
        <form>
          <div className={styles.close}>
            <h5>Connect Card to {participantName}</h5>
            <button onClick={handleCancelClose}>
              <CloseIcon />
            </button>
          </div>

          <div className={styles.email}>
            <label>Card Number</label>
            {organisationId ? (
              <Autocomplete
                options={cards.list}
                // getOptionLabel={(option) => option.email}
                getOptionLabel={(option) => `${option?.cardNumber}`}
                style={{ width: 300 }}
                onInputChange={handleInputChange}
                onChange={(event, newValue) => {
                  setCardId(newValue?.id || 0);
                  setCardNumber(newValue?.cardNumber || "");
                }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start"
                    }}
                  >
                    <span>
                      {option?.id}
                      {") "}
                      {option.user?.fullName}
                    </span>
                    <span>{option?.cardNumber}</span>
                    {/* <span
                      style={{
                        border: `1px solid ${roleColors[option.role]}73`,
                        backgroundColor: `${roleColors[option.role]}33`,
                        borderRadius: "4px",
                        padding: "2px"
                      }}
                    >
                      {roleMapping[option.role]}
                    </span> */}
                  </li>
                )}
                ListboxProps={{ style: { maxHeight: "200px", overflow: "auto" } }}
              />
            ) : (
              <input
                type="text"
                placeholder="123456ABCDEF7890"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                required
              />
            )}
            {error.cardNumber && (
              <div className={styles.error}>
                <ErrorOutlineIcon className={styles.errIcon} />
                {error.cardNumber}
              </div>
            )}
          </div>
          <div className={styles.RadioInput}>
            <label>Connect to all of this participant’s wallet?</label>
            <div className={styles.inputs}>
              <div className={styles.inputYes}>
                <input type="radio" name="connectOption" value="Yes" id="yes" onChange={handleRadioChange} />
                <label htmlFor="yes">Yes</label>
              </div>
              <div className={styles.inputNo}>
                <input type="radio" name="connectOption" value="No" id="No" onChange={handleRadioChange} />
                <label htmlFor="No">No</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.referModalBottom}>
        <div className={styles.referModalBtn}>
          <Stack spacing={2} direction="row">
            <Button className={styles.cancelBtn} variant="text" onClick={handleAddAndClose}>
              Connect & Back To List
            </Button>
            <Button className={styles.sendBtn} variant="contained" onClick={handleAddAndStay}>
              Connect & Add Another Card
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
