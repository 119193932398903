import CardEdit from "src/components/cardEdit/CardEdit";
import { User } from "src/types/commonTypes";
import styles from "./cards.module.scss";
import { Card } from "./cardSlice";

export const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "Card No.",
    dataIndex: "cardNumber",
    key: "cardNumber"
  },
  // {
  //   title: "Card Limit",
  //   dataIndex: "cardLimit",
  //   key: "cardLimit",
  //   render: (cardLimit: number) => <div>${cardLimit}</div>
  // },
  {
    title: "Status",
    dataIndex: "cardStatus",
    key: "cardStatus",
    render: (status: boolean) => (
      <div className={styles.tableCardStatus}>
        <div className={`${status ? styles.green : styles.grey}`}>{status ? "Active" : "Inactive"}</div>
      </div>
    )
  },
  {
    title: "Bsb",
    dataIndex: "bsb",
    key: "bsb"
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: (user?: User) => <div>{user?.fullName}</div>
  },
  {
    title: "Access Type",
    dataIndex: "accessType",
    key: "accessType"
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (card: Card) => <CardEdit card={card} />
  }
];
