import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import {
  UserListRequest,
  fetchUserList,
  addUser,
  addUserRequest,
  connectUser,
  connectUserRequest
} from "src/network/services/UsersServices";
import { immediateToast } from "izitoast-react";

type User = {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  active: boolean;
  lastActivets: string;
};

const initialUsers: User[] = [];

const userSlice = createSlice({
  name: "user",
  initialState: {
    userLoading: false,
    error: null,
    users: {
      list: initialUsers,
      total: 0
    }
  },
  reducers: {
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    addUserStart: (state) => {
      state.userLoading = true;
    },
    addUserSuccess: (state) => {
      state.userLoading = false;
    },
    addUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    connectUserStart: (state) => {
      state.userLoading = true;
    },
    connectUserSuccess: (state) => {
      state.userLoading = false;
    },
    connectUserFail: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  addUserStart,
  addUserSuccess,
  addUserFail,
  connectUserStart,
  connectUserSuccess,
  connectUserFail
} = userSlice.actions;

export const fetchUserListAction = (data: UserListRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await fetchUserList(data);

      dispatch(fetchUsersSuccess(response));
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const addUserAction = (data: addUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addUserStart());
    try {
      const response = await addUser(data);

      dispatch(addUserSuccess());
      immediateToast("success", {
        message: "User Successfully Added.",
        timeout: 3000,
        position: "topCenter"
      });
      return response;
    } catch (error) {
      dispatch(addUserFail(error));
      throw error;
    }
  };
};

export const connectUserAction = (organisationId: number, data: connectUserRequest, message: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(connectUserStart());
    try {
      const response = await connectUser(organisationId, data);

      dispatch(connectUserSuccess());
      immediateToast("success", {
        message: `User Successfully ${message}.`,
        timeout: 3000,
        position: "topCenter"
      });
      return response;
    } catch (error) {
      dispatch(connectUserFail(error));
      throw error;
    }
  };
};

export default userSlice.reducer;
